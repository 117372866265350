import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  inputPass: ItemsInput;
}

type ItemsInput = {
  oldPass: string;
  newPass: string;
  repeatPass: string;
  checkValidatePass: boolean;
};
const initialState: InitialState = {
  inputPass: {
    oldPass: "",
    newPass: "",
    repeatPass: "",
    checkValidatePass: false,
  },
};

const changePassSlice = createSlice({
  name: "changePassSlice",
  initialState,
  reducers: {
    updateInputPass: (state, action) => {
      Object.assign(state.inputPass, action.payload);
    },
    resetInputPass: () => initialState,
  },
});

export const { updateInputPass, resetInputPass } = changePassSlice.actions;

export default changePassSlice.reducer;
