import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
}

interface PropsStatus {
  teleStatus?: boolean;
  otpStatus?: boolean;
  mailStatus?: boolean;
}
const initialState: InitialState = {
  status: ReduxStateType.ERROR,
};

const postRegisOtp2FA = createSlice({
  name: "postRegisOtp2FASlice",
  initialState,
  reducers: {
    postRegisOtp2FAStart: (state, action: PayloadAction<PropsStatus>) => {
      state.status = ReduxStateType.LOADING;
    },
    postRegisOtp2FASuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    postRegisOtp2FAFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    resetpostRegisOtp2FA: () => initialState,
  },
});

export const {
  postRegisOtp2FAStart,
  postRegisOtp2FASuccess,
  postRegisOtp2FAFailed,
  resetpostRegisOtp2FA,
} = postRegisOtp2FA.actions;

export default postRegisOtp2FA.reducer;
