import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const settingsAuth2FA = (state: RootState) => state.settingsAuth2FA;

export const getCheckOtpStatusSelector = createSelector(
  settingsAuth2FA,
  (state) => state.getCheckOtpStatusSlice
);

export const postRegisOtp2FASelector = createSelector(
  settingsAuth2FA,
  (state) => state.postRegisOtp2FASlice
);
