import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { categoryDividend } from "../const";
import {
  DividendFilterParams,
  DividendItems,
  ResponseDividendAllItems,
} from "src/core/http/service/dividend/types";
import { addDays, format } from "date-fns";

export type DividendFilterState = {
  totalRecord: number;
  dividends: DividendItems[];
  filter: DividendFilterParams;
};

const initialState: ReduxData<DividendFilterState> = {
  data: {
    totalRecord: 0,
    dividends: [],
    filter: {
      accountCode: "",
      accountName: "",
      dividendType: categoryDividend[0].value,
      fromDate: format(new Date(), "dd/MM/yyyy"),
      toDate: format(addDays(new Date(), 30), "dd/MM/yyyy"),
      offset: 0,
      limit: 12,
      isLoading: true,
    },
  },
  status: ReduxStateType.INIT,
};

const dividendFilterSlice = createSlice({
  name: "dividendFilter",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<DividendFilterParams>) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    resetListDividends: (state) => {
      state.data.dividends = initialState.data.dividends;
    },
    updateOffset: (state, action: PayloadAction<number>) => {
      state.data.filter.offset = action.payload;
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.data.filter.isLoading = action.payload;
    },
    getDividendFilterStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getDividendFilterFailed: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.ERROR;
      toast.error(action.payload || "Đã có lỗi xảy ra");
    },
    getDividendFilterSuccess: (
      state,
      action: PayloadAction<{ items: ResponseDividendAllItems }>
    ) => {
      let items = action.payload.items.items;
      state.data.totalRecord = action.payload.items.totalRecord;
      state.data.dividends = [...state.data.dividends, ...items];
      state.status = ReduxStateType.SUCCESS;
    },
  },
});

export const {
  updateFilter,
  resetFilter,
  resetListDividends,
  updateOffset,
  updateIsLoading,
  getDividendFilterStart,
  getDividendFilterFailed,
  getDividendFilterSuccess,
} = dividendFilterSlice.actions;

export default dividendFilterSlice.reducer;
