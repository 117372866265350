import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { DividendCreateParams } from "src/core/http/service/dividend/types";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { categoryDividendCreate } from "../const";

export type DividendCreateState = {
  dividends: DividendCreateParams;
  hasMore: boolean;
  isValidate: boolean;
  value: string;
};

const initialState: ReduxData<DividendCreateState> = {
  data: {
    dividends: {
      accountCode: "",
      dividendType: categoryDividendCreate[0].value,
      shareCode: "",
      shareVolume: "",
      cash: "",
      returnDay: new Date(),
      content: "",
      channelCode: "",
      isRightBuy: 0,
    },
    isValidate: false,
    value: "",
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const dividendCreateSlice = createSlice({
  name: "dividendCreate",
  initialState,
  reducers: {
    updateCreate: (state, action: PayloadAction<DividendCreateParams>) => {
      Object.assign(state.data.dividends, action.payload);
    },
    resetCreate: (state) => {
      state.data.dividends = initialState.data.dividends;
    },
    updateValidate: (state, action: PayloadAction<boolean>) => {
      state.data.isValidate = action.payload;
    },
    resetValidate: (state) => {
      state.data.isValidate = initialState.data.isValidate;
    },
    updateValue: (state, action: PayloadAction<string>) => {
      state.data.value = action.payload;
    },
    resetValue: (state) => {
      state.data.value = initialState.data.value;
    },
    postDividendCreateStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    postDividendCreateFailed: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.ERROR;
      toast.error(action.payload || "Đã có lỗi xảy ra");
    },
    postDividendCreateSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      toast.success("Thêm mới thành công.");
    },
  },
});

export const {
  updateCreate,
  resetCreate,
  updateValidate,
  resetValidate,
  updateValue,
  resetValue,
  postDividendCreateStart,
  postDividendCreateFailed,
  postDividendCreateSuccess,
} = dividendCreateSlice.actions;

export default dividendCreateSlice.reducer;
