export const listAccounts = "api/account/accounts/authorize";
export const orderTrading = "api/channel/exec/orderManual";
export const listOrders = "api/channel/orderByAccount";
export const listOrdersUser = "api/channel/orders";
export const buyingPower = "api/management/order/buyingPower";
export const getBuyInfo = "api/management/order/getBuyInfo";
export const shareWithdraw = "api/management/order/shareWithdraw";
export const reportBuyingPower = "api/management/order/reportBuyingPower";
export const portfolio = "api/management/report/shareHolding";
export const importExcel = "api/channel/exec/import";
export const channelDetail = "api/management/channel/detail";
export const orderCancel = "api/channel/exec/orderList";
export const orderRemove = "api/channel/exec/orderList/permanent";
