import financeHttp from "src/core/http/singleton/financeService";
import { GET_LIST_REPORT_FEE_PORTFOLIO_REALIZE } from "./urls";
import { ReportFeeSyntheticParams } from "./types";
import { ResponseReportPortfolioRealize } from "src/domain/models/reportPortfolioRealize";

//lay danh sach lai lo cua KH
export const getListReportFeePortfolioApi = async (
  params: ReportFeeSyntheticParams
): Promise<ResponseReportPortfolioRealize> => {
  const res = await financeHttp.get<ResponseReportPortfolioRealize>(
    GET_LIST_REPORT_FEE_PORTFOLIO_REALIZE,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
