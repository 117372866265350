import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
// import { BondsItem } from "domain/models/bond";
// import { FilterBondsItem } from "core/http/service/abond/types";
// import { toast } from "react-toastify";
import {
  ItemPortfolio,
  ResponseDetailPortfolio,
} from "src/domain/models/assetsValueReport";
import { DEFAULT_LIMIT_OFFSET } from "src/helper/const";

export type PortfolioAssetsValueState = {
  detailPortfolio: {
    total: number;
    items: ItemPortfolio[];
  };
  masterAccount: string;
  account: string;
  offset: number;
  limit: number;
  hasMore: boolean;
};

const initialState: ReduxData<PortfolioAssetsValueState> = {
  data: {
    detailPortfolio: {
      total: 0,
      items: [],
    },
    masterAccount: "",
    account: "",
    offset: 1, // Chắc đang dùng pageNum pageSize
    limit: DEFAULT_LIMIT_OFFSET.LIMIT,
    hasMore: false,
  },
  status: ReduxStateType.INIT,
};

const GetListPortfolioSlice = createSlice({
  name: "getListPortfolioReport",
  initialState,
  reducers: {
    updateMasterAccountPortfolio: (state, action) => {
      state.data.masterAccount = action.payload;
    },
    updateAccountPortfolio: (state, action) => {
      state.data.account = action.payload;
    },
    updateOffsetPortfolio: (state, action) => {
      state.data.offset = action.payload;
    },
    updateLimitPortfolio: (state, action) => {
      state.data.limit = action.payload;
    },
    resetListPortfolio: (state) => {
      state.data = initialState.data;
    },
    getListPortfolioStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getListPortfolioFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      state.data.detailPortfolio = initialState.data.detailPortfolio;
      state.data.masterAccount = initialState.data.masterAccount;
      state.data.account = initialState.data.account;
    },
    getListPortfolioSuccess: (
      state,
      action: PayloadAction<{
        dataDetail: ResponseDetailPortfolio;
      }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      // state.data.detailPortfolio.total = action.payload.dataDetail.total;
      // state.data.detailPortfolio.items = action.payload.dataDetail.items;

      let data = action.payload.dataDetail.items; //items
      let { detailPortfolio, offset } = state.data;
      if (!data.length || data.length < (state?.data?.limit || 0)) {
        state.data.hasMore = false;
      } else {
        state.data.hasMore = true;
      }
      state.status = ReduxStateType.SUCCESS;
      state.data.detailPortfolio.items =
        offset && offset > 1
          ? [...detailPortfolio.items, ...action.payload.dataDetail.items]
          : action.payload.dataDetail.items;
    },
  },
});

export const {
  updateMasterAccountPortfolio,
  updateAccountPortfolio,
  updateOffsetPortfolio,
  updateLimitPortfolio,
  resetListPortfolio,
  getListPortfolioStart,
  getListPortfolioFailed,
  getListPortfolioSuccess,
} = GetListPortfolioSlice.actions;

export default GetListPortfolioSlice.reducer;
