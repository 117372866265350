import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface FormAuthState {
  dataAuth: {
    token: string;
    accountCode: string;
    roles: any;
    app: string;
    timeExp: number | string;
    sub: string;
  };
  status: ReduxStateType;
}

const initialState: FormAuthState = {
  dataAuth: {
    token: "",
    accountCode: "",
    roles: [],
    app: "",
    timeExp: "",
    sub: "",
  },
  status: ReduxStateType.ERROR,
};

const auth = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    authStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    authSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.dataAuth = action.payload;
    },
    authFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    logoutStart: (state, action) => {},
    resetAuth: () => {
      localStorage.clear();
      return initialState;
    },
  },
});

export const { authStart, authSuccess, authFailed, logoutStart, resetAuth } =
  auth.actions;

export default auth.reducer;
