import { Grid } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import React, { memo, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxField from "src/components/BoxField";
import TextField from "src/components/Forms/TextField";
import {
  NumberFormatBankCode,
  NumberFormatMobile,
} from "src/components/NumberFormat";
import {
  enterCallBack,
  enterFocus,
  handleValidate,
  handleValidateAdvanced,
} from "src/helper/validate";
import {
  resetFormRegister,
  updateInputFormRegister,
} from "../../redux/formRegister";
import { formRegister } from "../../redux/selector";
import { handleValidateTrading, validateInputEmail } from "../Login/validate";
const formRegisterSelector = createSelector(formRegister, (state) => ({
  accountCodeRegister: state.inputFormRegister.accountCodeRegister,
  accountName: state.inputFormRegister.accountName,
  emailRegister: state.inputFormRegister.emailRegister,
  mobile: state.inputFormRegister.mobile,
  bankNumber: state.inputFormRegister.bankNumber,
  bankCode: state.inputFormRegister.bankCode,
  referrerCode: state.inputFormRegister.referrerCode,
  checkValidate: state.inputFormRegister.checkValidate,
}));

function FormRegister({ handleSubmitRegister }) {
  const {
    accountCodeRegister,
    accountName,
    emailRegister,
    mobile,
    bankNumber,
    bankCode,
    referrerCode,
    checkValidate,
  } = useSelector(formRegisterSelector);

  const dispatch = useDispatch();
  // useEffect
  useEffect(() => {
    dispatch(resetFormRegister());
  }, []);
  // Hàm handle
  const handleInput = (e: any, name: string) => {
    dispatch(
      updateInputFormRegister({
        [name]: e.target.value,
      })
    );
  };
  // Tao hotkey
  const inputAccountCodeRef = useRef(null);
  const inputAccountNameRef = useRef(null);
  const inputEmailRef = useRef(null);
  const inputMobileRef = useRef(null);
  const inputBankNumberRef = useRef(null);
  const inputBankCodeRef = useRef(null);
  const inputReferrerCodeRef = useRef(null);
  // Validate
  const codeEmail = useMemo(() => {
    return validateInputEmail(emailRegister);
  }, [emailRegister]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BoxField required label="Số tài khoản">
          <TextField
            autoFocus
            inputRef={inputAccountCodeRef}
            value={accountCodeRegister}
            onChange={(e) => handleInput(e, "accountCodeRegister")}
            placeholder="Nhập số tài khoản"
            onKeyDown={(e) => enterFocus(e, inputAccountNameRef)}
            error={
              handleValidate(checkValidate, accountCodeRegister) ? true : false
            }
            helperText={handleValidate(checkValidate, accountCodeRegister)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField required label="Họ và tên">
          <TextField
            inputRef={inputAccountNameRef}
            value={accountName}
            onChange={(e) => handleInput(e, "accountName")}
            placeholder="Nhập họ và tên"
            onKeyDown={(e) => enterFocus(e, inputEmailRef)}
            error={handleValidate(checkValidate, accountName) ? true : false}
            helperText={handleValidate(checkValidate, accountName)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField required label="Email">
          <TextField
            value={emailRegister}
            inputRef={inputEmailRef}
            onChange={(e) => handleInput(e, "emailRegister")}
            placeholder="Nhập email"
            onKeyDown={(e) => enterFocus(e, inputMobileRef)}
            error={handleValidateAdvanced(checkValidate, codeEmail)}
            helperText={handleValidateTrading(checkValidate, codeEmail)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField label="Điện thoại">
          <TextField
            inputRef={inputMobileRef}
            value={mobile}
            onChange={(e) => handleInput(e, "mobile")}
            placeholder="Nhập số điện thoại"
            InputProps={{
              inputComponent: NumberFormatMobile as any,
            }}
            onKeyDown={(e) => enterFocus(e, inputBankNumberRef)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField label="STK ngân hàng">
          <TextField
            value={bankNumber}
            inputRef={inputBankNumberRef}
            onChange={(e) => handleInput(e, "bankNumber")}
            placeholder="Nhập số tài khoản ngân hàng"
            InputProps={{
              inputComponent: NumberFormatBankCode as any,
            }}
            onKeyDown={(e) => enterFocus(e, inputBankCodeRef)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField label="Ngân hàng mở TK">
          <TextField
            value={bankCode}
            inputRef={inputBankCodeRef}
            onChange={(e) => handleInput(e, "bankCode")}
            placeholder="Nhập NH mở tài khoản"
            onKeyDown={(e) => enterFocus(e, inputReferrerCodeRef)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField label="Tk người giới thiệu">
          <TextField
            value={referrerCode}
            inputRef={inputReferrerCodeRef}
            onChange={(e) => handleInput(e, "referrerCode")}
            placeholder="Nhập TK người giới thiệu"
            onKeyDown={(e) => enterCallBack(e, handleSubmitRegister)}
          />
        </BoxField>
      </Grid>
    </Grid>
  );
}

export default memo(FormRegister);
