import { createSlice } from "@reduxjs/toolkit";
import { addOrUpdateAccountParams } from "src/core/http/service/managementAccount/types";
import { roles } from "src/helper/const";
interface InitialState {
  inputForm: addOrUpdateAccountParams;
  checkValidate: boolean;
  isUpdate: boolean;
}

const initialState: InitialState = {
  inputForm: {
    accountCode: "",
    password: "",
    accountMobile: "",
    accountEmail: "",
    accountName: "",
    bankNumber: "",
    bankCode: "",
    interestRate: "",
    blockInterestOneWeek: "",
    buyFee: "",
    sellFee: "",
    // depositRate: 20,
    listAuthorize: [],
    roles: { value: roles.ROLE_USER, label: "User" },
    channelDto: [],
    accountStatus: true,
    referrerCode1: "",
    referrerCommission1: "",
    referrerCode2: "",
    referrerCommission2: "",
  },
  checkValidate: false,
  isUpdate: false,
};

const formAccountSlice = createSlice({
  name: "formAccount",
  initialState,
  reducers: {
    updateFormAccount: (state, action) => {
      Object.assign(state.inputForm, action.payload);
    },
    updateCheckValidate: (state, action) => {
      state.checkValidate = action.payload;
    },
    updateSideAccount: (state, action) => {
      state.isUpdate = action.payload;
    },
    resetFormAccount: () => initialState,
  },
});

export const {
  updateFormAccount,
  updateCheckValidate,
  resetFormAccount,
  updateSideAccount,
} = formAccountSlice.actions;

export default formAccountSlice.reducer;
