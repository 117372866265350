import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const PoliciesLazy = lazy(() => import("./pages"));

export const policiesRoutes = [
  {
    page: PoliciesLazy,
    path: paths.policies,
    roleSide: roles.ROLE_USER,
  },
];
