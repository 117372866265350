import { all } from "redux-saga/effects";
import authenicationSaga from "src/modules/Auth/saga";
import headerSaga from "src/layouts/SidebarLayout/Header/saga";
import managementAccountSaga from "src/modules/ManagementAccount/saga";
import channelSaga from "src/modules/Channel/saga";
import financeServiceSaga from "src/modules/FinanceService/saga";
import managementConnectSaga from "src/modules/ManagementConnect/saga";
import transferMoneySaga from "src/modules/TransferMoney/saga";
import stockTradingSaga from "src/modules/StockTrading/saga";
import stockTradingHandSaga from "src/modules/StockTradingHand/saga";
import staticInfoSaga from "src/redux-store/commons/staticInfo/saga";
import assetsValueReportSaga from "src/modules/AssetValueReport/saga";
import orderHistorySaga from "src/modules/OrderHistory/saga";
import userAssetsSaga from "src/modules/UserAssets/saga";
import reportSyntheticSaga from "src/modules/ReportSynthetic/saga";
import reportCategoryCustomerSaga from "src/modules/ReportCategoryCustomer/saga";
import recommendationSaga from "src/modules/Recommendations/saga";
import profitLossSaga from "src/modules/ReportPortfolioRealize/saga";
import forControlSaga from "src/modules/ForControlReport/saga";
import dividendSaga from "src/modules/Dividend/saga";
import channelPortfolioSaga from "src/modules/ReportPortfolioChannels/saga";
import reportPortfolioSaga from "src/modules/ReportPortfolio/saga";
import reportOrderCommissionSaga from "src/modules/ReportOrderCommission/saga";
import referralAccountSaga from "src/modules/ReferralAccount/saga";
import reportFeeSyntheticSaga from "src/modules/ReportFeeSynthetic/saga";
import settingsAuth2FASaga from "src/modules/SettingsAuth2FA/saga";

export default function* rootSaga() {
  yield all([
    channelSaga(),
    authenicationSaga(),
    headerSaga(),
    managementAccountSaga(),
    financeServiceSaga(),
    managementConnectSaga(),
    transferMoneySaga(),
    stockTradingSaga(),
    stockTradingHandSaga(),
    staticInfoSaga(),
    assetsValueReportSaga(),
    orderHistorySaga(),
    userAssetsSaga(),
    reportSyntheticSaga(),
    reportCategoryCustomerSaga(),
    recommendationSaga(),
    profitLossSaga(),
    forControlSaga(),
    dividendSaga(),
    channelPortfolioSaga(),
    reportPortfolioSaga(),
    reportOrderCommissionSaga(),
    referralAccountSaga(),
    reportFeeSyntheticSaga(),
    settingsAuth2FASaga(),
  ]);
}
