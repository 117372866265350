import { createSlice } from "@reduxjs/toolkit";
interface InitialState {
  side: string;
}
const initialState: InitialState = {
  side: "B",
};

const switchControl = createSlice({
  name: "switchControlSlice",
  initialState,
  reducers: {
    updateSwitch: (state, action) => {
      state.side = action.payload;
    },
  },
});

export const { updateSwitch } = switchControl.actions;

export default switchControl.reducer;
