import { createSlice, current } from "@reduxjs/toolkit";
import { compareArray } from "src/helper/const";
import { ReduxStateType } from "src/redux-store/types";

interface InitialState {
  data: any[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};
const listOrdersHand = createSlice({
  name: "listOrdersHandSlice",
  initialState,
  reducers: {
    listOrdersHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    listOrdersHandSuccess: (state, action) => {
      if (!compareArray(current(state.data), action.payload)) {
        state.data = action.payload;
      }
      //  trường hợp lấy mới dữ liệu mà không thay đổi thì sẽ ko cho updateState để rerender lại
      state.status = ReduxStateType.SUCCESS;
    },
    listOrdersHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
  },
});

export const {
  listOrdersHandStart,
  listOrdersHandSuccess,
  listOrdersHandFailed,
} = listOrdersHand.actions;

export default listOrdersHand.reducer;
