import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const DividendLazy = lazy(() => import("./pages"));

export const dividendRoutes = [
  {
    page: DividendLazy,
    path: paths.dividend,
    roleSide: roles.ROLE_EDITOR,
  },
];
