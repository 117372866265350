import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { registerApi } from "src/core/http/service/auth";
import { RootState } from "src/redux-store/store";
import { updateInputAuth } from "../redux/formAuth";
import {
  registerFailed,
  registerStart,
  registerSuccess,
} from "../redux/register";

function* registerStartWork() {
  const {
    accountCodeRegister,
    accountName,
    emailRegister,
    mobile,
    bankNumber,
    bankCode,
    referrerCode,
  } = yield select(
    (state: RootState) => state.authenication.formRegister.inputFormRegister
  );
  const params = {
    accountCode: accountCodeRegister,
    accountName: accountName,
    accountEmail: emailRegister,
    accountMobile: mobile,
    bankNumber,
    bankCode,
    referrerCode,
  };
  try {
    yield call(registerApi, params);
    yield put({
      type: registerSuccess.type,
    });
    toast.success(
      "Đăng ký thành công, thông tin tài khoản đã được gửi đến Email của bạn!",
      { autoClose: 4000 }
    );
    yield put({
      type: updateInputAuth.type,
      payload: {
        showRegister: false,
      },
    });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: registerFailed.type,
    });
  }
}

function* watchRegister() {
  yield takeLatest(registerStart.type, registerStartWork);
}

export default function* registerSaga() {
  yield all([watchRegister()]);
}
