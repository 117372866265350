import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { checkOtpStatusApi } from "src/core/http/service/auth";
import { accountInfoStaticSelector } from "src/redux-store/commons/staticInfo/redux/selector";
import {
  getCheckOtpStatusFailed,
  getCheckOtpStatusStart,
  getCheckOtpStatusSuccess,
} from "../redux/getCheckOtpStatus";
import { ResCheckOtpStatus } from "src/domain/models/auth";

function* getCheckOtpStatusStartWork(action) {
  const { accountCodeStatic } = yield select(accountInfoStaticSelector);
  const { payload } = action;
  const params = {
    accountCode: payload ? payload : accountCodeStatic,
  };
  try {
    const res: ResCheckOtpStatus = yield call(checkOtpStatusApi, params);
    yield put({
      type: getCheckOtpStatusSuccess.type,
      payload: res,
    });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: getCheckOtpStatusFailed.type,
    });
  }
}

function* watchGetCheckOtpStatus() {
  yield takeLatest(getCheckOtpStatusStart.type, getCheckOtpStatusStartWork);
}

export default function* getCheckOtpStatusSaga() {
  yield all([watchGetCheckOtpStatus()]);
}
