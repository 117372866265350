import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { orderCancelApi } from "src/core/http/service/stockTradingHand";
import { OrderCancelParams } from "src/core/http/service/stockTradingHand/types";
import { RootState } from "src/redux-store/store";
import { resetDeleteIDs } from "../redux/deleteOrderHand";
import { listOrdersHandStart } from "../redux/listOrdersHand";
import {
  orderCancelHandFailed,
  orderCancelHandStart,
  orderCancelHandSuccess,
  resetCancleIDs,
} from "../redux/orderCancelHand";
import { reportBuyingPowerHandStart } from "../redux/reportBuyingPowerHand";

function* orderCancelHandStartWork() {
  const { cancleIDs } = yield select(
    (state: RootState) => state.stockTradingHand.orderCancelHand
  );

  const params: OrderCancelParams = {
    ids: cancleIDs,
  };

  try {
    yield call(orderCancelApi, params);
    toast.success("Hủy lệnh thành công", {
      autoClose: 4000,
    });
    yield put({
      type: orderCancelHandSuccess.type,
    });
    yield put({
      type: resetCancleIDs.type,
    });
    yield put({
      type: resetDeleteIDs.type,
    });
    yield put(listOrdersHandStart());
    yield put(reportBuyingPowerHandStart());
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: orderCancelHandFailed.type,
    });
  }
}

function* watchorderCancelHand() {
  yield takeLatest(orderCancelHandStart.type, orderCancelHandStartWork);
}

export default function* orderCancelHandSaga() {
  yield all([watchorderCancelHand()]);
}
