import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

const initialState = {
  data: {},
  status: ReduxStateType.INIT,
};

const updateAccount = createSlice({
  name: "updateAccountSlice",
  initialState,
  reducers: {
    updateAccountStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    updateAccountSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    updateAccountFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { updateAccountStart, updateAccountSuccess, updateAccountFailed } =
  updateAccount.actions;

export default updateAccount.reducer;
