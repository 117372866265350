import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const ManagementCustomerLazy = lazy(() => import("./pages"));

export const managementCustomerRoutes = [
  {
    page: ManagementCustomerLazy,
    path: paths.managementCustomer,
    roleSide: roles.ROLE_ADMIN,
  },
];
