import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: any[];
  status: ReduxStateType;
}

const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const portfolioAccountsHand = createSlice({
  name: "portfolioAccountsHandSlice",
  initialState,
  reducers: {
    portfolioAccountsHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    portfolioAccountsHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    portfolioAccountsHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
  },
});

export const {
  portfolioAccountsHandStart,
  portfolioAccountsHandSuccess,
  portfolioAccountsHandFailed,
} = portfolioAccountsHand.actions;

export default portfolioAccountsHand.reducer;
