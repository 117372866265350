import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

interface InitialState {
  accountInfo: {
    accountAddress: string;
    accountCode: string;
    accountEmail: string;
    accountMobile: string;
    accountName: string;
    bankCode: string;
    bankNumber: string;
  };
  status: ReduxStateType;
}
const initialState: InitialState = {
  accountInfo: {
    accountAddress: "",
    accountCode: "",
    accountEmail: "",
    accountMobile: "",
    accountName: "",
    bankCode: "",
    bankNumber: "",
  },
  status: ReduxStateType.INIT,
};

const getAccountInfoStaticSlice = createSlice({
  name: "getAccountInfoStaticSlice",
  initialState,
  reducers: {
    getAccountInfoStaticStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getAccountInfoStaticSuccess: (state, action) => {
      state.accountInfo = Object.assign(state.accountInfo, action.payload);
      state.status = ReduxStateType.SUCCESS;
    },
    getAccountInfoStaticFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  getAccountInfoStaticStart,
  getAccountInfoStaticSuccess,
  getAccountInfoStaticFailed,
} = getAccountInfoStaticSlice.actions;

export default getAccountInfoStaticSlice.reducer;
