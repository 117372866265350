import financeHttp from "src/core/http/singleton/financeService";
import { GET_LIST_REPORT_FOR_CONTROL } from "./urls";
import { ReportForControlParams } from "./types";
import { ResponseForControl } from "src/domain/models/reportForControl";

//lay danh sach lai lo cua KH
export const getListReportForControlApi = async (
  params: ReportForControlParams
): Promise<ResponseForControl> => {
  const res = await financeHttp.get<ResponseForControl>(
    GET_LIST_REPORT_FOR_CONTROL,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
