import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const channelListConnect = (state: RootState) =>
  state.managementConnect.channelListConnect;
export const orderActiveConnect = (state: RootState) =>
  state.managementConnect.orderActiveConnect;
export const orderSessionConnect = (state: RootState) =>
  state.managementConnect.orderSessionConnect;
export const verifyOtpConnect = (state: RootState) =>
  state.managementConnect.verifyOtpConnect;
export const logOutConnect = (state: RootState) =>
  state.managementConnect.logOutConnect;
// ========================
export const managementConnect = (state: RootState) => state.managementConnect;
export const formModalSelector = createSelector(
  managementConnect,
  (state) => state.formModal
);
