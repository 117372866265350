import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";

import {
  getReportSyntheticStart,
  getReportSyntheticSuccess,
  getReportSyntheticFailed,
} from "../redux/reportSyntheticList";

import { FilterReportSyntheticState } from "../redux/selector";
import { ReportSyntheticParams } from "src/core/http/service/ReportSynthetic/types";
import { getListReportSyntheticApi } from "src/core/http/service/ReportSynthetic";
import { ResponseReportSynthetic } from "src/domain/models/reportSynthetic";

const filerReportSyntheticSelector = createSelector(
  FilterReportSyntheticState,
  (state) => ({
    filter: state.data.filter,
  })
);
function* getReportSynthetic(
  action: ReturnType<typeof getReportSyntheticStart>
) {
  try {
    const { filter } = yield select(filerReportSyntheticSelector);
    const params: ReportSyntheticParams = {
      ...filter,
    };
    const data: ResponseReportSynthetic = yield call(
      getListReportSyntheticApi,
      params
    );

    yield put({
      type: getReportSyntheticSuccess.type,
      payload: {
        data: data,
      },
    });
  } catch (error: any) {
    yield put(getReportSyntheticFailed(error));
  }
}

function* watchGetReportSynthetic() {
  yield takeLatest(getReportSyntheticStart.type, getReportSynthetic);
}

export default function* listReportSyntheticSaga() {
  yield all([watchGetReportSynthetic()]);
}
