import { Switch } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, sideswitch }) => ({
  padding: 0,
  borderRadius: "4px",
  width: "123px",
  height: 36,
  background: "transparent",
  border: `2px solid  ${sideswitch === "B" ? "#7bcfb0" : "#e99e9e"}`,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    border: "none",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(55px)",
      backgroundColor: "#f46a6a",
      borderRadius: "4px",
      width: "60px",
      height: "28px",
      lineHeight: "28px",
      color: "#fff",
      "& .MuiSwitch-thumb:before": {
        content: "'TẮT'",
        position: "absolute",
        left: "17px",
        top: "1px",
        width: "28px",
        height: "28px",
        fontSize: "14px",
        color: "#ffffff",
        backgroundColor: "transparent",
      },
      "& .MuiSwitch-thumb": {
        border: "none",
        backgroundColor: "#f46a6a!important",
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "transparent",
        borderRadius: 0,
        opacity: 1,
        border: "none",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "none",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {},
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    "&:before": {
      content: "'BẬT'",
      position: "absolute",
      width: "16px",
      height: "28px",
      left: "15px",
      top: "1px",
      background: "transparent",
      lineHeight: "28px",
      fontSize: "14px",
      color: "#ffffff",
    },
    boxSizing: "border-box",
    width: 60,
    height: 28,
    backgroundColor: "#34c38f!important",
    borderRadius: "4px",
    lineHeight: "28px",
    color: "#fff",
    border: "none",
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "transparent",
    border: "none",
    borderRadius: 0,
    "&:before": {
      content: '"BẬT"',
      position: "absolute",
      top: 15,
      transform: "translateY(-50%)",
      color: "grey",
      left: "15px",
      width: 16,
      height: 16,
    },
    "&:after": {
      content: '"TẮT"',
      position: "absolute",
      top: 15,
      right: 25,
      color: "grey",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
}));
function SwitchSetting2FA(props) {
  return <IOSSwitch {...props}></IOSSwitch>;
}

export default SwitchSetting2FA;
