import { Grid } from "@mui/material";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxField from "src/components/BoxField";
import InputPassword from "src/components/InputPassword";
import { enterCallBack, enterFocus, handleValidate } from "src/helper/validate";
import { updateInputPass } from "../../redux/changePass";
import { changePassSelector } from "../../redux/selector";
import { validateRepeatPass, validateRepeatPassText } from "../../validate";

function ChangePass({ handleSubmitChangePass, isRepeatPass }) {
  // Khai bao
  const dispatch = useDispatch();
  const { oldPass, newPass, repeatPass, checkValidatePass } =
    useSelector(changePassSelector);
  // Hàm tính toán

  // Tao hotkey
  const oldPassInputRef = useRef(null);
  const newPassInputRef = useRef(null);
  const repeatPassInputRef = useRef(null);

  const handleInput = (e: any, name: string) => {
    dispatch(
      updateInputPass({
        [name]: e.target.value,
      })
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BoxField required label="Mật khẩu hiện tại">
          <InputPassword
            autoFocus
            ref={oldPassInputRef}
            value={oldPass}
            onChange={(e) => handleInput(e, "oldPass")}
            placeholder="Nhập mật khẩu hiện tại"
            onKeyDown={(e) => enterFocus(e, newPassInputRef)}
            error={handleValidate(checkValidatePass, oldPass) ? true : false}
            helperText={handleValidate(checkValidatePass, oldPass)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField required label="Mật Khẩu mới">
          <InputPassword
            value={newPass}
            ref={newPassInputRef}
            onChange={(e) => handleInput(e, "newPass")}
            placeholder="Nhập mật khẩu mới"
            onKeyDown={(e) => enterFocus(e, repeatPassInputRef)}
            error={handleValidate(checkValidatePass, newPass) ? true : false}
            helperText={handleValidate(checkValidatePass, newPass)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={12}>
        <BoxField required label="Nhập lại mật khẩu mới">
          <InputPassword
            value={repeatPass}
            ref={repeatPassInputRef}
            onChange={(e) => handleInput(e, "repeatPass")}
            placeholder="Nhập lại mật khẩu mới"
            error={validateRepeatPass(
              checkValidatePass,
              repeatPass,
              isRepeatPass
            )}
            helperText={validateRepeatPassText(
              checkValidatePass,
              repeatPass,
              isRepeatPass
            )}
            onKeyDown={(e) => enterCallBack(e, handleSubmitChangePass)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={7} />
    </Grid>
  );
}

export default ChangePass;
