import { styled } from "@mui/styles";

export const WrapOtp = styled("div")({
  width: "100%",
  height: "100%",
  marginTop: "20px",
  marginLeft: "25px",
  "& input": {
    fontSize: "50px",
    width: "60px!important",
  },
  "& span": {
    fontSize: "50px",
  },
});
