import numeral from "numeral";

export const flattenObject = (inputObj: { [key: string]: any }) => {
  const outputObj: { [key: string]: string } = {};

  for (const i in inputObj) {
    if (!inputObj.hasOwnProperty(i)) continue;

    if (typeof inputObj[i] === "object") {
      const flatObject = flattenObject(inputObj[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        outputObj[i + "." + x] = flatObject[x];
      }
    } else {
      outputObj[i] = inputObj[i];
    }
  }
  return outputObj;
};

export const checkAssetsValueReport = (params: any) => {
  return typeof params === "number" && params !== 0
    ? numeral(params).format("0,0")
    : "-";
};

export const changeStatusWithCode = (code: any) => {
  switch (code) {
    case "02":
      return "Thường";
    case "60":
      return "OutR";
    case "68":
      return "TT68";
    default:
      return "Thường";
  }
};

export const changeLevelReferrralAccount = (code: any) => {
  switch (code) {
    case "1":
      return "Cấp 1 là người giới thiệu 1";
    case "2":
      return "Cấp 2 là người giới thiệu 2";
    default:
      return "";
  }
};
