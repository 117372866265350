import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ResCheckOtpStatus } from "src/domain/models/auth";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
  is2faStatus: boolean;
  is2faTele: boolean;
  is2faMail: boolean;
}

const initialState: InitialState = {
  status: ReduxStateType.ERROR,
  is2faStatus: false,
  is2faTele: false,
  is2faMail: false,
};

const getCheckOtpStatus = createSlice({
  name: "getCheckOtpStatusSlice",
  initialState,
  reducers: {
    getCheckOtpStatusStart: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.LOADING;
    },
    getCheckOtpStatusSuccess: (
      state,
      action: PayloadAction<ResCheckOtpStatus>
    ) => {
      const { is2faStatus, is2faTele, is2faMail } = action.payload;
      state.is2faStatus = is2faStatus;
      state.is2faTele = is2faTele;
      state.is2faMail = is2faMail;
    },
    getCheckOtpStatusFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    resetGetCheckOtpStatus: () => initialState,
  },
});

export const {
  getCheckOtpStatusStart,
  getCheckOtpStatusSuccess,
  getCheckOtpStatusFailed,
  resetGetCheckOtpStatus,
} = getCheckOtpStatus.actions;

export default getCheckOtpStatus.reducer;
