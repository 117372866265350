import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { ResponseReportPortfolio } from "src/domain/models/reportPortfolio";

export type ReportPortfolioState = {
  listReportPortfolio: ResponseReportPortfolio[];
};

const initialState: ReduxData<ReportPortfolioState> = {
  data: {
    listReportPortfolio: [],
  },
  status: ReduxStateType.INIT,
};

const reportPortfolio = createSlice({
  name: "reportPortfolio",
  initialState,
  reducers: {
    getReportPortfolioStart: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.LOADING;
    },
    getReportPortfolioSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.listReportPortfolio = action.payload.items;
    },
    getReportPortfolioFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
    resetGetReportPortfolio: () => initialState,
  },
});

export const {
  getReportPortfolioStart,
  getReportPortfolioSuccess,
  getReportPortfolioFailed,
  resetGetReportPortfolio,
} = reportPortfolio.actions;

export default reportPortfolio.reducer;
