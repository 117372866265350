import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { ReportPortfolioRealizeParams } from "src/core/http/service/reportPortfolioRealize/types";
import { addDays, format, subMonths } from "date-fns";

export type ReportPortfolioRealizeState = {
  listProfitLoss: any[];
  filter: ReportPortfolioRealizeParams;
  hasMore: boolean;
  totalRealized: number;
};

const initialState: ReduxData<ReportPortfolioRealizeState> = {
  data: {
    listProfitLoss: [],
    filter: {
      accountCode: "",
      shareCode: "",
      fromDate: format(subMonths(new Date(), 3), "dd/MM/yyyy"),
      toDate: format(addDays(new Date(), 1), "dd/MM/yyyy"),
    },
    hasMore: true,
    totalRealized: 0,
  },
  status: ReduxStateType.INIT,
};

const reportPortfolioRealizeSlice = createSlice({
  name: "reportPortfolioRealize",
  initialState,
  reducers: {
    updateFilter: (
      state,
      action: PayloadAction<ReportPortfolioRealizeParams>
    ) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    resetListReportPortfolioRealize: (state) => {
      state.data.listProfitLoss = initialState.data.listProfitLoss;
    },
    resetTotalRealized: (state) => {
      state.data.totalRealized = initialState.data.totalRealized;
    },
    getReportPortfolioRealizeStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getReportPortfolioRealizeFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
    getReportPortfolioRealizeSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.listProfitLoss = action.payload.items;
      const sumTotal: any = state.data.listProfitLoss.filter((item: any) => {
        return item?.accountCode === "SUM_ALL";
      });
      state.data.totalRealized = sumTotal[0]?.realized;
    },
  },
});

export const {
  updateFilter,
  resetFilter,
  resetTotalRealized,
  resetListReportPortfolioRealize,
  getReportPortfolioRealizeStart,
  getReportPortfolioRealizeSuccess,
  getReportPortfolioRealizeFailed,
} = reportPortfolioRealizeSlice.actions;

export default reportPortfolioRealizeSlice.reducer;
