import { validateEmail } from "src/helper/validate";

export const validateInputEmail = (email: string) => {
  if (email) {
    // validate cấp 1
    if (validateEmail(email)) {
      // validate cấp 2
      return errorCode.SUCCESS;
    } else {
      return errorCode.EMAIL_01;
    }
  } else {
    return errorCode.NONE_01;
  }
};

// Tạo mã lỗi cho trường cần validate
export const errorCode = {
  SUCCESS: true,
  NONE_01: "Vui lòng nhập vào chỗ trống",
  EMAIL_01: "Nhập sai định dạng Email",
};

// Hàm này tạo text cho error nhiều cấp
export const handleValidateTrading = (checkValidate: boolean, params: any) => {
  return checkValidate ? (params !== true ? params : "") : "";
};
