import { themeColors } from "src/themes/schemes/PureLightTheme";

export const TabStockTradingHand = {
  BUY: "B",
  SELL: "S",
  SELL_TO: "ST0",
};

export const dataTabsStockTradingHand = [
  { id: 0, tabName: "MUA" },
  { id: 1, tabName: "BÁN" },
  { id: 2, tabName: "BÁN T0" },
];

export const chooseBGSide = (side: string) => {
  switch (side) {
    case TabStockTradingHand.BUY:
      return themeColors.blueBg1;
    case TabStockTradingHand.SELL:
      return themeColors.redBg2;
    case TabStockTradingHand.SELL_TO:
      return themeColors.redBg3;
  }
};

export const reSidetoString = (side: number) => {
  switch (side) {
    case 0:
      return TabStockTradingHand.BUY;
    case 1:
      return TabStockTradingHand.SELL;
    case 2:
      return TabStockTradingHand.SELL_TO;
  }
};

export const reSideToNumber = (side: string) => {
  switch (side) {
    case TabStockTradingHand.BUY:
      return 0;
    case TabStockTradingHand.SELL:
      return 1;
    case TabStockTradingHand.SELL_TO:
      return 2;
  }
};

export const constSide = {
  BUY: "B",
  SELL: "S",
  SELL_TO: "T0",
  BUY_TO: "BT0",
};

export const checkSide = (typeSide: string, side: string) => {
  if (side === constSide.BUY && typeSide === "T0") {
    return "CỔ TỨC";
  } else if (side === constSide.SELL && typeSide === constSide.SELL_TO) {
    return "BÁN T0";
  } else {
    return side === constSide.BUY ? "MUA" : "BÁN";
  }
};
export const checkSideHand = (
  typeSide: string,
  side: string,
  price?: number
) => {
  if (side === constSide.BUY && price === 0) {
    return "CỔ TỨC";
  } else if (side === constSide.SELL && typeSide === constSide.SELL_TO) {
    return "BÁN T0";
  } else {
    return side === constSide.BUY ? "MUA" : "BÁN";
  }
};
export const checkSideType = (
  typeSide: string,
  side: string,
  price: number
) => {
  if (side === constSide.BUY && price === 0) {
    return "BT0";
  } else if (side === constSide.SELL && typeSide === constSide.SELL_TO) {
    return "T0";
  } else {
    return side === constSide.BUY ? "B" : "S";
  }
};

export const checkTypeSide = (side: string) => {
  switch (side) {
    case "B":
      return "B";
    case "S":
      return "S";
    case "T0":
      return "S";
  }
};
