import { toast } from "react-toastify";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { importExcelApi } from "src/core/http/service/stockTradingHand";
import { getBuyInfoHandStart } from "../redux/getBuyInfoHand";
import {
  importExcelOrderFailed,
  importExcelOrderStart,
  importExcelOrderSuccess,
} from "../redux/importExcelOrder";
import { listOrdersHandStart } from "../redux/listOrdersHand";
import { portfolioAccountsHandStart } from "../redux/portfolioAccountsHand";
import { reportBuyingPowerHandStart } from "../redux/reportBuyingPowerHand";

function* importExcelOrderStartWork(action) {
  yield delay(300);
  const params: any[] = [...action.payload];
  try {
    const res = yield call(importExcelApi, params);
    yield put({
      type: importExcelOrderSuccess.type,
      payload: res,
    });
    yield put(getBuyInfoHandStart());
    yield put(listOrdersHandStart());
    yield put(reportBuyingPowerHandStart());
    yield put(portfolioAccountsHandStart());
    toast.success("Import thành công lệnh!", { autoClose: 4000 });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    const mixErrMess =
      "Đã có lỗi xảy ra ở dòng thứ " +
      (Number(errMess) + 2) +
      " trong file Import";
    toast.error(mixErrMess, { autoClose: 4000 });
    yield put({
      type: importExcelOrderFailed.type,
    });
  }
}

function* watchImportExcelOrder() {
  yield takeLatest(importExcelOrderStart.type, importExcelOrderStartWork);
}

export default function* importExcelOrderSaga() {
  yield all([watchImportExcelOrder()]);
}
