import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";

const SettingsAuth2FALazy = lazy(() => import("./pages"));

export const settingsAuth2FARoutes = [
  {
    page: SettingsAuth2FALazy,
    path: paths.settingsAuth2FA,
    roleSide: roles.ROLE_USER,
  },
];
