import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: any[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const importExcelOrder = createSlice({
  name: "importExcelOrderSlice",
  initialState,
  reducers: {
    importExcelOrderStart: (state, action) => {
      state.status = ReduxStateType.LOADING;
    },
    importExcelOrderSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    importExcelOrderFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
  },
});

export const {
  importExcelOrderStart,
  importExcelOrderSuccess,
  importExcelOrderFailed,
} = importExcelOrder.actions;

export default importExcelOrder.reducer;
