import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const channelPortfolioState = (state: RootState) =>
  state.channelPortfolio.portfolioSlice;

const channelPortfolio = (state: RootState) =>
  state.channelPortfolio.portfolioSlice;
export const channelPortfolioSelector = createSelector(
  channelPortfolio,
  (state) => ({
    dataPortfolios: state.data.portfolios,
  })
);

export const filterChannelPortfolioState = (state: RootState) =>
  state.channelPortfolio.filterSlice;

const filterChannelPortfolio = (state: RootState) =>
  state.channelPortfolio.filterSlice;
export const filterChannelPortfolioSelector = createSelector(
  filterChannelPortfolio,
  (state) => ({
    channelCode: state.filter.channelCode,
  })
);
