import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLocalStorage } from "src/helper/localStorage";
import { defaulActiveTab } from "../../const";
interface InitialState {
  activeTab: string;
}
const initialState: InitialState = {
  activeTab: getLocalStorage("activeTab")
    ? getLocalStorage("activeTab")
    : defaulActiveTab.STOCK_TRADING,
};

const sideBar = createSlice({
  name: "sideBarSlice",
  initialState,
  reducers: {
    updateSideBar: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { updateSideBar } = sideBar.actions;

export default sideBar.reducer;
