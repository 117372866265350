import { createSlice } from "@reduxjs/toolkit";
import { TabStockTradingHand } from "../const";
interface InitialState {
  tabs: {
    activeTab: string;
  };
}
const initialState: InitialState = {
  tabs: {
    activeTab: TabStockTradingHand.BUY,
  },
};

const tabsOrderHand = createSlice({
  name: "tabsOrderHandSlice",
  initialState,
  reducers: {
    updateTabsOrderHand: (state, action) => {
      Object.assign(state.tabs, action.payload);
    },
    resetTabsOrderHand: () => initialState,
  },
});

export const { updateTabsOrderHand, resetTabsOrderHand } =
  tabsOrderHand.actions;

export default tabsOrderHand.reducer;
