import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  ReportBuyingPowerResponse,
  ShareHoldingResponse,
} from "src/domain/models/userAssets";
import { DEFAULT_LIMIT_OFFSET } from "src/helper/const";
import { ReduxData, ReduxStateType } from "src/redux-store/types";

export type UserAssetsState = {
  reportBuyingPower: ReportBuyingPowerResponse;
  shareHolding: ShareHoldingResponse[];
  selectedAccountCode: string;
  limit: number;
  offset: number;
};
const initialState: ReduxData<UserAssetsState> = {
  data: {
    selectedAccountCode: "",
    reportBuyingPower: {
      portfolioCurrent: 0,
      cashCurrent: 0,
      realized: 0,
      unrealized: 0,
      navCurrent: 0,
      rate: 0,
      realize: 0,
      remainingRealistic: 0,
      cashBlock: 0,
      buyingPower: 0,
      cashWithdraw: 0,
      cashDividend: 0,
      shareDividend: 0,
      nav: 0,
      depositRate: 0,
      cashDeposit: 0,
      interestRate: 0,
      dividendInclusiveRate: 0,
      buyFee: 0,
      requiredRate: 0,
      fsRate: 0,
    },
    shareHolding: [],
    offset: DEFAULT_LIMIT_OFFSET.OFFSET,
    limit: DEFAULT_LIMIT_OFFSET.LIMIT,
  },
  status: ReduxStateType.INIT,
};
const userAssetsSlice = createSlice({
  name: "userAssets",
  initialState,
  reducers: {
    loadMore: (state) => {
      const { limit, offset } = state.data;
      state.data.offset = limit + offset + 1;
    },
    resetAccountCode: (state) => {
      state.data.selectedAccountCode = "";
      Object.assign(
        state.data.reportBuyingPower,
        initialState.data.reportBuyingPower
      );
    },
    updateAccountCode: (state, action: PayloadAction<string>) => {
      state.data.selectedAccountCode = action.payload;
    },
    updateLimit: (state, action: PayloadAction<number>) => {
      state.data.limit = action.payload;
    },
    updateOffset: (state, action: PayloadAction<number>) => {
      state.data.offset = action.payload;
    },
    getReportBuyingPowerStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getReportBuyingPowerSuccess: (
      state,
      action: PayloadAction<ReportBuyingPowerResponse>
    ) => {
      Object.assign(state.data.reportBuyingPower, action.payload);
    },
    getShareHoldingStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    callApiFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
    getShareHoldingSuccess: (
      state,
      action: PayloadAction<ShareHoldingResponse[]>
    ) => {
      const reData = (action.payload || []).map((item) => ({
        ...item,
        sumTable: item?.accountCode,
      }));
      state.data.shareHolding = reData;
    },
  },
});
export const {
  loadMore,
  resetAccountCode,
  updateAccountCode,
  updateLimit,
  updateOffset,
  getReportBuyingPowerStart,
  getReportBuyingPowerSuccess,
  getShareHoldingStart,
  getShareHoldingSuccess,
  callApiFailed,
} = userAssetsSlice.actions;
export default userAssetsSlice.reducer;
