import basePathHttp from "src/core/http/singleton/basePathHttp";
import {
  ChannelListConnectRes,
  ManagementConnectRes,
} from "src/domain/models/managementConnect";
import {
  GetChannelListConnectParams,
  LogOutConnectParams,
  OrderActiveConnectParams,
  OrderSessionConnectParams,
  PostOrderActiveConnectVPSParams,
  VerifyOtpConnectParams,
} from "./types";
import {
  channelList,
  logout,
  orderActive,
  orderSession,
  verifyOtp,
} from "./urls";

export const orderActiveConnectApi = async (
  params: OrderActiveConnectParams
): Promise<ManagementConnectRes> => {
  const res = await basePathHttp.post<ManagementConnectRes>(
    orderActive,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const channelListConnectApi = async (
  params: GetChannelListConnectParams
): Promise<ChannelListConnectRes[]> => {
  const res = await basePathHttp.post<ChannelListConnectRes[]>(
    channelList,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const orderSessionConnectApi = async (
  params: OrderSessionConnectParams
): Promise<ManagementConnectRes> => {
  const res = await basePathHttp.get<ManagementConnectRes>(
    orderSession,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const verifyOtpConnectApi = async (
  params: VerifyOtpConnectParams
): Promise<ManagementConnectRes> => {
  const res = await basePathHttp.post<ManagementConnectRes>(verifyOtp, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const logOutConnectApi = async (
  params: LogOutConnectParams
): Promise<ManagementConnectRes> => {
  const res = await basePathHttp.post<ManagementConnectRes>(logout, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const orderActiveConnectVPSApi = async (
  params: PostOrderActiveConnectVPSParams
): Promise<ManagementConnectRes> => {
  const res = await basePathHttp.post<ManagementConnectRes>(
    orderActive,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
