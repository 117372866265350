// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";

import { getlistProfitLossState } from "../redux/selector";
import { ReportPortfolioRealizeParams } from "src/core/http/service/reportPortfolioRealize/types";
import { getListReportPortfolioRealizeApi } from "src/core/http/service/reportPortfolioRealize";
import {
  getReportPortfolioRealizeFailed,
  getReportPortfolioRealizeStart,
  getReportPortfolioRealizeSuccess,
} from "../redux/listProfitLoss";

const profitLossSelector = createSelector(getlistProfitLossState, (state) => ({
  filter: state.data.filter,
}));

function* getProfitLoss(
  action: ReturnType<typeof getReportPortfolioRealizeStart>
) {
  try {
    const { filter } = yield select(profitLossSelector);
    const params: ReportPortfolioRealizeParams = {
      ...filter,
    };
    yield delay(200);
    const res = yield call(getListReportPortfolioRealizeApi, params);
    yield put({
      type: getReportPortfolioRealizeSuccess.type,
      payload: {
        items: res,
      },
    });
  } catch (error: any) {
    yield put(getReportPortfolioRealizeFailed(error));
  }
}

function* watchGetProfitLoss() {
  yield takeLatest(getReportPortfolioRealizeStart.type, getProfitLoss);
}
export default function* getProfitLossSaga() {
  yield all([watchGetProfitLoss()]);
}
