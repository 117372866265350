import financeHttp from "src/core/http/singleton/financeService";
import {
  DividendCreateParams,
  DividendDeleteParams,
  DividendFilterParams,
  DividendUpdateParams,
  ResponseDividendAllItems,
} from "./types";
import {
  DELETE_DIVIDENDS,
  GET_LIST_DIVIDENDS,
  POST_CREATE_DIVIDENDS,
  PUT_UPDATE_DIVIDENDS,
} from "./urls";

export const getDividendFilterApi = async (
  params: DividendFilterParams
): Promise<ResponseDividendAllItems> => {
  const res = await financeHttp.get<ResponseDividendAllItems>(
    GET_LIST_DIVIDENDS,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postDividendCreateApi = async (
  params: DividendCreateParams
): Promise<any> => {
  const res = await financeHttp.post<any>(POST_CREATE_DIVIDENDS, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const putDividendUpdateApi = async (
  params: DividendUpdateParams
): Promise<any> => {
  const res = await financeHttp.put<any>(PUT_UPDATE_DIVIDENDS, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteDividendApi = async (
  params: DividendDeleteParams
): Promise<Object> => {
  const res = await financeHttp.delete<Object>(DELETE_DIVIDENDS(params.id));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
