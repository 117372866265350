import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: {
    buyingPower: number;
    cashBlock: number;
    cashCurrent: number;
    cashDeposit: number;
    navCurrent: number;
    portfolioCurrent: number;
    rate: number;
    realized: number;
    unrealized: number;
    cashWithdraw: number;
    cashCall: number;
    cashSellCall: number;
    depositRate: number;
  };
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: {
    buyingPower: 0,
    cashBlock: 0,
    cashCurrent: 0,
    cashDeposit: 0,
    navCurrent: 0,
    portfolioCurrent: 0,
    rate: 0,
    realized: 0,
    unrealized: 0,
    cashWithdraw: 0,
    cashCall: 0,
    cashSellCall: 0,
    depositRate: 0,
  },
  status: ReduxStateType.INIT,
};

const reportBuyingPower = createSlice({
  name: "reportBuyingPowerSlice",
  initialState,
  reducers: {
    reportBuyingPowerStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    reportBuyingPowerSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    reportBuyingPowerFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
    resetBuyingPower: () => initialState,
  },
});

export const {
  reportBuyingPowerStart,
  reportBuyingPowerSuccess,
  reportBuyingPowerFailed,
  resetBuyingPower,
} = reportBuyingPower.actions;

export default reportBuyingPower.reducer;
