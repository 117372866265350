import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderCommissionItems } from "src/domain/models/reportOrderCommission";

export type PortfolioState = {
  dataOrderCommission: OrderCommissionItems[];
};

const initialState: ReduxData<PortfolioState> = {
  data: {
    dataOrderCommission: [],
  },
  status: ReduxStateType.INIT,
};

const getOrderCommission = createSlice({
  name: "getOrderCommissionSlice",
  initialState,
  reducers: {
    getOrderCommissionStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getOrderCommissionSuccess: (
      state,
      action: PayloadAction<{
        items: OrderCommissionItems[];
      }>
    ) => {
      let { items } = action.payload;
      state.data.dataOrderCommission = items;
      state.status = ReduxStateType.SUCCESS;
    },
    getOrderCommissionFailed: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.ERROR;
      toast.error(action.payload || "Đã có lỗi xảy ra");
    },
    resetDataOrderCommission: (state) => {
      Object.assign(state.data, initialState.data);
    },
  },
});

export const {
  getOrderCommissionStart,
  getOrderCommissionSuccess,
  getOrderCommissionFailed,
  resetDataOrderCommission,
} = getOrderCommission.actions;

export default getOrderCommission.reducer;
