import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const ReportCategoryCustomerLazy = lazy(() => import("./pages"));

export const reportCategoryCustomerRoutes = [
  {
    page: ReportCategoryCustomerLazy,
    path: paths.reportCategoryCustomer,
    roleSide: roles.ROLE_EDITOR,
  },
];
