import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: {
    ceilingPrice: number;
    floorPrice: number;
    lastPrice: number;
    referencePrice: number;
    marginRate: number;
    buyingPower: number;
    maxBuy: number;
  };
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: {
    ceilingPrice: 0,
    floorPrice: 0,
    lastPrice: 0,
    referencePrice: 0,
    marginRate: 0,
    buyingPower: 0,
    maxBuy: 0,
  },
  status: ReduxStateType.INIT,
};

const getBuyInfoHand = createSlice({
  name: "getBuyInfoHandSlice",
  initialState,
  reducers: {
    getBuyInfoHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getBuyInfoHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    getBuyInfoHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
    resetGetBuyInfoHand: () => initialState,
  },
});

export const {
  getBuyInfoHandStart,
  getBuyInfoHandSuccess,
  getBuyInfoHandFailed,
  resetGetBuyInfoHand,
} = getBuyInfoHand.actions;

export default getBuyInfoHand.reducer;
