import { format } from "date-fns";
import numeral from "numeral";

export const checkOrderStatus = (status: string) => {
  switch (status) {
    case "PENDING":
      return true;
    case "PENDING_CANCEL":
      return false;
    case "NEW":
      return true;
    case "REJECT":
      return false;
    case "CANCEL":
      return false;
    case "FULFILL":
      return false;
    case "PARTIAL_FILL":
      return true;
    case "PARTIAL_FILL_CANCEL":
      return false;
    default:
      return false;
  }
};

export const dateText = (date) => {
  if (date) {
    return format(date, "dd/MM/yyyy HH:mm:ss");
  } else {
    return null;
  }
};
export const showTextThousand = (number: string | number) => {
  return number ? numeral(number).format("0,0") : null;
};
export const statusText = (status) => {
  switch (status) {
    case "PENDING":
      return "Chờ xử lý";
    case "PENDING_CANCEL":
      return "Chờ hủy";
    case "NEW":
      return "Chờ khớp";
    case "REJECT":
      return "Từ chối";
    case "CANCEL":
      return "Hủy";
    case "FULFILL":
      return "Khớp";
    case "PARTIAL_FILL":
      return "Khớp một phần";
    case "PARTIAL_FILL_CANCEL":
      return "Hủy một phần";
    default:
      return "";
  }
};
export const statusStyled = (status) => {
  switch (status) {
    case "PENDING":
      return "warning";
    case "PENDING_CANCEL":
      return "warning";
    case "NEW":
      return "warning";
    case "REJECT":
      return "error";
    case "CANCEL":
      return "error";
    case "FULFILL":
      return "success";
    case "PARTIAL_FILL":
      return "success";
    case "PARTIAL_FILL_CANCEL":
      return "success";
    default:
      return "";
  }
};

export const disableCheckBoxOrderStatus = (status: string) => {
  switch (status) {
    case "PENDING":
      return true;
    case "PENDING_CANCEL":
      return false;
    case "NEW":
      return true;
    case "REJECT":
      return false;
    case "CANCEL":
      return false;
    case "FULFILL":
      return false;
    case "PARTIAL_FILL":
      return true;
    case "PARTIAL_FILL_CANCEL":
      return false;
    default:
      return false;
  }
};

export const optionsStatus = [
  {
    value: "",
    label: "-- Tất cả --",
  },
  {
    value: "NEW",
    label: "Chờ khớp",
  },
  {
    value: "PARTIAL_FILL",
    label: "Khớp một phần",
  },
  {
    value: "FULFILL",
    label: "Đã Khớp",
  },
  {
    value: "CANCEL",
    label: "Đã hủy",
  },
  {
    value: "REJECT",
    label: "Từ chối",
  },
];

export const optionsSide = [
  {
    value: "",
    label: "-- Tất cả --",
  },
  {
    value: "B",
    label: "Mua",
  },
  {
    value: "S",
    label: "Bán",
  },
];
