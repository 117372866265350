import channelHttp from "src/core/http/singleton/channel";
import {
  ChannelItem,
  ResponseCreateChannel,
  ResponseDelChannel,
  ResponseUpdateChannel,
} from "src/domain/models/channel";
import { POST_CHANNELS, POST_CHANNEL, delChannel, PUT_CHANNEL } from "./urls";
import { FilterState } from "src/modules/Channel/redux/types";

export const getChannelsApi = async (
  params: FilterState
): Promise<ChannelItem[]> => {
  const { ...restParams } = params;
  const res = await channelHttp.post<ChannelItem[]>(POST_CHANNELS, restParams);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createChannelApi = async (
  params: FilterState
): Promise<ResponseCreateChannel> => {
  const { ...restParams } = params;
  const res = await channelHttp.post<ResponseCreateChannel>(
    POST_CHANNEL,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const delChannelApi = async (
  params: FilterState
): Promise<ResponseDelChannel> => {
  const res = await channelHttp.delete<ResponseDelChannel>(
    delChannel(params.channelCode)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const updateChannelApi = async (
  params: FilterState
): Promise<ResponseUpdateChannel> => {
  const { ...restParams } = params;
  const res = await channelHttp.put<ResponseUpdateChannel>(
    PUT_CHANNEL,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
