import { toast } from "react-toastify";
import { all, delay, put, takeLatest } from "redux-saga/effects";
import { logoutStart, resetAuth } from "../redux/auth";

function* logoutStartWork(action) {
  yield delay(500);
  try {
    if (action.payload === "exp") {
      toast.error("Hết phiên đăng nhập", { autoClose: 4000 });
    } else {
      toast.success("Đăng xuất thành công", { autoClose: 4000 });
    }
    yield put(resetAuth());
  } catch (error: any) {}
}

function* watchLogout() {
  yield takeLatest(logoutStart.type, logoutStartWork);
}

export default function* logoutSaga() {
  yield all([watchLogout()]);
}
