import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { verifyOtpConnectApi } from "src/core/http/service/managementConnect";
import { RootState } from "src/redux-store/store";
import { errorCode } from "src/languages/errorCode";
import {
  verifyOtpConnectFailed,
  verifyOtpConnectStart,
  verifyOtpConnectSuccess,
} from "../redux/verifyOtpConnect";
import { resetFormModal } from "../redux/formModal";
import { orderSessionConnectStart } from "../redux/orderSessionConnect";
import { VerifyOtpConnectParams } from "src/core/http/service/managementConnect/types";

function* verifyOtpConnectStartWork() {
  // store lấy giá trị mbsManageCode truyền lên
  yield delay(300);
  const { channelId } = yield select(
    (state: RootState) => state.managementConnect.formModal.inputConnect
  );
  const { inputOtp } = yield select(
    (state: RootState) => state.managementConnect.formModal.otp
  );

  const params: VerifyOtpConnectParams = {
    channelId,
    otp: inputOtp,
  };

  try {
    yield call(verifyOtpConnectApi, params);
    toast.success("Xác thực OTP thành công", {
      autoClose: 4000,
    });
    yield put({
      type: verifyOtpConnectSuccess.type,
    });
    yield put(orderSessionConnectStart());
    yield put(resetFormModal());
  } catch (error: any) {
    yield put({
      type: verifyOtpConnectFailed.type,
    });
    const errMess = error?.response?.data?.code;
    toast.error(errorCode[errMess] ?? "Xác thực thất bại");
  }
}

function* watchVerifyOtpConnect() {
  yield takeLatest(verifyOtpConnectStart.type, verifyOtpConnectStartWork);
}

export default function* verifyOtpConnectSaga() {
  yield all([watchVerifyOtpConnect()]);
}
