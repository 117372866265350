import { combineReducers } from "redux";
import dividendFilterSlice from "./getDividendFilter";
import dividendCreateSlice from "./postDividendCreate";
import dividenDeleteSlice from "./deleteDividend";
import dividenUpdateSlice from "./dividendUpdate";

export default combineReducers({
  dividendFilterSlice,
  dividendCreateSlice,
  dividenDeleteSlice,
  dividenUpdateSlice,
});
