import { all } from "@redux-saga/core/effects";
import financeService from "./financeService";
import deleteFinancialsSuspendSaga from "./deleteFinancialsSuspend";
import postFinancialsSuspendSaga from "./postFinancialsSuspend";
import importListGDKHQSaga from "./importListGDKHQ";
export default function* financeServiceSaga() {
  yield all([
    financeService(),
    deleteFinancialsSuspendSaga(),
    postFinancialsSuspendSaga(),
    importListGDKHQSaga(),
  ]);
}
