import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface RegisterState {
  status: ReduxStateType;
}

const initialState: RegisterState = {
  status: ReduxStateType.ERROR,
};

const register = createSlice({
  name: "registerSlice",
  initialState,
  reducers: {
    registerStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    registerSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    registerFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { registerStart, registerSuccess, registerFailed } =
  register.actions;

export default register.reducer;
