import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { ResponseReportSynthetic } from "src/domain/models/reportSynthetic";
import { ReportSyntheticParams } from "src/core/http/service/ReportSynthetic/types";

export type ReportSyntheticState = {
  data: ResponseReportSynthetic[];
  filter: ReportSyntheticParams;
  hasMore: boolean;
  totalRealized: number;
};

const initialState: ReduxData<ReportSyntheticState> = {
  data: {
    data: [],
    filter: {
      accountCode: "",
    },
    totalRealized: 0,
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const reportSyntheticListSlice = createSlice({
  name: "reportSynthetic",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<ReportSyntheticParams>) => {
      state.data.filter = {
        ...state.data.filter,
        ...action.payload,
      };
    },
    resetTotalRealized: (state) => {
      state.data.totalRealized = initialState.data.totalRealized;
    },
    clearFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    clearData: (state) => {
      state.data.data = initialState.data.data;
    },
    getReportSyntheticStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getReportSyntheticFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      state.data.filter = initialState.data.filter;
    },
    getReportSyntheticSuccess: (
      state,
      action: PayloadAction<{
        data: ResponseReportSynthetic[];
      }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.data = action.payload.data;
    },
  },
});

export const {
  updateFilter,
  clearFilter,
  clearData,
  resetTotalRealized,
  getReportSyntheticStart,
  getReportSyntheticSuccess,
  getReportSyntheticFailed,
} = reportSyntheticListSlice.actions;

export default reportSyntheticListSlice.reducer;
