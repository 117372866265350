import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "src/components/Modal";
import { logoutStart } from "src/modules/Auth/redux/auth";
import { accountInfoStaticSelector } from "src/redux-store/commons/staticInfo/redux/selector";
import { themeColors } from "src/themes/schemes/PureLightTheme";
import { updateInputPass } from "../redux/changePass";
import { updateInputInformation, updateShowModal } from "../redux/information";
import { postChangeInfoStart } from "../redux/postChangeInfo";
import { postChangePassStart } from "../redux/postChangePass";
import { changePassSelector, informationSelector } from "../redux/selector";
import ChangePass from "./ChangePass";
import Information from "./Information";
import {
  MenuUserBox,
  UserBoxButton,
  UserBoxDescription,
  UserBoxLabel,
  UserBoxText,
} from "./style";

const styleModal = {
  "& .MuiPaper-root": {
    width: "1000px",
    maxHeight: "100%",
    maxWidth: "1000px",
  },
};

const styleModalPass = {
  "& .MuiPaper-root": {
    width: "600px",
    maxHeight: "100%",
    maxWidth: "600px",
  },
};
function HeaderUserbox() {
  // khai bao
  const dispatch = useDispatch();
  const {
    accountEmail,
    accountName,
    bankCode,
    bankNumber,
    showInfo,
    showChangePass,
  } = useSelector(informationSelector);
  const { accountCodeStatic, accountNameStatic } = useSelector(
    accountInfoStaticSelector
  );
  const { oldPass, newPass, repeatPass } = useSelector(changePassSelector);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  // Handle show DropDown
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleLogOut = () => {
    dispatch(logoutStart("logout"));
  };
  // ====
  // Handle show form info
  const handleCloseInfo = (): void => {
    dispatch(
      updateShowModal({
        showInfo: false,
      })
    );
  };
  const handleOpenInfo = (): void => {
    dispatch(
      updateShowModal({
        showInfo: true,
      })
    );
  };
  const handleSubmitInfo = () => {
    if (accountName && accountEmail && bankCode && bankNumber) {
      dispatch(postChangeInfoStart());
      dispatch(
        updateShowModal({
          showInfo: false,
        })
      );
    } else {
      dispatch(
        updateInputInformation({
          checkValidateInfo: true,
        })
      );
    }
  };
  // ====
  // Handle show form changePass

  const handleCloseChangePass = (): void => {
    dispatch(
      updateShowModal({
        showChangePass: false,
      })
    );
  };
  const handleOpenChangePass = (): void => {
    dispatch(
      updateShowModal({
        showChangePass: true,
      })
    );
  };
  const isRepeatPass = useMemo(() => {
    return newPass === repeatPass ? true : false;
    // xét trường hợp pass viết lại pass mới phải trùng
  }, [newPass, repeatPass]);
  const handleSubmitChangePass = () => {
    if (oldPass && newPass && repeatPass && isRepeatPass) {
      dispatch(postChangePassStart());
      dispatch(
        updateInputPass({
          checkValidatePass: false,
        })
      );
    } else {
      dispatch(
        updateInputPass({
          checkValidatePass: true,
        })
      );
    }
  };
  // ====
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Hidden mdDown>
          <UserBoxText sx={{ mr: 2 }}>
            <UserBoxLabel variant="body1">{accountNameStatic}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {accountCodeStatic}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Avatar sx={{ background: themeColors.white1 }}>
          <PersonIcon sx={{ color: themeColors.black }} />
        </Avatar>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox display="flex" justifyContent="flex-end">
          <Hidden mdDown>
            <UserBoxText sx={{ mr: 2 }}>
              <UserBoxLabel blackcolor={1} variant="body1">
                {accountNameStatic}
              </UserBoxLabel>
              <UserBoxDescription blackcolor={1} variant="body2">
                {accountCodeStatic}
              </UserBoxDescription>
            </UserBoxText>
          </Hidden>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button onClick={handleOpenInfo}>
            <PermContactCalendarRoundedIcon fontSize="small" />
            <ListItemText primary="Thông tin TK" />
          </ListItem>
          <ListItem button onClick={handleOpenChangePass}>
            <KeyRoundedIcon fontSize="small" />
            <ListItemText primary="Đổi mật khẩu" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button onClick={handleLogOut} color="primary" fullWidth>
            <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
            Thoát
          </Button>
        </Box>
      </Popover>
      <Modal
        sx={styleModal}
        open={showInfo}
        closeText="Đóng"
        acceptText="Xác Nhận"
        content={<Information handleSubmitInformation={handleSubmitInfo} />}
        disableEnforceFocus
        onAccept={handleSubmitInfo}
        onClose={handleCloseInfo}
        title={"Thay đổi thông tin TK"}
      />
      <Modal
        sx={styleModalPass}
        open={showChangePass}
        closeText="Đóng"
        acceptText="Xác Nhận"
        content={
          <ChangePass
            handleSubmitChangePass={handleSubmitChangePass}
            isRepeatPass={isRepeatPass}
          />
        }
        disableEnforceFocus
        onAccept={handleSubmitChangePass}
        onClose={handleCloseChangePass}
        title={"Thay đổi mật khẩu"}
      />
    </>
  );
}

export default HeaderUserbox;
