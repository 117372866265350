import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { listAccountstApi } from "src/core/http/service/staticInfo";
import { roles as validateRoles } from "src/helper/const";
import { updateInputForm } from "src/modules/StockTrading/redux/formOrder";
import { updateInputFormHand } from "src/modules/StockTradingHand/redux/formOrderHand";
// import { updateInputForm } from "src/modules/StockTrading/redux/formOrder";
import { RootState } from "src/redux-store/store";
import {
  listAccountsStaticFailed,
  listAccountsStaticStart,
  listAccountsStaticSuccess,
} from "../redux/listAccountsStatic";

function* listAccountsStaticStartWork() {
  const { accountCode, roles } = yield select(
    (state: RootState) => state.authenication.auth.dataAuth
  );

  const params = {
    accountCode,
    roles,
  };

  try {
    const res = yield call(listAccountstApi, params);
    const filterActive = res.filter(
      (item) =>
        item.accountStatus === "ACTIVE" &&
        item?.roles[0] === validateRoles.ROLE_USER
    );
    const optionsListAcc = filterActive.map((item) => ({
      value: item?.accountCode,
      label: item?.accountCode + " - " + item?.accountName,
      accountName: item?.accountName,
    }));

    yield put({
      type: listAccountsStaticSuccess.type,
      payload: {
        dataListAcc: res,
        optionsListAcc,
      },
    });
    yield put({
      type: updateInputForm.type,
      payload: {
        accountCode: optionsListAcc[0],
      },
    });
    yield put({
      type: updateInputFormHand.type,
      payload: {
        accountCode: optionsListAcc[0],
      },
    });
  } catch (error: any) {
    yield put({
      type: listAccountsStaticFailed.type,
    });
  }
}

function* watchlistAccountsStatic() {
  yield takeLatest(listAccountsStaticStart.type, listAccountsStaticStartWork);
}

export default function* listAccountsStaticSaga() {
  yield all([watchlistAccountsStatic()]);
}
