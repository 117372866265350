import { Button, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { themeColors } from "src/themes/schemes/PureLightTheme";

export const UserBoxButton = styled(Button)(
  ({ theme }) => `
          display: flex ;
          padding-left: ${theme.spacing(1)};
          padding-right: ${theme.spacing(1)};
  `
);

export const MenuUserBox = styled(Box)(
  ({ theme }) => `
          background: ${theme.colors.alpha.black[5]};
          padding: ${theme.spacing(2)};
  `
);

export const UserBoxText = styled(Box)(
  ({ theme }) => `
          text-align: left;
          padding-left: ${theme.spacing(1)};
  `
);

export const UserBoxLabel = styled(Typography)<{ blackcolor?: number }>(
  ({ theme, blackcolor }) => `
          font-weight: ${theme.typography.fontWeightBold};
          color: ${blackcolor ? themeColors.black : themeColors.white};
          display: block;
  `
);

export const UserBoxDescription = styled(Typography)<{ blackcolor?: number }>(
  ({ theme, blackcolor }) => `
    color: ${blackcolor ? themeColors.black : themeColors.white1};
          font-size: 12px;
  `
);

export const WrapIconHeader = styled(Box)``;
