import HttpClient from "src/core/http";
export default class financeHttp extends HttpClient {
  private static classInstance?: financeHttp;
  private constructor() {
    super(process.env.REACT_APP_RESTFUL_BASE_URL || "");
  }
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new financeHttp();
    }
    return this.classInstance;
  }
}
