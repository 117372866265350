import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import {
  getFinanceServiceApi,
  postFinanceServiceApi,
} from "src/core/http/service/financeService";

import {
  getFinanceServicesStart,
  getFinanceServicesFailed,
  getFinanceServicesSuccess,
} from "../redux/financeServiceList";
import {
  insertFinanceServicesStart,
  insertFinanceServicesSuccess,
  insertFinanceServicesFailed,
} from "../redux/insertFinanceService";

import {
  FilterFinanceServiceState,
  InsertFinanceServiceState,
} from "../redux/selector";
import { FilterFinanceServiceItem } from "src/core/http/service/financeService/types";
import { ResponseFinanceServices } from "src/domain/models/financeServices";
import { toast } from "react-toastify";

const filterFinanceServiceSelector = createSelector(
  FilterFinanceServiceState,
  (state) => ({
    filter: state.data.filter,
  })
);

const insertFinanceServiceSelector = createSelector(
  InsertFinanceServiceState,
  (state) => ({
    data: state.data.insertData,
  })
);

function* getFinanceServices(
  action: ReturnType<typeof getFinanceServicesStart>
) {
  try {
    const { filter } = yield select(filterFinanceServiceSelector);
    const params: FilterFinanceServiceItem = {
      ...filter,
    };
    const data: ResponseFinanceServices = yield call(
      getFinanceServiceApi,
      params
    );

    yield put({
      type: getFinanceServicesSuccess.type,
      payload: {
        financeSerive: data,
      },
    });
  } catch (error: any) {
    yield put(getFinanceServicesFailed(error));
  }
}

function* insertFinanceServices(
  action: ReturnType<typeof insertFinanceServicesStart>
) {
  try {
    const { data } = yield select(insertFinanceServiceSelector);
    yield call(postFinanceServiceApi, data);
    yield put({ type: insertFinanceServicesSuccess.type });
    yield put(getFinanceServicesStart());
    toast.success("Import Danh Mục thành công.");
  } catch (error: any) {
    yield put(insertFinanceServicesFailed(error));
  }
}

function* watchGetFinanceServices() {
  yield takeLatest(getFinanceServicesStart.type, getFinanceServices);
}
function* watchInsertFinanceServices() {
  yield takeLatest(insertFinanceServicesStart.type, insertFinanceServices);
}

export default function* financeServiceSaga() {
  yield all([watchGetFinanceServices(), watchInsertFinanceServices()]);
}
