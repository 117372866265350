import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inputFormForgetPass: {
    emailForgetPass: "",
    checkValidate: false,
  },
};

const formForgetPassSlice = createSlice({
  name: "formForgetPass",
  initialState,
  reducers: {
    updateInputFormForgetPass: (state, action) => {
      Object.assign(state.inputFormForgetPass, action.payload);
    },
    resetFormForgetPass: () => initialState,
  },
});

export const { updateInputFormForgetPass, resetFormForgetPass } =
  formForgetPassSlice.actions;

export default formForgetPassSlice.reducer;
