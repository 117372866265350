import { persistReducer, persistStore } from "redux-persist";
import sagaMiddleware, { setupMiddleware } from "./middleware";

import {
  AnyAction,
  CombinedState,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";

import { persistConfig } from "./options";
import rootReducer from "./reducers";

export type RootState = ReturnType<typeof rootReducer>;
const persistedReducer = persistReducer(persistConfig, rootReducer) as Reducer<
  CombinedState<RootState>,
  AnyAction
>;

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: true,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

setupMiddleware();

let persistor = persistStore(store);

export { store, persistor };
