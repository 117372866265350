import { managementAccountRoutes } from "src/modules/ManagementAccount/route";
import { managementCustomerRoutes } from "src/modules/ManagementCustomer/route";
import { financeServiceRoutes } from "src/modules/FinanceService/route";
import { channelRoutes } from "src/modules/Channel/route";
import { transferMoneyRoutes } from "src/modules/TransferMoney/route";
import { managementConnectRoutes } from "src/modules/ManagementConnect/route";
import { stockTradingRoutes } from "src/modules/StockTrading/route";
import { AssetValueReportRoutes } from "src/modules/AssetValueReport/route";
import { userAssetsRoutes } from "src/modules/UserAssets/route";
import { orderHistoryRoutes } from "src/modules/OrderHistory/route";
import { StockTradingHandRoutes } from "src/modules/StockTradingHand/route";
import { reportSyntheticRoutes } from "src/modules/ReportSynthetic/route";
import { recommendationRoutes } from "src/modules/Recommendations/route";
import { reportPortfolioRealizeRoutes } from "src/modules/ReportPortfolioRealize/route";
import { reportCategoryCustomerRoutes } from "src/modules/ReportCategoryCustomer/route";
import { reportForControlRoutes } from "src/modules/ForControlReport/route";
import { dividendRoutes } from "src/modules/Dividend/route";
import { channelPortfolioRoutes } from "src/modules/ReportPortfolioChannels/route";
import { reportPortfolioRoutes } from "src/modules/ReportPortfolio/route";
import { reportOrderCommissionRoutes } from "src/modules/ReportOrderCommission/route";
import { referralAccountRoutes } from "src/modules/ReferralAccount/route";
import { reportFeeSyntheticRoutes } from "src/modules/ReportFeeSynthetic/route";
import { policiesRoutes } from "src/modules/ReportPolicies/route";
import { introduceRoutes } from "src/modules/Introduce/route";
import { settingsAuth2FARoutes } from "src/modules/SettingsAuth2FA/route";
const routesApp = [
  ...managementAccountRoutes,
  ...managementCustomerRoutes,
  ...financeServiceRoutes,
  ...channelRoutes,
  ...transferMoneyRoutes,
  ...managementConnectRoutes,
  ...stockTradingRoutes,
  ...AssetValueReportRoutes,
  ...userAssetsRoutes,
  ...orderHistoryRoutes,
  ...StockTradingHandRoutes,
  ...reportSyntheticRoutes,
  ...recommendationRoutes,
  ...reportPortfolioRealizeRoutes,
  ...reportCategoryCustomerRoutes,
  ...reportForControlRoutes,
  ...dividendRoutes,
  ...channelPortfolioRoutes,
  ...reportPortfolioRoutes,
  ...reportOrderCommissionRoutes,
  ...referralAccountRoutes,
  ...reportFeeSyntheticRoutes,
  ...policiesRoutes,
  ...introduceRoutes,
  ...settingsAuth2FARoutes,
];

export default routesApp;
