import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const getOrderHistoryState = (state: RootState) =>
  state.orderHistory.orderHistorySlice;
export const exportExelOrderState = (state: RootState) =>
  state.orderHistory.exportExel;

const orderHistory = (state: RootState) => state.orderHistory.orderHistorySlice;
export const orderHistorySelector = createSelector(orderHistory, (state) => ({
  filter: state.data.filter,
  hasMore: state.data.hasMore,
  orderHistories: state.data.orderHistories,
  status: state.status,
}));
