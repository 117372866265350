import React from "react";
import { ThemeProvider } from "@mui/material";
import { themeCreator } from "./base";
import { StylesProvider } from "@mui/styles";
import { createSelector } from "@reduxjs/toolkit";
import { themes } from "./selector";
import { useSelector } from "react-redux";

const themeSelector = createSelector(themes, (state) => ({
  mode: state.mode,
}));

const ThemeProviderWrapper: React.FC = (props) => {
  const { mode } = useSelector(themeSelector);
  const theme = themeCreator(mode);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
