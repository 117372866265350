import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: any[];
  status: ReduxStateType;
}

const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const portfolioAccounts = createSlice({
  name: "portfolioAccountsSlice",
  initialState,
  reducers: {
    portfolioAccountsStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    portfolioAccountsSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    portfolioAccountsFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
  },
});

export const {
  portfolioAccountsStart,
  portfolioAccountsSuccess,
  portfolioAccountsFailed,
} = portfolioAccounts.actions;

export default portfolioAccounts.reducer;
