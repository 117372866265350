import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { orderRemoveApi } from "src/core/http/service/stockTradingHand";
import { OrderRemoveParams } from "src/core/http/service/stockTradingHand/types";
import { RootState } from "src/redux-store/store";
import { listOrdersHandStart } from "../redux/listOrdersHand";
import {
  deleteOrderHandFailed,
  deleteOrderHandStart,
  deleteOrderHandSuccess,
  resetDeleteIDs,
} from "../redux/deleteOrderHand";
import { reportBuyingPowerHandStart } from "../redux/reportBuyingPowerHand";
import { resetCancleIDs } from "../redux/orderCancelHand";

function* deleteOrderHandStartWork() {
  const { deleteIDs } = yield select(
    (state: RootState) => state.stockTradingHand.deleteOrderHand
  );
  const params: OrderRemoveParams = {
    ids: deleteIDs,
  };

  try {
    yield call(orderRemoveApi, params);
    toast.success("Xóa lệnh thành công", {
      autoClose: 4000,
    });
    yield put({
      type: deleteOrderHandSuccess.type,
    });
    yield put({
      type: resetCancleIDs.type,
    });
    yield put({
      type: resetDeleteIDs.type,
    });
    yield put(listOrdersHandStart());
    yield put(reportBuyingPowerHandStart());
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: deleteOrderHandFailed.type,
    });
  }
}

function* watchDeleteOrderHand() {
  yield takeLatest(deleteOrderHandStart.type, deleteOrderHandStartWork);
}

export default function* deleteOrderHandSaga() {
  yield all([watchDeleteOrderHand()]);
}
