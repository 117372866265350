// import { Notification } from "@arco-design/web-react";
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { getLocalStorage } from "src/helper/localStorage";
import { logoutStart } from "src/modules/Auth/redux/auth";
import { store } from "src/redux-store/store";

enum LogType {
  REQUEST = "req",
  RESPONSE = "res",
  ERROR = "err",
}

const requestLog = (
  method: string = "",
  url: string = "",
  data: any,
  type: LogType,
  baseURL: string
) => {
  const tag =
    type === LogType.REQUEST || type === LogType.RESPONSE
      ? method
      : LogType.ERROR;
  const colors = {
    [LogType.REQUEST]: "blue",
    [LogType.RESPONSE]: "green",
    [LogType.ERROR]: "red",
  };
  const icons = {
    [LogType.REQUEST]: ">>>",
    [LogType.RESPONSE]: "<<<",
    [LogType.ERROR]: "xxx",
  };

  log(
    `%c${icons[type]} [${tag.toUpperCase()}] | %c${url.replace(
      baseURL,
      ""
    )} \n`,
    `color: ${colors[type]}; font-weight: bold`,
    "color: violet; font-weight: bold",
    data
  );
};

//  Style log trạng thái req và res
const log = (...params: any) => {
  if (process.env.NODE_ENV === `development`) {
    // eslint-disable-next-line no-console
    console.log(...params);
  }
};

abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleRequestError
    );

    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = (response: AxiosResponse) => {
    const {
      config: { method, url, baseURL },
    } = response;
    requestLog(method, url, response, LogType.RESPONSE, baseURL || "");
    return response;
  };

  private _handleRequestError = (error: any) => {
    log("request.error", error?.response?.data);
    return Promise.reject(error);
  };

  // middleware request
  private _handleRequest = (req: AxiosRequestConfig) => {
    let token = getLocalStorage("token");
    if (token) {
      req.headers = {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      };
    } else {
      req.headers = { "content-type": "application/json" };
    }
    const { method, url, baseURL } = req;
    requestLog(method, url, req, LogType.REQUEST, baseURL || "");
    return req;
  };

  private _handleError = (error: any) => {
    if (
      error?.response?.status === 401 &&
      error?.response?.data === "Unauthorized !"
    ) {
      store.dispatch(logoutStart("exp"));
    }
    log("response.error", error);
    return Promise.reject(error);
  };

  public get = <T>(
    url: string,
    params = {},
    config: AxiosRequestConfig = {}
  ): AxiosPromise<T> => this.instance.get<T>(url, { params, ...config });

  public post = <T>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ) => this.instance.post<T>(url, data, { ...config });

  public put = <T>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ) => this.instance.put<T>(url, data, { ...config });

  public patch = <T>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ) => this.instance.patch<T>(url, data, { ...config });

  public delete = <T>(url: string, config: AxiosRequestConfig = {}) =>
    this.instance.delete<T>(url, { ...config });
}

export default HttpClient;
