import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { DividendUpdateParams } from "src/core/http/service/dividend/types";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { categoryDividendCreate } from "../const";

export type DividendUpdateState = {
  dividends: DividendUpdateParams;
  hasMore: boolean;
  isValidate: boolean;
  value: string;
};

const initialState: ReduxData<DividendUpdateState> = {
  data: {
    dividends: {
      id: 0,
      accountCode: "",
      dividendType: categoryDividendCreate[0].value,
      shareCode: "",
      shareVolume: "",
      cash: "",
      returnDay: new Date(),
      content: "",
      channelCode: "",
      isRightBuy: 0,
    },
    isValidate: false,
    value: "",
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const dividendUpdateSlice = createSlice({
  name: "dividendUpdate",
  initialState,
  reducers: {
    updateDividendUpdate: (
      state,
      action: PayloadAction<DividendUpdateParams>
    ) => {
      Object.assign(state.data.dividends, action.payload);
    },
    resetUpdate: (state) => {
      state.data.dividends = initialState.data.dividends;
    },
    updateValidateFormUpdate: (state, action: PayloadAction<boolean>) => {
      state.data.isValidate = action.payload;
    },
    resetValidateFormUpdate: (state) => {
      state.data.isValidate = initialState.data.isValidate;
    },
    updateValueFormUpdate: (state, action: PayloadAction<string>) => {
      state.data.value = action.payload;
    },
    resetValueFormUpdate: (state) => {
      state.data.value = initialState.data.value;
    },
    dividendUpdateStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    dividendUpdateFailed: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.ERROR;
      toast.error(action.payload || "Đã có lỗi xảy ra.");
    },
    dividendUpdateSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      toast.success("Cập nhật thành công.");
    },
  },
});

export const {
  updateDividendUpdate,
  resetUpdate,
  updateValidateFormUpdate,
  resetValidateFormUpdate,
  updateValueFormUpdate,
  resetValueFormUpdate,
  dividendUpdateStart,
  dividendUpdateFailed,
  dividendUpdateSuccess,
} = dividendUpdateSlice.actions;

export default dividendUpdateSlice.reducer;
