import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "src/redux-store/store";
import { auth2faFailed, auth2faStart, auth2faSuccess } from "../redux/auth2fa";
import { login2faApi } from "src/core/http/service/auth";
import { ResLogin2FA } from "src/domain/models/auth";
import { authStart } from "../redux/auth";

function* auth2faStartWork() {
  yield delay(200);
  const { accountCode, password } = yield select(
    (state: RootState) => state.authenication.formAuth.inputAuth
  );
  const params = {
    accountCode,
    password,
  };
  try {
    const res: ResLogin2FA = yield call(login2faApi, params);
    yield put({
      type: auth2faSuccess.type,
      payload: res,
    });
    if (res.is2faStatus) {
      if (res.is2faTele && res.is2faMail) {
        toast.success("Mã OTP đã gửi về Mail và Telegram, Vui lòng xác thực");
      } else if (res.is2faMail) {
        toast.success("Mã OTP đã gửi về Mail, Vui lòng xác thực");
      } else if (res.is2faTele && res.isTeleRegister) {
        toast.success("Mã OTP đã gửi về Telegram, Vui lòng xác thực");
      }
    } else if (!res.is2faStatus) {
      yield put({
        type: authStart.type,
      });
    }
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess);
    yield put({
      type: auth2faFailed.type,
    });
  }
}

function* watchAuth2fa() {
  yield takeLatest(auth2faStart.type, auth2faStartWork);
}

export default function* auth2faSaga() {
  yield all([watchAuth2fa()]);
}
