import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const staticInfoState = (state: RootState) =>
  state.staticInfo.listAccountsStatic;

const listAccountsStatic = (state: RootState) =>
  state.staticInfo.listAccountsStatic;
export const listAccountsStaticSelector = createSelector(
  listAccountsStatic,
  (state) => ({
    dataListAcc: state.data.dataListAcc,
    optionsListAcc: state.data.optionsListAcc,
  })
);

export const accountInfoState = (state: RootState) =>
  state.staticInfo.accountInfo;
const accountInfoStatic = (state: RootState) =>
  state.staticInfo.getAccountInfoStatic;
export const accountInfoStaticSelector = createSelector(
  accountInfoStatic,
  (state) => ({
    accountAddress: state.accountInfo.accountAddress,
    accountCodeStatic: state.accountInfo.accountCode,
    accountEmail: state.accountInfo.accountEmail,
    accountMobile: state.accountInfo.accountMobile,
    accountNameStatic: state.accountInfo.accountName,
    bankCode: state.accountInfo.bankCode,
    bankNumber: state.accountInfo.bankNumber,
  })
);
export const listSharesStatic = (state: RootState) =>
  state.staticInfo.listSharesStatic;
export const listSharesStaticSelector = createSelector(
  listSharesStatic,
  (state) => state
);
export const channelListStatic = (state: RootState) =>
  state.staticInfo.channelListStatic;
