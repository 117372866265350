import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SidebarWrapper = styled(Box)`
  width: ${({ theme }) => theme.sidebar.width};
  color: ${({ theme }) => theme.sidebar.textColor};
  background: ${({ theme }) => theme.sidebar.background};
  box-shadow: ${({ theme }) => theme.sidebar.background};
  height: 100%;
  position: fixed;
  z-index: 10;
`;

export const TopSection = styled(Box)`
  height: ${({ theme }) => theme.header.height};
`;

export const WrapSideBarItem = styled(Box)<{ active?: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 55px;
  gap: 2px;
  color: ${({ active, theme }) =>
    active
      ? theme.sidebar.sideBarItem.active
      : theme.sidebar.sideBarItem.deactive};
  background-color: ${({ active, theme }) =>
    active ? theme.sidebar.sideBarItem.hoverBGActive : ""};
  border-right: ${({ active, theme }) =>
    active ? "4px solid " + theme.sidebar.sideBarItem.active : "none"};
  justify-content: center;
  align-items: center;
  &:hover {
    border-right: 4px solid ${({ theme }) => theme.sidebar.sideBarItem.active};
    background-color: ${({ theme }) => theme.sidebar.sideBarItem.deactive};
    color: ${({ theme }) => theme.sidebar.sideBarItem.active};
  }
`;

export const ListSubItem = styled("div")`
  position: absolute;
  background: ${({ theme }) => theme.sidebar.background};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  top: 0;
  left: ${({ theme }) => theme.sidebar.width};
  width: 230px;
  z-index: 1000;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`;
export const WrapSelectItem = styled("div")`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px, rgba(0, 0, 0, 0.24) 0px 1px 1px;
  border-bottom-right-radius: 4px;
  svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.sidebar.sideBarItem.hoverBGActive};
  }
`;

export const ItemNew = styled("div")`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 22px;
  color: white;
  height: 10px;
  text-align: center;
  border-radius: 3px;
  font-size: 7px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.tabsOrder.sellT0};
`;

export const Text = styled("div")`
  font-size: 9px;
  font-weight: bold;
`;
