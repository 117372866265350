import { all } from "redux-saga/effects";

import listAccountsStaticSaga from "./listAccountsStatic";
import accountInfoSaga from "./accountInfoSaga";
import getAccountInfoStaticSaga from "./getAccountInfoStatic";
import listSharesStaticSaga from "./listSharesStatic";
import channelListStaticSaga from "./channelListStatic";

export default function* staticInfoSaga() {
  yield all([
    listAccountsStaticSaga(),
    accountInfoSaga(),
    getAccountInfoStaticSaga(),
    listSharesStaticSaga(),
    channelListStaticSaga(),
  ]);
}
