import React, { memo } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DialogProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonProps } from "../Button";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  hasIconClose: boolean;
}

const ModalTitle = (props: DialogTitleProps) => {
  const { children, onClose, hasIconClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: 700 }} {...other}>
      {children}
      {hasIconClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
interface ModalProps extends DialogProps {
  /**
   * Props bắt buộc, dùng để hiển thị nội dung modal ví dụ: Form nhập liệu, confirm gì đó hoặc 1 câu thông báo
   * @requires
   */
  content: string | React.ReactNode;
  /**
   * Props này dùng để hiển thị modal nếu true => Modal mở >< false => Modal đóng
   * @default false
   */
  isOpen?: boolean;
  /**
   * Nếu có thì sẽ show icon dấu x đóng trên title và button đóng modal
   */
  onClose?: () => void;
  /**
   * Props này dùng để hiển thị text nút đóng modal
   * @default "Đóng"
   */
  closeText?: string;
  /**
   * Nếu có thì sẽ show nút accept dùng để xử lý lúc accept modal (ví dụ submit form)
   */
  onAccept?: () => void;
  /**
   * Props này dùng để hiển thị text nút accept modal ví dụ: Xác nhận, OK, Được thôi, Ok man
   * @default "Xác nhận"
   */
  acceptText?: string;
  isLoadingAccept?: boolean;
  minWidthButton?: string;

  /**
   * Props show button close?
   */
  hasIconClose?: boolean;
  /**
   * Props cho button accept
   */
  acceptBtnProps?: ButtonProps;

  /**
   * Props xét height của DialogContent
   */
  height?: string;
}

const Modal: React.FC<ModalProps> = ({
  title,
  onClose,
  onAccept,
  isLoadingAccept = false,
  minWidthButton = "100px",
  height = "auto",
  acceptText,
  closeText,
  open,
  content,
  hasIconClose,
  acceptBtnProps,
  ...rest
}) => {
  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        {...rest}
      >
        {title && (
          <ModalTitle
            id="customized-dialog-title"
            onClose={onClose}
            hasIconClose={hasIconClose}
          >
            {title}
          </ModalTitle>
        )}
        <DialogContent sx={{ height: height }} dividers>
          {content}
        </DialogContent>
        <DialogActions>
          {onAccept && (
            <LoadingButton
              loading={isLoadingAccept}
              loadingPosition="end"
              endIcon={<></>}
              variant="contained"
              onClick={onAccept}
              sx={{ minWidth: minWidthButton }}
              {...acceptBtnProps}
            >
              {acceptText || "Xác nhận"}
            </LoadingButton>
          )}
          {onClose && (
            <Button
              variant="contained"
              color="error"
              onClick={onClose}
              sx={{ minWidth: minWidthButton }}
            >
              {closeText || "Đóng"}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default memo(Modal);
