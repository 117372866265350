import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const ReportPortfolioLazy = lazy(() => import("./pages"));

export const reportPortfolioRoutes = [
  {
    page: ReportPortfolioLazy,
    path: paths.reportPortfolio,
    roleSide: roles.ROLE_USER,
  },
];
