import { Button, Grid } from "@mui/material";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { auth2faSelector } from "src/modules/Auth/redux/selector";
import { WrapOtp } from "./style";
import { auth2faStart, updateOTP2fa } from "src/modules/Auth/redux/auth2fa";
import { authStart } from "src/modules/Auth/redux/auth";

function ModalOtp() {
  const dispatch = useDispatch();
  const { otp } = useSelector(auth2faSelector);

  const handleOtp = (e) => {
    dispatch(updateOTP2fa(e));
    if (e.length === 6) {
      dispatch(authStart());
    }
  };

  const handleCodeOtp = () => {
    dispatch(auth2faStart());
    dispatch(updateOTP2fa(""));
  };

  return (
    <Grid justifyContent="center" alignItems="center" container spacing={2}>
      <WrapOtp>
        <OtpInput
          shouldAutoFocus
          value={otp}
          onChange={handleOtp}
          numInputs={6}
          separator={<span>-</span>}
        />

        <Button
          onClick={handleCodeOtp}
          size="small"
          variant="contained"
          color="warning"
          sx={{ marginTop: "10px", marginLeft: "160px" }}
        >
          Lấy lại Mã
        </Button>
      </WrapOtp>
    </Grid>
  );
}

export default ModalOtp;
