import { combineReducers } from "redux";
import authSlice from "./auth";
import formAuthSlice from "./formAuth";
import formRegisterSlice from "./formRegister";
import formForgetPassSlice from "./formForgetPass";
import registerSlice from "./register";
import resetPasswordSlice from "./resetPassword";
import auth2faSlice from "./auth2fa";

export default combineReducers({
  auth: authSlice,
  formAuth: formAuthSlice,
  formRegister: formRegisterSlice,
  formForgetPass: formForgetPassSlice,
  register: registerSlice,
  resetPassword: resetPasswordSlice,
  auth2fa: auth2faSlice,
});
