import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

interface InitialState {
  data: any[];
  status: ReduxStateType;
}

const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const orderTrading = createSlice({
  name: "orderTradingSlice",
  initialState,
  reducers: {
    orderTradingStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    orderTradingSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    orderTradingFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
  },
});

export const { orderTradingStart, orderTradingSuccess, orderTradingFailed } =
  orderTrading.actions;

export default orderTrading.reducer;
