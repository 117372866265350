import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PortfolioItem } from "src/domain/models/channelPortfolio";

export type PortfolioState = {
  portfolios: PortfolioItem[];
};

const initialState: ReduxData<PortfolioState> = {
  data: {
    portfolios: [],
  },
  status: ReduxStateType.INIT,
};

const portfolioSlice = createSlice({
  name: "channelPortfolioSlice",
  initialState,
  reducers: {
    getPortfoliosStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getPortfoliosSuccess: (
      state,
      action: PayloadAction<{
        items: PortfolioItem[];
      }>
    ) => {
      let { items } = action.payload;
      state.data.portfolios = items;
      state.status = ReduxStateType.SUCCESS;
    },
    callApiFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
  },
});

export const { getPortfoliosStart, getPortfoliosSuccess, callApiFailed } =
  portfolioSlice.actions;

export default portfolioSlice.reducer;
