import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { shareWithdrawApi } from "src/core/http/service/stockTrading";
import { ShareWithdrawParams } from "src/core/http/service/stockTrading/types";
import { ShareWithdrawRes } from "src/domain/models/stocktrading";
import { RootState } from "src/redux-store/store";
import {
  shareWithdrawFailed,
  shareWithdrawStart,
  shareWithdrawSuccess,
} from "../redux/shareWithdraw";

function* shareWithdrawStartWork() {
  const { accountCode, shareCode } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );
  const params: ShareWithdrawParams = {
    accountCode: accountCode?.value,
    shareCode: shareCode?.value,
    shareStatus: "02",
  };

  try {
    const res: ShareWithdrawRes = yield call(shareWithdrawApi, params);
    yield put({
      type: shareWithdrawSuccess.type,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: shareWithdrawFailed.type,
    });
  }
}

function* watchShareWithdraw() {
  yield takeLatest(shareWithdrawStart.type, shareWithdrawStartWork);
}

export default function* shareWithdrawSaga() {
  yield all([watchShareWithdraw()]);
}
