import { all } from "@redux-saga/core/effects";
import getDividendFilterSaga from "./getDividendFilter";
import postDividendCreateSaga from "./postDividendCreate";
import getDividendDeleteSaga from "./getDividendDelete";
import dividendUpdateSaga from "./dividendUpdate";
export default function* dividendSaga() {
  yield all([
    getDividendFilterSaga(),
    postDividendCreateSaga(),
    getDividendDeleteSaga(),
    dividendUpdateSaga(),
  ]);
}
