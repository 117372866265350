import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { getBuyInfoApi } from "src/core/http/service/stockTradingHand";
import { GetBuyInfoParams } from "src/core/http/service/stockTradingHand/types";
import { GetBuyInfoRes } from "src/domain/models/stockTradingHand";
import { RootState } from "src/redux-store/store";
import {
  getBuyInfoHandFailed,
  getBuyInfoHandStart,
  getBuyInfoHandSuccess,
} from "../redux/getBuyInfoHand";

function* getBuyInfoHandStartWork() {
  yield delay(400);
  const { accountCode, shareCode, price } = yield select(
    (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
  );

  const params: GetBuyInfoParams = {
    accountCode: accountCode?.value,
    shareCode: shareCode?.value ? shareCode?.value : "",
    sharePrice: price !== "ATC" || price !== "ATO" ? price : "",
    marketType: price === "ATC" || price === "ATO" ? price : "",
  };
  try {
    const res: GetBuyInfoRes = yield call(getBuyInfoApi, params);
    yield put({
      type: getBuyInfoHandSuccess.type,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: getBuyInfoHandFailed.type,
    });
  }
}

function* watchgetBuyInfoHand() {
  yield takeLatest(getBuyInfoHandStart.type, getBuyInfoHandStartWork);
}

export default function* getBuyInfoHandSaga() {
  yield all([watchgetBuyInfoHand()]);
}
