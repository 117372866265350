import userAssetsHttp from "src/core/http/singleton/userAssets";
import {
  ReportBuyingPowerParams,
  ReportBuyingPowerResponse,
  ShareHoldingParams,
  ShareHoldingResponse,
} from "src/domain/models/userAssets";
import { GET_REPORT_SHARE_HOLDING, GET_REPORT_BUYING_POWER } from "./urls";

export const getShareHoldingApi = async (
  params: ShareHoldingParams
): Promise<ShareHoldingResponse[]> => {
  const { ...restParams } = params;
  const res = await userAssetsHttp.get<any>(
    GET_REPORT_SHARE_HOLDING,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getReportBuyingPowergApi = async (
  params: ReportBuyingPowerParams
): Promise<ReportBuyingPowerResponse> => {
  const { ...restParams } = params;
  const res = await userAssetsHttp.get<any>(
    GET_REPORT_BUYING_POWER,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
