import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

export type InitialState = {
  data: any[];
  status: ReduxStateType;
};

const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const portfolioSlice = createSlice({
  name: "getReportAccountShareSlice",
  initialState,
  reducers: {
    getReportAccountShareStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getReportAccountShareSuccess: (state, action) => {
      state.data = action.payload;
      state.status = ReduxStateType.SUCCESS;
    },
    getReportAccountShareFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  getReportAccountShareStart,
  getReportAccountShareSuccess,
  getReportAccountShareFailed,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
