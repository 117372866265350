export const validateRepeatPass = (
  checkvalidate: boolean,
  param: string,
  isRepeatPass: string
) => {
  return checkvalidate
    ? param === ""
      ? true
      : isRepeatPass
      ? false
      : true
    : false;
};
export const validateRepeatPassText = (
  checkvalidate: boolean,
  param: string,
  isRepeatPass: string
) => {
  return checkvalidate
    ? param === ""
      ? "Vui lòng điền vào chỗ trống"
      : isRepeatPass
      ? ""
      : "Bạn đã nhập sai mật khẩu mới"
    : "";
};

//  Thông qua tất điều kiện ném giá trị cuối cùng === false
//  Có lỗi ném giá trị true
