import { createSlice } from "@reduxjs/toolkit";
import { ChannelListConnectRes } from "src/domain/models/managementConnect";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: ChannelListConnectRes[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const channelListConnect = createSlice({
  name: "channelListConnectSlice",
  initialState,
  reducers: {
    channelListConnectStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    channelListConnectSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    channelListConnectFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
  },
});

export const {
  channelListConnectStart,
  channelListConnectSuccess,
  channelListConnectFailed,
} = channelListConnect.actions;

export default channelListConnect.reducer;
