import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const IntroduceLazy = lazy(() => import("./pages"));

export const introduceRoutes = [
  {
    page: IntroduceLazy,
    path: paths.introduce,
    roleSide: roles.ROLE_USER,
  },
];
