import React from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from "@mui/material";
export interface ButtonProps extends MuiButtonProps {
  /**
   * Nếu có loading thì disable button và thêm icon quay tròn
   * Default: false
   * */
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  loading = false,
  startIcon,
  ...rest
}) => {
  return (
    <MuiButton
      {...rest}
      disabled={loading || disabled}
      startIcon={loading ? <CircularProgress size={10} /> : startIcon}
    >
      {" "}
      {children}
    </MuiButton>
  );
};
export default Button;
