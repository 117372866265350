import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

const initialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const listAuthorize = createSlice({
  name: "listAuthorizeSlice",
  initialState,
  reducers: {
    listAuthorizeStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    listAuthorizeSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    listAuthorizeFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { listAuthorizeStart, listAuthorizeSuccess, listAuthorizeFailed } =
  listAuthorize.actions;

export default listAuthorize.reducer;
