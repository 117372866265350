import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
import { FinanceServiceItem } from "src/domain/models/financeServices";
import { FilterFinanceServiceItem } from "src/core/http/service/financeService/types";
import { toast } from "react-toastify";

export type InitialState = {
  data: {
    financeServices: FinanceServiceItem[];
    filter: FilterFinanceServiceItem;
    hasMore: boolean;
  };
  status: ReduxStateType;
};

const initialState: InitialState = {
  data: {
    financeServices: [],
    filter: {
      shareCode: "",
    },
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const financeServiceListSlice = createSlice({
  name: "financeService",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<FilterFinanceServiceItem>) => {
      state.data.filter = {
        ...state.data.filter,
        ...action.payload,
      };
    },
    clearFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    getFinanceServicesStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getFinanceServicesFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      state.data.filter = initialState.data.filter;
      toast.error("Đã có lỗi khi hiển thị Danh Mục.");
    },
    getFinanceServicesSuccess: (
      state,
      action: PayloadAction<{
        financeSerive: FinanceServiceItem[];
      }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.financeServices = action.payload.financeSerive;
    },
  },
});

export const {
  updateFilter,
  clearFilter,
  getFinanceServicesStart,
  getFinanceServicesFailed,
  getFinanceServicesSuccess,
} = financeServiceListSlice.actions;

export default financeServiceListSlice.reducer;
