import AppLanguage from "./types";
import errorsEn from "./errorEn";

const en: AppLanguage = {
  common: {
    titleAlert: "NOTIFICATION",
    buttonClose: "CLOSE",
    login: "Login",
    errorlogin: "Invalid login information. Please try again.",
    username: "Username",
    password: "Password",
    enterPassword: "Enter password",
    signin: "Signin",
    darkMode: "Dark mode",
    lightMode: "Light mode",
    EN: "EN",
    VI: "VI",
    search: "Search",
    buy: "Buy",
    sell: "Sell",
    buyPlus: "BUY +",
    sellPlus: "SELL +",
    buyUp: "BUY",
    sellUp: "SELL",
    tickerTracking: "Enter symbol tracking",
    searchHeader: "Enter, company name",
    noValue: "No corresponding code",
    cancel: "Cancel",
    accept: "Accept",
    short: "Short",
    long: "Long",
    shortUp: "SHORT",
    longUp: "LONG",
    closeUp: "CLOSE",
    shortPlus: "SHORT +",
    longPlus: "LONG +",
    editUp: "EDIT",
    save: "Save",
    ok: "OK",
    OUTTIME: "Outtime",
    BOD: "BOD",
    AVAILABLE: "AVAILABLE",
    OPEN: "OPEN",
    RUNOFF: "Put-through",
    PAUSE: "PAUSE",
    CLOSED: "CLOSED",
    option: "Option",
    choose: "Choose",
    expiredToken: "Session has expired, please login again.",
    errorDelete: "Cannot cancel order",
    errorCancelCopier: "Cannot cancel order account copier",
    hiddenTab: "Hidden tab",
    close: "Close",
    maximizeTab: "Zoom in tab",
    restoreTab: "Zoom out tab",
    CP: "CP",
    unit: "B",
    million: "M",
    loading: "Loading...",
    inputSymbol: "Input symbol",
    warningRangeDay:
      "Exceed {day} days. You choose From date To date corresponding to {day} days in any time period.",
    warningInvalidDay: "From date must not exceed to date",
    nullInputFromDate: "Quý khách chưa nhập Từ ngày",
    nullInputToDate: "Quý khách chưa nhập Đến ngày",
    existTabError: "Function opened",
    warningLiquidAppendix: "Vui lòng chọn phụ lục",
    warningCreateAppendix: "Tài khoản không có quyền thực hiện chức năng này",
    extendTypeError: "Vui lòng chọn phương thức gia hạn",
  },
  system: {
    modalSupport: {
      title: "Support",
      content:
        "MBS would like to thank you for using our service. If you need any assistance please contact us here.",
      lableComment: "Your suggestions",
      errorComment: "Your suggestions cannot be left blank",
      errorEmail: "Email/mobile number cannot be left blank.",
      enterComment: "Enter suggestions",
      email: "Email/Mobile",
      enterEmail: "Enter email or mobile number",
      send: "Send",
      upPhoto: "Send photos",
      success: "Send successfully",
      contentSuccess:
        "Information has been sent to MBS. Thank you for contacting us.",
    },
  },
  auth: {
    errors: {
      emptyPassword: "Password cannot be left blank",
      emptyUsername: "Account cannot be left blank",
    },
    info: {
      title: "Account Information",
      customerNo: "Customer No",
      generalInformation: "General Information",
      identityCardId: "Identity Card Id",
      issueDate: "Issue Date",
      issuePlace: "Issue Place",
      birthDate: "Birth Date",
      sex: "Sex",
      communications: "Communications",
      currentAddress: "Current Address",
      phone: "Phone",
      email: "Email",
      contactName: "Contact Name",
      contactTel: "Contact Telephone",
      caringStaff: "Caring Staff",
      brokerName: "Broker Name",
      brokerBrand: "Broker Brand",
      brokerPhone: "Broker Phone",
      brokerEmail: "Broker Email",
      curentPass: "Curent password",
      newPass: "New password",
      confirmNewPass: "Confirm new password",
      editInfo: {
        title: "Edit Info",
        titleButton: "Edit Communications",
        cancel: "Cancel Change",
        update: "Confirm",
        inputAddress: "Input Address",
        inputPhone: "Input Phone",
        inputEmail: "Input Email",
        inputContactName: "Input Contact Name",
        inputContactTel: "Input Contact Phone",
        inputCurentPass: "Enter Curent Password",
        inputNewPass: "Enter new Password",
        inputConfirmNewPass: "Enter again new password",
        errorPhoneNumber: "Invalid phone number. Please try again!",
        errorEmail: "Invalid email. Please try again!",
        curentPassRequired: "Please enter your password",
        newPassRequired: "Please enter your password",
        confirmNewPassRequired: "Please enter your password",
        confirmNewPass: "Passwords don't match.",
        errorAddress:
          "The address is less than 15 characters. Please re-enter address!",
        errorChangeAll:
          "Email and phone number do not edit at the same time. Please try again!",
        changeAccountInfoSuccess: "Update Success",
        noChangeInfo: "Contact information unchanged!",
      },
      smartOTP: {
        cancel: "Cancel",
        smartOTP: "Enter SmartOTP",
        qrcode: "QR CODE",
        enterOTP: "Enter OTP",
        content: "Please open the MBS Mobile app and scan the code",
        expired: "QRcode expire",
        buttonGetQR: "Get a new code",
        errorInputOTP: "Didn't enter enough code, please enter all 6 numbers!",
        errorQR: "Scan the code failed, please try again",
      },
      sendOTP: {
        label: "Input code OTP",
        title: "Get OTP",
        cancel: "Cancel",
        confirm: "Confirm",
        sendOTP: "Send OTP",
        sendOTPAgain: "Send OTP Again",
        textConfirm: "I confirm registration to change contact information",
        textTitle: "Send OTP code via:",
        sendOTPAgainToastSuccess: "Resend verification code successfully",
      },
    },
    login: {
      callMBS: "Gọi MBS",
      numberMBS: "Hotline MBS: 1900 9088",
      username: "Username",
      enterUsername: "Enter username",
      rememberMe: "Remember me",
      forgetPassword: "Forget password?",
      forgetPasswordSuccess: "Forgot password successfully",
      userIncorect: "userIncorect",
      passIncorect: "passIncorect",
      signIn: "signIn",
      risk: "Risk",
      Hotline: "Hotline",
      Contact: "Contact",
      Tutorial: "Tutorial",
    },
    forgetPassword: {
      max6: "Max 6 characters",
      errorCMND: "wrong",
      CMND: "CMND/CCCD/GPKD/...",
      errorUser: "User wrong",
      forgetPassword: "forgetPassword",
      enter: "Enter",
      cancel: "Cancel",
      submit: "submit",
      forgetPasswordSuccess:
        "The password is sent to the SMS / Email customers registered with MBS.",
    },
    changePassword: "Change password",
    changePasswordSuccess: "Change password Success",
    setting: "Setting",
    logout: "Logout",
    notification: {
      comeinand: "Comeinand",
      money: "Money",
      advisory: "Advisory",
      cskh: "Service",
      maskAsRead: "Mask as read",
      warning: "Warning",
    },
    tradingAccount: {
      titleTypeOtp: "Authentication",
      typeOtp: "Authentication type",
      title: "Trading Account",
      defaultAccount: "Default Account",
      accountName: "Account Name",
      accountStock: "Account Stock",
      accountCash: "Account Cash",
      accountMCredit: "Account M-Credit",
      accountMargin: "Account Margin",
      accountDerivative: "Account Derivative",
      accountBeneficiary: "Account Beneficiary",
      addAccountBeneficiary: "Add Account Beneficiary",
      success: "Add account success",
      masterAccountName: "Master Account Name",
      bankAccountCode: "Bank Acc Code",
      bankAccountCodeInput: "Bank Acc Code Input",
      errorBankAccount: "Acc cannot be left blank",
      errorBankBranch: "Branch cannot be left blank",
      bankName: "Bank Name",
      bankNameInput: "Bank Name Input",
      bankProvince: "Bank Province",
      bankProvinceInput: "Bank Province Input",
      bankBranch: "Bank Branch",
      bankBranchInput: "Bank Branch Input",
      cancel: "Cancel",
      addAccount: "Add Acc",
      typeAccount: "Type Acc",
      deleteAccount: "Delete Acc",
      confirmDelete: "Confirm delete account beneficiary",
      accept: "Accept",
      deleteSuccess: "Delete Account Success",
      table: {
        tooltipTable: "{bankName}",
      },
    },
    smsEmail: {
      title: "Email/SMS",
      sms: "SMS",
      email: "Email",
      free: "FREE",
      basic: "BASIC (8,800 VND)",
      pro: "ADVANCED(33,000 VND)",
      settingEmail: "SETTING A EMAIL",
      basicRegister: "You want to register Basic package?",
      advancedToBasic:
        "You want to go from  advanced package to basic package ?",
      advancedRegister: "You want to sign up package advanced??",
      basicToAdvanced:
        "You want to go from basic package to advanced package ?",
      canelBasic: "You want to cancel the Basic plan?",
      canelAdvanced: "You want to cancel the Advanced plan?",
      modalTitle: "SMS/Email setting",
    },
    onlineService: {
      modalTitle: "Register online services",
      titleCancelMargin: " Canceling margin registration",
      marginContr: "Registration limit",
      cancelMarginConfirm:
        "I agree to the terms and conditions of service use of MBS from time to time",
      buttonCancel: "Unregister",
      titleCancelSMUT: "Cancel",
      SMUTCancelConfirm:
        "I have read and agree to the terms of MBS when canceling SMUT",
      accountNumber: "Account number",
      fullName: "Full name",
      titleBroker: "Register broker service",
      lableBroker: "Recommend an account manager and broker",
      brokerConfirm:
        "I have read and agree to the conversion rights account management services",
      buttonYes: "Registered",
      brokerRequest:
        "CHANGE ACCOUNT MANAGEMENT SERVICES: FROM MBS ONLINE TO BROKER SERVICE",
      enterCode: "Enter broker",
      titleMargin: "Register Margin service",
      maxMarginValue: "Maximum value registered ",
      minMarginValue: "Minimum value registered ",
      stepMarginValue: "step value margin ",
      marginValue: "Limit register",
      marginConfirm:
        "I agree to the terms and conditions of service use of MBS from time to time",
      titleMbsOnline: "Register MBS Online service (No Broker)",
      mbsOnline: "MBS Online",
      authenticationMethod: "Authentication method",
      matrix: "Matrix",
      smartOTP: "SmartOTP",
      titleSmartOTP: "Change authentication method Smart OTP",
      smartOTPRequest: "CHANGE METHOD FROM MATRIX TO SMART OTP",
      smartOTPConfirm:
        "I have read and agree to the terms of MBS when changing the authentication method",
      mbsOnlineRequest:
        "CHANGE ACCOUNT MANAGEMENT SERVICES: FROM BROKER SERVICE TO MBS ONLINE",
      mbsOnlineConfirm:
        "I have read and agree to the conversion rights account management services",
      titleSMUT: "Register SMUT service",
      SMUTConfirm:
        "I have read and agreed with register purchase_power service",
      accountService: "Account service",
      marginService: "Margin service",
      registered: "registered",
      unregistered: "Unregistered",
      MCreditService: "M-Credit service ",
      CKService: "CK service",
      registerETrading: "Register ETrading",
      callTrading: "Call trading",
      onlineTrading: "Online trading",
      accountManagementService: "Acc management service",
      brokerService: "Broker service",
      SMUTService: "SMUT service",
      DateRequest: "REQUEST DATE",
      content: "CONTENT",
      status: "STATUS",
      fromDate: "From date",
      toDate: "To Date",
      all: "All",
      CHUA_DUYET: "Pending",
      DA_DUYET: "Approved",
      statusFilter: "Status",
      errorDayRange: "The start date can not be greater than the end date",
      MBSOnlineService: "MBS Online service",
      registerMBSOnline: "Register MBS Online",
      history: "History",
      sendOTP: "Send OTP:",
      numberPhone: "Number phone",
      enterOTP: "Enter OTP",
      enterSmsOTP: "Enter SMS OTP",
      send: "Send code again",
    },
    chooseTheme: {
      editTheme: "Change theme",
      chooseTheme: "Choose theme",
      blackTheme: "Black theme",
      darkTheme: "Dark blue theme",
      lightTheme: "Light theme",
      lightBlueTheme: "Light and Blue theme",
      default: "(default)",
    },
    keyboardShortcuts: {
      title: "Setting keyboard",
      noKey: "Empty",
      duplicateKey: "Error Duplicate Key",
      keyShortcutOpenForm: {
        title: "Key Open Form",
        category: "Category",
        market: "Market",
        stockOrder: "Stock Order",
        stockOrderDer: "Stock Order Der",
      },
      transactionSettings: {
        title: "Transaction Settings",
        stockOrderBuy: "Stock order buy",
        stockOrderSell: "Stock order sell",
        popupConfirmOrder: "Popup confirm order",
      },
    },
  },
  category: {
    table: {
      noContent: "",
      profitPerLoss: "+/-",
      changePercentage: "%",
      ticker: "Symbol",
      ceiling: "CE",
      floor: "FL",
      referenceShort: "TC",
      reference: "REF",
      overBought: "Over bought",
      overSold: "Over sold",
      filled: "Order matching",
      price: "Price",
      amount: "Volume",
      high: "High",
      low: "Low",
      average: "Average",
      foreign: "Foreign",
      overBoughtAmount3: "(Over bought) Volume 3 ",
      amount3: "Vol 3",
      overBoughtPrice3: "(Over bough) Price 3",
      price3: "Price 3",
      overBoughtAmount2: "(Over bought) Volume 2",
      amount2: "Vol 2",
      overBoughtPrice2: "(Over bought) Price 2",
      price2: "Price 2",
      overBoughtAmount1: "(Over bought) Volume 1",
      amount1: " Vol 1",
      overBoughtPrice1: "(Over bought) Price 1",
      price1: "Price 1",
      filledPrice: "(Order matching) PRICE",
      lastSale: "LAST_SALE",
      filledDiff: "(Order matching) +/-",
      filledAmount: "(Order matching) Volume",
      overSoldPrice1: "(Over bought) Price 1",
      overSoldAmount1: "(Over bought) Volume 1",
      overSoldPrice2: "(Over bought) Price 2",
      overSoldAmount2: "(Over bought) Volume 2",
      overSoldPrice3: "(Over bought) Price 3",
      overSoldAmount3: "(Over bought) Volume 3",
      sumAmount: "Total volume ",
      foreignBought: "(ĐTNN) Buy",
      buy: "Buy",
      foreignSold: "(ĐTNN) Sell",
      sold: "Sell",
      date_dh: "Date ĐH",
      diff: "Differrence",
      oi: "OI",
      tcph: "TCPH",
      gdcc: "GDCC",
      ckcs: "UDS",
      price_ckcs: "Price",
      th: "TH",
      ratio: "Radio",
      diff_cd: "Diff CD",
      break_even_point: "Break even point",
      volume: "volume",
      stock: "Stock",
      time: "Time",
      transactionValue: "Transaction value",
      accumulatedValue: "Accumulated value",
      accumulatedVolume: "Accumulated volume",
      bid: "Bid",
      ask: "Ask",
    },
    modal: {
      title: "Edit column",
      editColumn: "Edit Column",
      selectColumn: "Select column",
      reset: "Reset",
      cancel: "Cancel",
      apply: "Apply",
    },
    custom: {
      title: "Your category",
      confirm: "Confirm",
      nameCategory: "Enter name category",
      addCategory: "Add category",
      cancel: "Cancel",
      favorite: "Favorites category",
      addToFavoriteListSuccess: "Add to favorite list success",
      addToFavoriteListFailure: "Add to favorite list failure",
    },
    tabs: {
      owner: "Owner category",
      listed: "List",
      putThrough: "Put through",
      derivative: "Derivative",
      coverWarrant: "Cover warrant",
      industry: "Industry",
    },
    derivative: {
      vn30: "VN30",
      tpcp: "HĐTL TPCP",
    },
    coverWarrant: {
      mbs: "Cover warrant MBS",
      full: "Full market warrants",
    },
    putThrough: {
      hsx: "HSX",
      hnx: "HNX",
      upcom: "UPCOM",
      totalSharesTradedPT: "TOTAL VOLUME TRANSACTION AGREEMENT",
      totalValuesTradedPT: "TOTAL VALUE OF TRANSACTION AGREEMENT",
      billionVnd: "Billion VND",
      dataEmpty: "Data empty",
    },
    errors: {
      duplicatedName: "Duplicated name",
      required: "Enter a list name",
      sameOwnerName: "Duplicated owner name",
    },
  },
  market: {
    dragTooltip: "Add to display",
    viewDoesntExist: "View doesnt exist",
    newScreen: "Market Analysis (empty)",
  },
  widgets: {
    orderBookCondition: {
      name: "Order List",
    },
    orderBasket: {
      name: "Order basket",
    },
    orderBasketDer: {
      name: "Order basket der",
    },
    moneyblock: {
      name: "Money block",
    },
    coverWarrantInfo: {
      name: "CW Information",
      cwIssuerName: "CW issuer name",
      cwType: "CW type",
      cwExercisePrice: "Exercise price",
      cwExerciseRatio: "Exercise ratio",
      cwRemainLastTradingDate: "Remain last trading date",
      CKCSCode: "UDS ",
      CKCSPrice: "Price",
      status: "Status",
      cwLastTradingDate: "Last trading date",
      cwMaturityDate: "Maturity date",
    },
    tickerGraph: {
      name: "Technical Analystic",
    },
    watchList: {
      name: "Watch List",
    },
    accountInfo: {
      name: "Account Info",
    },
    financialService: {
      name: "Financial Service",
    },
    stockOrder: {
      name: "Stock Order",
    },
    priceList: {
      name: "Price list",
    },
    TickerInfo: {
      name: "TickerInfo",
      errors: {
        noWatchlistToAdd: "No watchlist to add",
      },
    },
    orderBookOfTheDay: {
      name: "Order list of the day",
    },
    finance: {
      year: "Year",
      quarter: "Quarter",
      viewType: "View type",
      mainIndicator: {
        label: "Label",
        type: {
          roe: "ROE",
          roa: "ROA",
          eps: "EPS",
          yoy: "YOY",
        },
      },
      reportRevenue: {
        label: "Income Statement",
        type: {
          netincome: "Net Income",
          netrevenue: "Total Revenue",
          operatingincome: "Operating Income",
          yoy: "YOY",
        },
      },
      cashFlow: {
        label: "Cash Flow",
        type: {
          cashflowoperating: "Operating",
          cashflowinvesting: "Investing",
          cashflowfinance: "Financing",
          yoy: "YOY",
        },
      },
      balanceSheet: {
        label: "Balance Sheet",
        totalAssets: "Total Assets",
        totalLiabilities: "Total Liabilities",
        solvencyDebtAssets: "Debt to Asset",
      },
    },
    Card: {
      agree: "Agree",
    },
    foreignWatch: {
      name: "Foreigners",
      description:
        "The green color on the chart shows foreign net buying. Red shows foreign net selling. Data includes Stocks and ETFs. The unit value of million VND.",
      floor: "FL",
      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      buyValue: "Buy Value",
      sellValue: "Sell Value",
      netValue: "Net Value",
      totalBuyValue: "Total buy value",
      totalSellValue: "Total sell Value",
      totalNetValue: "Total net value",
    },
    marketWatch: {
      name: "Market movement",
      topIndex: "TOP INDEX",
      marketRange: "Market range",
      liquidity: "Liquidity",
      transactionValueVolume: "Transaction value volume",
      capitalization: "MARKET CAP",
      floor: "FL",
      valueType: "Type",
      value: "Value",
      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      transactionTypes: {
        value: "Matched transactions",
        valueAll: "Matched transactions and agreements",
      },
      valueTypes: {
        price: "Transaction value",
        volume: "Trading volume",
      },
    },
    marketStats: {
      yAxesLabel: "Contribute to INDEX (%)",
      xAxesLabel: "Amount trading",
      changedPercentVolumeWithAvg5d: "Amount trading:",
      contributionPercentage: "Contribute to INDEX (%):",
      contributionValue: "Contribute to INDEX (Point):",
      marketPercent: "% Market cap:",
    },
    liquidity: {
      name: "Liquidity",
      description:
        "Chart comparing the cash flow at the same time of the previous session, an average of 1 week, 2 weeks and 1 month average.",
      present: "Present",
      lastSession: "Last Session",
      oneWeek: "One week",
      twoWeek: "Two week",
      oneMonth: "One month",
    },
    topIndex: {
      percent: "Contribute to INDEX (%)",
      value: "Contribute to INDEX (Point)",
      increaseContribute: "Increase contribute",
      decreaseContribute: "Decrease contribute",
      point: "Point",
      description:
        "The graph shows the influence of information points and the impact of a 30% share to increase impact of the index declined, 15 stocks rose and 15 pm influence affects dimensional reduction codes.",
    },
    capitalization: {
      description:
        "Chart shows the allocation of all-cap stocks on the floor. Unit is million VND.",
    },
    transactionValueVolume: {
      description:
        "The graph shows the value / volume of exchanges are focused on the code. Transaction value unit is million VND.",
    },
    industryDescription: {
      topIndex: {
        description:
          "Biểu đồ thể hiện đóng góp của ngành vào INDEX. Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
      transactionIndustry: {
        description:
          "Biểu đồ thể hiện giá trị giao dịch ngành (đơn vị Triệu VND). Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
    },
    marketRange: {
      description:
        "The horizontal axis shows the ratio of volume to 5-day average of volume. The vertical axis shows the contribution of each token to the return of the index at the current session.",
    },
    topPrice: {
      left: "Top increases",
      right: "Top decreased",
      headers: {
        today: "To day",
        oneWeek: "One week",
        oneMonth: "One month",
        threeMonths: "Three months",
        sixMonths: "Six months",
        oneYear: "One year",
      },
      table: {
        stockId: "Company name",
        price: "Price match",
        changedPrice: "Change",
        changedPricePercentage: "%",
      },
    },
    coverWarrant: {
      title: "Cover Warrant",
      table: {
        stockId: "Symbol",
        price: "Price match",
        changedPrice: "+/-",
        changedPricePercentage: "%",
        volume: "Volume",
      },
      headers: {
        allIssuer: "All Issuer",
        allUnderlying: "All Underlying",
      },
    },
    industry: {
      title: "Industry",
      select: "Choose industry",
      table: {
        ticker: "Symbol",
        price: "Match price",
        totalAmount: "Total volume",
      },
    },
    topStatistical: {
      name: "Top trading stocks",
      headers: {
        topVolume: "Volume",
        topValue: "Value",
        topChange: "% Change",
      },
      table: {
        stockId: "Symbol",
        price: "Last price",
        changedPrice: "Change",
        changedPricePercentage: "%",
        topValue: "Value(Bill)",
        topValueMass: "Vol(Mill)",
      },
    },
    topForeign: {
      name: "Top foreign trading",
      headers: {
        foreignNetValuesBuy: "Buy Net",
        foreignNetValuesSell: "Sell Net",
        foreignBuy: "F buy volume",
        foreignSell: "F sell volume",
        foreignBuyValue: "F buy value",
        foreignSellValue: "F sell value",
      },
      table: {
        stockId: "Company name",
        price: "Match",
        changedPrice: "Change",
        changedPricePercentage: "%",
        topValue: "Value(Bill)",
        topValueMass: "Value(Thousand)",
      },
    },
    exchangeTradedFund: {
      name: "ETF",
      priceMatch: "Price match",
      codeTicker: "Symbol",
      volume: "Total volume",
    },
    recommendation: {
      name: "Stock recommendations",
      code: "Code",
      side: "KN",
      recommendedPrice: "Rec.Price",
      lastSale: "Market Price",
      expectedPrice: "Target Price",
      positive: "Positive",
      watching: "tracking",
      buy: "Buy",
      sell: "Sell",
    },
    adIndex: {
      name: "Advances & Declines Distribution",
      title: "Index A-D",
      sum: "Total: {number}",
      advance: "Increase(Advancers): {number}",
      decline: "Decrease (Decliners): {number}",
    },
    vn30Index: {
      title: " Index VN30",
    },
    organization: {
      title: "Business Profile",
      domesticAndForeign: "Domestic and Foreign",
      domesticStackHolder: "Domestic stack holder",
      foreignStackHolder: "Foreign stack holder",
    },
    netflow: {
      name: "Netflow",
      positive: "Net Flow > 0: Positive signal",
      negative: "Net Flow < 0: Negative signal",
      headers: {
        present: "T",
        oneDayAgo: "T-1",
        twoDayAgo: "T-2",
        threeDayAgo: "T-3",
        fourDayAgo: "T-4",
      },
      noteText:
        "It is an indicator that measures the value of active buy orders - the value of active sell orders of all stocks in VN30, to determine whether the cash flow is actively buying or selling.",
    },
    orderFlowDistribution: {
      title: "Order Flow Distribution (Unit: Billion VND)",
      small: "Small",
      medium: "Medium",
      large: "Larger",
      totalBuy: "Inflow",
      totalSell: "Outflow",
      noteTitle:
        "GIÁ TRỊ MUA/BÁN ĐƯỢC THỐNG KÊ THEO CHI TIẾT LỆNH KHỚP LÀ MUA CHỦ ĐỘNG/BÁN CHỦ ĐỘNG VỚI CÁC MỨC GIÁ KHỚP LỆNH (GTKL) TƯƠNG ỨNG:",
      noteText1: "- Lớn: 10% GTKL lớn nhất",
      noteText2: "- Nhỏ: 10% GTKL bé nhất",
      noteText3: "- Trung bình: 80% GTKL còn lại",
    },
    infoQuote: {
      title: "Step value",
      volume: "Volume",
    },
    volumeAndTime: {
      title: "Volume and Time",
      matchHistory: "Match history",
      volumeAnalysis: "Volume Analysis",
      esideShorthand: {
        buy: "B",
        sell: "S",
      },
    },
    tickerInfo: {
      fillCode: "Enter symbol, company name ...",
      ceilingPrice: "CE",
      floorPrice: "FL",
      referencePrice: "TC",
      openAveragePrice: "OPEN/AVG",
      lowesthighestPrice: "DAY’S RANGE",
      totalSellQty: "F.SELL VOLUME",
      totalBuyQty: "F.BUY VOLUME",
      totalSharesTradeMt: "Total volume",
      marketCap: "MARKET CAP",
      sharesOutstanding: "SHARE OUTSTANDING",
      priceToEarningRatio: "P/E (TTM)",
      tangibleBookValuePerShare: "VALUE/BOOK VALUE",
      returnOnEquity: "ROE",
      highestPrice52Week: "52 WEEK RANGE",
      turnoverRate: "% TURNOVER",
      priceToBook: "P/B",
      dividend: "DIVIDEND",
      range: "% RANGE",
      earningPerShare: "EPS (TTM)",
      priceToSalesRatio: "P/S",
      dividendYield: "DIV YIELD",
      currentRoom: "Room NN",
      DILastTradingDate: "Expired date",
      bias: "BASIC",
      OI: "OI",
    },
    placeOrder: {
      conditionalOrder: {
        title: "Conditional order",
        normal: "Normal",
        timeConditionOrder: "Before the date",
        trailingStopOrder: "Trend",
        priorityOrder: "Painting Buy / Sell",
        takeProfit: "Take profit",
        cutLoss: "Cut loss",
        generatedOneTime: "Generated One Time",
        matchingEnoughVolume: "Matching enough volume",
        byPercent: "By percent",
        byValue: "By value",
        conditionsList: {
          noCondition: "No condition",
          referencePrices: "Reference Prices",
          matchedPrice: "Matched Price",
        },
        priceTC: "Prices Reference",
        errorPlaceSellOrderSPCL: "Take profit/ loss don't apply order buy",
        errorPriceTCOLO: "Before day order only support LO price",
      },
      errorCheckFAL: "Please click confirm before trading.",
      inputTicker: "Input Ticker",
      errorTickerEmpty: "Please enter stock code or company name",
      errorTickerIllegal: "Inval symbol",
      account: "Account",
      tradetype: "Type order",
      method: "Method",
      condition: "condition",
      toDate: "To date",
      buyTitle: "COMFIRM BUY",
      sellTitle: "COMFIRM SELL",
      accountNumber: "Account number",
      commandType: "Type order",
      stockCode: "Stock code",
      sellVolumn: "Sell volumn",
      buyVolumn: "Buy volumn",
      sellPrice: "Sell price",
      buyPrice: "Buy price",
      totalLabel: "Order Value (VND)",
      noVATLabel: "(Excluding fees and taxes)",
      confirmFAL: "You are replacing a FAL order. Please confirm this order!",
      visibleModalConfirm:
        "Không hiển thị lại vào lần sau. Bạn có thể bật lại chức năng này ở Cài đặt giao dịch",
      comfirmBuy: "Comfirm buy",
      comfirmSell: "Comfirm sell",
      slidingMargin: "Sliding margin",
      value: "Value",
      percent: "%",
      stopInterval: "Stop range",
      minBuyPrice: "Min buy price",
      maxSellPrice: "Max sell price",
      avgPrice: "Avg Price",
      arbitrage: "Diff price",
      cancel: "Cancel",
      buyFal: "Buy fal",
      dailyCredit: "Daily Credit",
      maxBuy: "Max buy",
      maxSell: "Max sell",
      maxVolume: {
        tooltip: "Click to place order",
      },
      marginRate: "Margin rate",
      purchasingAbility: "Buy power",
      buy_command: "PLACE ORDER",
      sell_command: "PLACE ORDER",
      volumn: "VOLUME",
      ceilingPrice: "CE",
      floorPrice: "FL",
      referencePrice: "REF",
      orderSuccess: "Order success",
      orderError: "An error occurred. Please check again",
      errorAccountDer: "Dữ liệu không hợp lệ, vui lòng thử lại",
      volume: "Volume",
      price: "Price",
      normal: "Normal",
      tabNameTooltip: "Kích để đặt lệnh",
    },
    orderBookDetail: {
      title: "Order details",
      status: "Status",
      tradeType: "Transfer Type",
      symbol: "Symbol",
      tradingDate: "Trading date",
      orderQty: "Order qty",
      price: "Price set",
      cumQty: "Matching volume",
      matchAvgPrice: "Match avg price",
      channel: "channel",
      rejectReason: "Reject reason",
      table: {
        no: "ID",
        cumQty: "MATCHING VOLUME",
        matchAvgPrice: "MATCH AVG PRICE",
        time: "TIME",
      },
      noData: "No data",
    },
    orderBookEdit: {
      setPrice: "Set price",
      setVolumn: "Set volumn",
      cancel: "Cancel",
      confirm: "Confirm",
      BUY: "Edit buy order",
      BUY_OUTR: "Edit buy order OutR",
      SELL: "Edit sell orde",
      SELL_OutR: "Edit buy order OutR",
      SELL_TT68: "Edit buy order TT68",
      orderSuccess: "Order Success",
      orderError: "An error occurred. Please check again",
      errorVolumnUp: "Do not edit the volume up.",
      errorVolumnDown: "Do not edit the volume down.",
      errorChangeBoth: "Do not edit price and volume at the same time.",
    },
    placeOrderDer: {
      name: "Derivative",
      conditionalOrder: "Conditional order",
      EE: "EE",
      net: "Net",
      maxLong: "max Long",
      maxShort: "Max Short",
      comfirmShortTitle: "Comfirm Short tittle",
      comfirmLongTitle: "Comfirm Long tittle",
      tickerCode: "Symbol",
      volume: "Volume",
      price: "Price",
      acceptLong: "LONG",
      acceptShort: "SHORT",
      inputTicker: "Enter symbol",
      A: "---",
      UP: "UP",
      DOWN: "DOWN",
      TUP: "TUP",
      TDOWN: "TDOWN",
      BULLBEAR: "BULL & BEAR",
      OCO: "OCO",
      activePrice: "Active price",
      stoplot: "Price cut loss",
      takeprofit: "Take profit price",
      margin: "slip margin",
      normal: "Normal",
      orderSuccess: "Order Success",
      orderError: "Order Error",
      errorAccountEquity: "Dữ liệu không hợp lệ, vui lòng thử lại",
    },
    portfolio: {
      name: "Portfolio",
      userName: "USER",
      code: "CODE",
      type: "TYPE",
      weight: "AVAILABE",
      wait: "wait",
      rightShares: "WAIT RIGHT SHARE",
      avgPrice: "AVG PRICE(VNĐ)",
      marketPrice: "MARKET PRICE",
      grossValue: "INFLOW",
      marketValue: "MARKET PRICE",
      marketValueRatio: "RATIO (%)",
      profit: "PL",
      profitRatio: "% PL/",
      sum: "TOTAL",
      sharesBalance: "TOTAL",
      sellAll: {
        title: "Bán hết MP",
        titleConfirm: "Xác nhận đặt lệnh",
        percent: "Chọn tỉ lệ danh mục muốn bán",
        table: {
          userName: "Tài khoản",
          code: "Mã CK",
          type: "Loại",
          sharesBalance: "KL khả dụng",
          weight: "KL bán",
          marketPrice: "Giá hiện tại",
          exchanges: "Sàn",
          sellQty: "KL đặt",
          price: "Giá đặt",
        },
        cancel: "Hủy",
        confirm: "Xác nhận",
        placeOrder: "Đặt lệnh",
        emptySelect: "Không có lệnh nào phù hợp được chọn",
        orderSuccess: "Đặt lệnh thành công {count}/ {total} lệnh",
      },
    },
    orderBookDerEdit: {
      titleLong: "Edit Long order",
      titleShort: "Edit Short order",
      setPrice: "Set price",
      setVolume: "Set volume",
      profit: "Take profit price",
      conditionPrice: "Condition price",
      stopLoss: "Cut loss price",
      margin: "Silp margin",
      cancel: "Cancel",
      orderSuccess: "Order Success",
      orderError: "An error occurred. Please check again",
      confirmLong: "Confirm Long",
      confirmShort: " Confirm Short",
    },
    openPosition: {
      name: "Position",
      seriesId: "Contract code",
      net: "Net",
      askAvgPrice: "Ask avg price",
      marketPrice: "Market price",
      floatingPL: "PL",
      summary: "Total",
      valuePositionSummary: "Value of open position",
    },
    closePosition: {
      name: "Close position",
      seriesId: "Contract code",
      long: "Long",
      net: "Net",
      askAvgPrice: "Avg sell",
      bidAvgPrice: "Avg buy",
      tradingPL: "PL",
      summary: "Total",
    },
    watchlist: {
      selectCategory: "Select watchlist",
      ticker: "Symbol",
      sumVolume: "	TOTAL VOLUME",
      percent: "%",
    },
    maximunTabError: "Exceeded the maximum number of boards",
  },
  tickerDetail: {
    title: "Stock details",
    tabs: {
      finance: "Financing",
      orgInfo: "Business information",
      news: "Newws",
      listPrice: "Step price",
      volumeAndTime: "Volume and time",
      distribution: "Order flow distribution",
    },
  },
  extends: {
    statement: {
      orderError: "An error occurred. Please check again",
      error90Day: "Exceeded the 90-day search",
      error30Day: "Exceeded the 30-day search",
      error7Day: "Exceeded the 7-day search",
      errorDay: "The start date can not be greater than the end date",
      type: "Type",
      all: "All",
      cashStatement: "Cash Statement",
      stockStatement: "Stock Statement",
      derStatement: "Derivative statement",
      stt: "ID",
      userName: "UserName",
      tradeDate: "TRADE DATE",
      cashUp: "CASH UP",
      cashDown: "CASH DOWN",
      balance: "BALANCE",
      content: "CONTENT",
      beginBalance: "BEGIN BALANCE",
      sum: "TOTAL",
      closeBalance: "CLOSE BALANCE",
      ticker: "SYMBOL",
      exchange: "BOARD",
      account: "Account",
      shareBegin: "Begin balance",
      shareChange: "Total",
      shareEnd: "Close balance",
      shareCode: "SYMBOL",
      shareStatus: "TYPE",

      derStatementTable: {
        rowNo: "ID",
        date: "TRADE DATE",
        transactionNo: "NUMBER OF VOUCHERS",
        balanceMBS: "BALANCE MBS",
        clientDebit: "CASH DOWN",
        clientCredit: "CASH UP",
        balanceVSD: "BALANCE VSD",
        brokerDebit: "CASH DOWN",
        brokerCredit: "CASH DOWN",
        totalBalance: "BALANCE",
        description: "CONTENT",

        summary: "TOTAL",
        beginBalance: "BEGIN BALANCE",
        endBalance: "CLOSE BALANCE",
      },
    },
    profitLossEquity: {
      title: "Profit Loss Equity",
      header: {
        account: "Account",
        fromDate: "From Date",
        toDate: "To Date",
        symbol: "Symbol",
        search: "Search",
        inputSearch: "Input Share code Search",
        allAccount: "All Account",
        errorDayRange: "Exceeded 30-day search limit",
        errorDayRange2: "From date not greater than To date",
      },
      tableContent: {
        tradingDate: "Trading Date",
        accountNo: "Account No",
        symbol: "Symbol",
        status: {
          title: "Status",
          normal: "Normal",
          outR: "OutR",
          tt68: "TT68",
        },
        content: "Content",
        volume: "Volume",
        matchedPrice: "Matched Price",
        fee: "Fee",
        taxToPay: "Tax/ Other Fee",
        avgPrice: "Avg Price",
        gainloss: "Gain Loss",
        percent: "Percent",
        totalProfit: "Total Profit",
        loadmore: "Load more items",
        loadend: "You have seen it all",
        nothing: "Nothing Item",
        buy: "BUY",
        sell: "SELL",
      },
    },
    profitLossDer: {
      title: "Profit Loss Der",
      header: {
        account: "Account",
        fromDate: "From Date",
        toDate: "To Date",
        symbol: "Symbol",
        search: "Search",
        inputSearch: "Input Search",
        allAccount: "All Account",
        errorDayRange: "Exceeded 30-day search limit",
      },
      tableContent: {
        transDate: "Trans Date",
        symbol: "Symbol",
        netOff: "Net Off",
        expired: "Expired",
        open: "Open",
        totalNetOff: "Total Net Off",
        avgBuyPrice: "Avg Buy Price",
        avgSellPrice: "Avg Sell Price",
        closePrice: "Close Price",
        profit: "Profit",
        totalProfit: "Total Profit",
        buy: "Buy",
        sell: "Sell",
        long: "Long",
        short: "Short",
      },
    },
    confirmationOrderOnline: {
      success: "Registration successful",
      title: "Confirmation Order Online",
      modalMessage: {
        title: "Notification",
        titleConfirm:
          "I agree and confirm the transaction in order to be true according to my request.",
        textBody:
          "Customers here agree with the information in this Form and confirm ....  . . . .   .  .  .  .  . . . . . . . . . . . . . .",
        content:
          "Please register for Order Confirmation utility via GDDT system at MBS.",
      },
      modalConfirmExit: {
        title: "Confirm Exit",
        content: "Are you sure you want to cancel this activity?",
      },
      modalConfirmCondition: {
        title: "Sign up for Online Order Confirmation service",
        account: "Account",
        textCondition1: "I agree with",
        textCondition2: "Terms and Conditions",
        textCondition3:
          "Order confirmation via electronic transaction system at MBS.",
        messageConfirmOrderSuccess: "Confirm order success",
        messageVerifyOTPSuccess: "Verify OTP Success",
        messageVerifyOTPFailure: "Verify OTP Failure",
        sendAgain: "Send Again",
      },
      header: {
        account: "Account",
        fromDate: "From Date",
        toDate: "To Date",
        search: "Search",
        allAccount: "All Acc",
        confirmType: "Confirm Type",
        errorDayRange: "Error Day Range",
        errorDay: "The start date can not be greater than the end date",
      },
      tableContent: {
        orderDate: "Order Date",
        accountCode: "Account Code",
        side: "Side",
        shareCode: "Share Code",
        orderVolume: "Order Volume",
        showPrice: "Show Price",
        orderStatus: "Order Status",
        orderNo: "Order No",
        channels: "Channels",
        confirmType: "Confirm Type",
        confirmDate: "Confirm Date",
        confirmOrder: "Confirm Order",
        buy: "Buy",
        sell: "Sell",
        unconfimred: "Unconfirmed",
        signed: "Signed",
        confirmed: "Confirmed",
        selectAll: "Select All",
        cancelWaiting: "Cancel Waiting",
        fixWaitingFixed: "Fix Waiting/Fixed",
        matchWaiting: "Match Waiting",
        cancelled: "Cancel",
        matched: "Matched",
        partialMatch: "Partial Match",
        refuse: "Refuse",
      },
    },
    categoryFinancialService: {
      title: "Stock Portfolio",
      header: {
        serviceType: "Service Type",
        serviceTypeAll: "All",
        exchanges: "Exchanges",
        exchangesSelectAll: "All",
        shareCode: "Share Code",
        shareCodeSelectAll: "All",
        search: "Search",
      },
      tableContent: {
        numericalOrder: "ID",
        exchanges: "Exchanges",
        shareCode: "Share Code",
        shareName: "Share Name",
        marginMin: "Margin Min",
        marginMax: "Margin Max",
        marginReal: "Margin Real",
        textFooter:
          "The actual ratio of MBS lending/cooperation is being calculated on the reference price, the exact disbursement rate will depend on the purchase price of the customer.",
      },
    },
    oddLotShare: {
      tabHeader: {
        available: "Odd lot selling",
        history: "Odd lot selling hist",
      },
      tabPanel: {
        sellOddLotShare: {
          filterActions: {
            search: "Search",
            accountNumberLabel: "Account number",
            shareCodeLabel: "Symbol",
            selectAll: "All",
          },
          table: {
            header: {
              account: "ACCOUNT",
              shareCode: "SYMBOL",
              oddShare: "ODD SYMBOL",
              sellingPrice: "SELL PRINCE",
              sellValue: "SELL VALUE",
              exchange: "BOARD",
              stateShare: "STATUS",
            },
            footer:
              "Odd lot transactions for exchanges, service charges 10% of total transaction value date 23/07/2012",
            sellButton: "Sell",
            transactionSuccessfulMsg: "Transaction successful",
          },
        },
        sellOddHistory: {
          filterActions: {
            search: "Search",
            fromDateLabel: "From date",
            toDateLabel: "To date",
            accountNumberLabel: "Acc number",
            shareCodeLabel: "Symbol",
            selectAll: "All",
          },
          table: {
            header: {
              order: "ID",
              account: "ACCOUNT",
              shareCode: "SYMBOL",
              oddShare: "VOLUME",
              buyPrice: "BUY PRICE",
              buyValue: "BUY VALUE",
              buyDate: "BUY DATE",
              statusProcess: "STATUS",
            },
          },
          error: {
            dateRange: "Exceeded the 30-day search",
            dateError: "From date must be less To date",
          },
        },
      },
    },
  },
  categories: {
    placeOrderTooltip: "Double click to order",
    ticketTooltip: "Click to see more code",
    ticketDetail: "View code details",
  },
  moneyTransfer: {
    all: "All",
    equity: "Equity",
    derivative: "Derivative",
    bankTransfer: "Bank tranfer",
    internalTransfer: "Internal tranfer",
    derivativeTransfer: "Tranfer to derivative",
    depositToVSD: "Deposit to VSD",
    withdrawFromVSD: "Withdraw from VSD",
    D_CP: "Deposit",
    W_CP: "Withdraw",
    BIDVTransfer: "BIDV Transfer",
    ExternalTransfer: "Transfer out",
    CS_TO_PS: "Tranfer to derivative",
    PS_TO_CS: "Transfer MB",
    transferHistory: "Transfer history",
    createTransferRequest: "Create a money transfer request",
    fromAccount: "From account",
    toAccount: "To account",
    accountHolder: "Bank acc name",
    bank: "Bank",
    branch: "Branch",
    address: "Address",
    transferMethod: "Transfer method",
    amountsTransferable: "Amounts transferable",
    cashBalance: "Current cash",
    transferAmount: "Transfer amount",
    transferFee: "Transfer fee",
    remark: "Content",
    enterRemark: "Entter content",
    enterAmount: "Enter money",
    fastTransfer: "Fast transfer",
    normalTransfer: "Normal transfer",
    payMarginFee: "Pay fee Margin/BCC+",
    transferType: "Transfer type",
    checkDetail: "Please check the transaction details Before you action",
    petitioner: "Requester",
    idOrPassportNumber: "ID number",
    MBSAccountNumber: "Account at MBS",
    fromDate: "From date",
    toDate: "To date",
    accountType: "Account Type",
    transferStatus: "Status",
    transferOverLimit: "Transfe over limit",
    transferOverLimitDesc:
      "Amount is over limit that MBS needs to check and approve. Are you sure to execute??",
    moneyTransferSuccess: "Send your request successfully",
    transferSuccess:
      "Your request has sent to our system. Please check status request transfer",
    noRecord: "Not record",
    choseBranch: "Choose branch",
    escrow_d_cp: "Deposit",
    escrow_w_cp: "Withdraw",
    first_escrow_desc:
      "Note: Available trading time is from 8:30 to 15h45 on working days. Please pay attention when making deposit / withdrawal to limit the collateral management fee. Fee details sinh phí Quản lý tài sản ký quỹ. Chi tiết về biểu phí",
    is_here: "Here",
    from_vsd: "Withdraw fee",
    to_vsd: "Deposit fee",
    last_escrow_desc:
      "VSD (5.500) bank's fee will be deducted at your MBS account.",
    ac: "ACC",
    accountNotSupport:
      "Tài khoản Ngân hàng nhận không thuộc danh sách liên kết,  giao dịch thực hiện theo hình thức chuyển thường.",
    transferNormalMethod:
      "Giao dịch được thực hiện theo hình thức chuyển thường",
    branchNote:
      "Quý khách chọn Chi nhánh trong danh sách liên kết để chuyển tự động, trường hợp Chi nhánh NH của Quý khách không có trong danh sách vui lòng chọn “Khác”, giao dịch sẽ được xử lý thủ công.",
    calculatingTransFee: "Hệ thống đang tính phí chuyển tiền...",
    error: {
      limitAmount: "Transferred share must not exceed withdrawable available",
      notEmptyContent: "Content isn't blank",
      notEmptyAmount: "Tranfer money isn't blank",
      notEmptyBranch: "Branch isn't blank",
      invalidAmount: "Invalid transfer amount",
      invalidFeePaymentAccount:
        "Receive account pay Margin/BCC+ fee is invalid",
      doNotTransferMoreThanCashMaxInOneDay:
        "On a day, do not transfer larger amount",
      doNotTransferLargerAmounts: "do not transfer larger amount",
      lessThanMin: "Transfer amount cannot be smaller",
      remarksIsAlphanumeric:
        "Remarks cannot contain special characters and accented Vietnamese",
      errorDay: "Begin day cannot be larger than end day",
    },
    table: {
      transferDate: "Trans date",
      transferType: "Trans type",
      accountTransfer: "From ACC",
      accountReceive: "To ACC",
      inputClientName: "Tên người nhận",
      transferAmount: "Amount",
      transferFee: "Trans fee",
      remark: "Remark",
      rejectReason: "Reject reason",
      status: {
        status: "Status",
        notAccept: "Pending",
        success: "Approved",
        reject: "Reject",
        pending: "In Progress",
        DA_DUYET: "Approved",
        DANG_XU_LY: "In Progress",
      },
    },
    button: {
      confirm: "Confirm",
      cancel: "Cancel",
      search: "Search",
    },
  },
  orderBook: {
    confirm: "Confirm",
    contentConfirm: "Cancel the selected order",
    title: "Order list of the day",
    search: "Search",
    select: "Select",
    account: "Account",
    cancel: "Cancel order",
    all: "All",
    buy: "Buy",
    sell: "Sell",
    condition: "Condition Type",
    conditionType: {
      title: "Condition",
      all: "All",
      timeConditionOrder: "Time Condition Order",
      priorityOrder: "Priority Order",
      trailingStopOrder: "Trailing Stop Order",
      takeProfit: "Take Profit",
      cutLoss: "Cut Loss",
    },
    normal: "Normal",
    errorCheckCancel: "No orders selected",
    symbol: "Symbol",
    table: {
      ticker: "Symbol",
      account: "Acc",
      type: "Trans",
      status: "Status",
      volume: "Vol",
      price: "Price",
      matchVolume: "Match vol",
      matchPrice: "Match price",
      commandNumber: "Com number",
      timeBook: "P time",
      timeMatch: "F time",
      timeCancel: "C time",
      channel: "Channel",
      tooltipTable: "Double click to see details",
    },
    tableCondition: {
      tradeDate: "Trade Date",
      reqTime: "Request Time",
      channel: "Channel",
      side: "Side",
      traderId: "Trader Id",
      masterAcc: "Master Account",
      accoNo: "Account Code",
      brokerId: "Broker Id",
      secCd: "Sec Cd",
      secType: "Sec Type",
      secSubType: "Sec Sub Type",
      tradingSessionId: "Trading Session Id",
      ordQty: "Order Qty",
      ordPrice: "Order Price",
      matQty: "Mat Qty",
      cancelQty: "Cancel Qty",
      condType: "Condition Type",
      effectDate: "Effect Date",
      expiredDate: "Expired Date",
      matMethod: "Mat Method",
      optType: "Opt Type",
      optVal: "Opt Value",
      optValType: "Opt Value Type",
      condition: "Condition",
      value: "Value",
      valueActive: "Value Active",
      tooltipValueActive:
        "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
      limitOffset: "Limit Offset",
      notes: "Notes",
      clientInfo: "Client Info",
      status: "Status",
      updDateType: "Update Date Type",
      takeEffect: "Take Effect",
      complete: "Complete",
      expired: "Expired",
      cancel: "Canceled",
      generatedOneTime: "Incurred 1 time",
      matchingEnoughVolume: "Match",
      noValue: "---",
      timeConditionOrder: "Before day",
      trailingStopOrder: "Trend",
      priorityOrder: "Buy/Sell",
      takeProfit: "Take profit",
      cutLoss: "Loss",
      noData: "Not Data",
      arbitrage: "Differrence",
      optValByValue: "Value",
      optValByPercent: "%",
    },
    // Utils status - start
    NONE: "None",
    PENDING: "Pending",
    WAIT_MATCH: "Pending Match",
    MATCHING_PARTIAL: "Matched partial",
    MATCH_PART: "Partial",
    MATCHING: "Matched",
    MATCHED: "Matched",
    WAIT_EDIT: "P edit",
    WAIT_CANCEL: "P cancel",
    WAIT_ACTIVE: "P active",
    REJECTED_EDIT: "R edit",
    REJECTED_CANCEL: "R cancel",
    EDITED: "Edited",
    CANCELLED: "Cancel",
    REJECTED: "Reject",
    WAIT_APPROVE: "Pending approve",
    // Utils status - end
    BUY: "Buy",
    BUY_OUTR: "Buy OutR",
    SELL: "Sell",
    SELL_OutR: "Sell OutR",
    SELL_TT68: "Sell TT68",
    SELL_FORCE: "Sell Force",
    SELL_FORCE_OutR: "Sell Force OutR",
    SELL_FORCE_TT68: "Sell Force TT68",
    orderSuccess: "Order success",
    orderError: "An error occurred. Please check again",
    editCommand: "Edit oder",
    undefined: "Undefined",
  },
  orderBasket: {
    filter: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        all: "All",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
    },
    table: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
      commandType: {
        title: "Command type",
        normal: "Normal",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Volume",
      price: "Price",
      createdTime: "Create Time",
    },
    titleButtonBuy: "ORDER BASKET (BUY)",
    titleButtonSell: "ORDER BASKET (SELL)",
    cancel: "Cancel",
    placeOrder: "Place order",
    activeOrder: "Active order",
    orderCreateSuccess: "Create order basket success",
    orderDeleteError: "Delete order basket failed",
    confirmDelete: "Confirm cancel order",
    contentConfirmDelete: "You definitely want to cancel the order",
    confirmActive: "Confirm active order",
    contentConfirmActive: "You definitely want to active the order",
    cancelSuccess: "Cancel order basket success",
    cancelFail: "Cancel order basket failed",
    activeSuccess: "Active order basket success",
    activeFailed: "Active order basket failed",
    createSuccess: "Create order basket success",
    createFailed: "Create order basket failed",
    errorCheckCancel: "No lines selected to cancel",
    errorCheckActive: "No lines selected to active",
    orderError: "Order basket error",
    tabNameTooltip: "Kích để add lệnh vào giỏ",
  },
  orderBasketDer: {
    filter: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        all: "All",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
    },
    table: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
      commandType: {
        title: "Command type",
        normal: "Normal",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Volume",
      price: "Price",
      createdTime: "Create Time",
    },
    titleButtonLong: "ORDER BASKET (LONG)",
    titleButtonShort: "ORDER BASKET (SHORT)",
    cancel: "Cancel",
    placeOrder: "Place order",
    activeOrder: "Active order",
    orderError: "Order basket der error",
  },
  error: errorsEn,
  validation: {
    emptyAccount: "Please select an account",
    emptyTicker: "Please select symbol",
    emptyPrice: "Please select a price",
    emptyStopPrice: "Please enter activation price",
    emptyStopLoss: "Please enter activation stop loss",
    emptyProfit: "Please enter activation profit",
    restrictedTrading: "Code suspended / restricted transactions",
    rangePrice: "Price must be between Floor and Ceiling",
    patternPrice: "Inval set price (step price 0.1)",
    patternStopPrice: "Giá kích hoạt không hợp lệ",
    patternStopLoss: "Giá cắt lỗ không hợp lệ",
    patternProfit: "Giá chốt lãi không hợp lệ",
    patternMargin: "Giá biên trượt không hợp lệ",
    emptyVolume: "Please select volume",
    emptyOtpVal: "Please input otp value",
    emptyLimitOffset: "Please input limit offset",
    patternVolume: "The volume is not round lot (Lot 100)",
    maxVolumeSell: "Quantity is over max number",
    maxVolumeBuy: "Quantity is over max quantity",
    maxVolumeFAL: "Quantity is over maximum FAL volume",
    overRoom: "Quantity is over remain room",
    patternMaxLongVolume: "Volume exceeds max Long volume",
    patternMaxShortVolume: "Volume exceeds max Short volume",
    activePriceUp: "Trigger price have to be larger than market price",
    activePriceDown: "Trigger price have to be smaller than market price",
    emptyMargin: "Please enter slip margin",
    takeProfilePrice_Error_001: "Price take profit big than price",
    takeProfilePrice_Error_002: "Price take profit less than celling",
    takeProfilePrice_Error_003: "Price take profit less than price",
    takeProfilePrice_Error_004: "Price take profit big than floor",
    stopLotPrice_Error_001: "Price cut loss less than price",
    stopLotPrice_Error_002: "Price cut loss big than floor",
    stopLotPrice_Error_003: "Price cut loss plus sliding big celling",
    stopLotPrice_Error_004: "Price cut loss big than price",
    stopLotPrice_Error_005: "Price cut loss less than celling",
    stopLotPrice_Error_006: "Price cut loss plus sliding not big celling",
    stopLotPrice_Error_007: "Price cut loss different set price,",
    stopLotPrice_Error_008: "Price cut loss big than market price",
    stopLotPrice_Error_009: "Price cut loss plus sliding less than celling",
    stopLotPrice_Error_010: "Price cut loss less than market price",
    stopLotPrice_Error_011: "Price cut loss minus sliding not less than floor",
  },
  formPlaceholder: {
    inputAvgPrice: "Enter Price Avg",
    inputOptValByValue: "Enter Value",
    inputOptValByPercent: "Enter Percent",
    inputLimitOffset: "Enter LimitOffset",
    inputPrice: "Enter price",
    inputVolumne: "Enter volume",
    inputValue: "Enter value",
  },
  sidebar: {
    getOTP: "Get OTP",
    categories: "Watchlist",
    market: "Market",
    stockOrder: "Trading",
    placeOrderDer: "Place order derivative",
    orderBook: "Order List",
    orderHistory: "Order history",
    moneyTransfer: "Cash transfer",
    advance: "Advance",
    coveredWarrant: "Covered warrant",
    proInvestor: "Pro Investor",
    loanExtension: "Cash transaction",
    marginLimit: "Margin limit",
    screeners: "Screeners",
    DVTC_NAV: "Property report",
    infoWarrant: "Register for the right to buy",
    disableMenu: "Dis/able menu",
    support: "Support",
    businessCooperation: "Business cooperation",
    extends: {
      title: "Extends",
      profitLoss: "Profit loss equity/der",
      confirmOrderOnline: "Confirmation order online",
      categoryFinancialService: "Category financial service",
      statement: "Statement",
      oddLotShare: "Buy odd-lot share",
    },
    transferShare: "Transfer Share",
    packServices: "Pack Services",
    extendsOne: "Extends One",
    extendsTwo: "Extends Two",
    extendsThree: "Extends Three",
    investmentEfficiency: "Investment Efficiency",
    techAnalysis: "Technical Analystic",
    expandMenu: "Expand",
    collapseMenu: "Collapse",
  },
  stockOrderBoard: {
    chooseABoard: "Choose a Board",
    all: "All",
    default: "Default",
    customized: "Customized",
    newBoard: "New Board",
    descNewBoard: "Adding widggets to an blank board.",
    standard: "Standard",
    descStandard: "For basic and new trader.",
    standard2: "Standard2",
    widgetsSettings: "Widgets Settings",
    suggestionsWidgetsSettings: "Select and drag and drop and board",
    addDisplayArea: "Add display area",
    hoverOverTheAreaToAdd: "Hover over the area to add",
    viewDoesNotExist: "View does not exist",
    tab: {
      title: "Tab",
      open: "Open",
      rename: "Rename",
      saveTheTemplate: "Save the template",
    },
    modalSaveBoard: {
      titleModal: "Save as a board",
      nameBoard: "Name board",
      description: "Description",
      cancel: "Cancel",
      save: "Save",
    },
    modalRenameBoard: {
      titleModal: "Rename Board",
      ok: "OK",
      cancel: "Cancel",
    },
  },
  stockOrderDerBoard: {
    all: "All",
    default: "Default",
    customized: "Customized",
    addingWidggets: "Adding widggets to an blank board",
    blank: "Blank",
    DefaultStock: "Adding widggets to an blank board",
    normal: "Normal",
    multiTrades: "Multi-trades",
    MultiChart: "Multi chart or trades board",
    addDisplaytitle: "Add display area<br>(Hover over the area to add)",
    saveBoard: "SAVE AS A BOARD",
    boardName: "Name board",
    description: "Description",
    changeBoardName: "Rename board",
    chooseBoard: "Choose a Board",
    open: "Open",
    changeName: "Rename",
    save: "Save the template",
    noView: "View does not exist",
  },
  advance: {
    advanceHistory: "Advance history",
    advanceMoney: "Advance",
    fromDate: "From date",
    toDate: "To date",
    account: "Account",
    overDate: "The start date cannot be greater than the end date",
    lengthDate: "Exceeded the 30-day search",
    success: "Advance successful",
    button: {
      confirm: "Comfirm",
      cancel: "Cancel",
      search: "Search",
      add: "Add account",
    },
    table: {
      index: "ID",
      contractID: "Contract number",
      account: "ACC",
      accountHolder: "ACC HOLDER",
      soldDate: "SELL DATE",
      advanceDate: "ADV DATE",
      amount: "AMOUNT",
      advanceFree: "ADV FREE",
      advanceSource: "ADVAN SOURCE",
      receiveAccount: "RECEIVE ACC",
      status: {
        status: "STATUS",
        success: "APPROVED",
        reject: "PENDING",
        pending: "IN PROGRESS",
      },
    },
    advanceAccount: "Account",
    totalSaleAmount: "Total sell amount",
    advancedMoney: "Advance amount + fees",
    advanceableMoney: "Advance available",
    withdrawable: "Advance amount",
    interestRate: "Interest rate",
    provisionalFee: "Fee estimate",
    enterAmount: "Enter amount",
    addAccount: "+ Add account",
    error: {
      noContent: "Please enter the amount",
      overPriced: "Amount is over available",
    },
    accountReceiver: {
      header: "Add account",
      id: "Number account",
      owner: "Acc holder",
      bank: "Bank",
      city: "Bank province",
      branch: "Branch",
      description:
        "Services Loan Bank advances MB provided with preferential interest rate of 8% / year.",
      register: "Register now here.",
      input: {
        id: "Enter account",
        owner: "Enter account holder name",
        bank: "Enter bank",
        city: "Enter bank province",
        branch: "Enter branch",
      },
    },
  },
  tableInfinityScroll: {
    tableContent: {
      loadMore: "Loading",
      loadEnd: "No new data",
      nothing: "Nothing",
    },
  },
  orderBookDer: {
    symbol: "Symbol",
    side: "Trans",
    stopType: "Type",
    Status: "Status",
    orderQty: "Mass",
    price: "Price",
    cumQty: "Match mass",
    matchAvgPrice: "Price match BQ",
    stopPrice: "Price cut loss",
    stopPrice2: "Price take profit",
    orderId: "SHL",
    createdTime: "Placed time",
    matchTime: "Filled time",
    cancelTime: "Cancel time",
    channel: "Channel",
    Normal: "Normal",
    UP: "UP",
    DOWN: "Down",
    TUP: "T Up",
    TDOWN: "T Down",
    BULLBEAR: "Bull&Bear",
    OCO: "OCO",
    inputHD: "Enter HD",
    search: "Search",
    comfirmTitle: "Confirm Order Cancellation",
    comfirmMessage: "Are you sure you want to cancel this order",
    accept: "Accept",
    cancel: "Cancel",
    Updated: "Edited",
    PedingUpdate: "Pending edit",
    Waiting: "Pending",
    WaitingCancel: "Pending cancel",
    RejectUpdate: "Reject edit",
    RejectCancel: "Reject cancel",
    noValue: "-",
    cancelSucces: "Cancel successfully",
    cancelFail: "Cancel fail",
  },
  OrderBookDerDetail: {
    title: "Details",
    tradeNumber: "Order No",
    account: "Account",
    status: "Status",
    tradeType: "Type",
    orderTime: "Order time",
    activeTime: "Active time",
    orderVolumn: "Order QTY",
    orderPrice: "Order price",
    stopLoss: "Stop Price",
    stopPrice: "Cut loss price",
    margin: "Sliding",
    adjustableStopLossPrice: "Cut loss price controled",
    matchingVolume: "Matched QTY",
    stopPrice2: "Price take profit",
    matchingValue: "Matched price",
    cancelTime: "Cancel time",
    cancelVolume: "Cancel QTY",
    chanel: "Order chanel",
    cancelSuccessMessage: "CANCEL successful",
  },
  orderHistory: {
    orderHistoryCS: "Order history",
    orderHistoryFS: "Derivative history",
    orderDetail: "Deatails",
    fromDate: "From date",
    toDate: "To date",
    account: "Account",
    side: "Transaction",
    orderType: "Order type",
    conditionOrder: "Condition order",
    status: "Status",
    priceConditionNote:
      "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
    table: {
      stt: "ID",
      orderDate: "Order date",
      orderTime: "Order time",
      orderActive: "Active time",
      account: "Account",
      orderShare: "No",
      orderContract: "Contract No",
      orderSide: "Transaction",
      volume: "QTY",
      orderVolume: "QTY order",
      price: "Price",
      stopPrice: "Price cut loss",
      orderPrice: "Order Price",
      orderStatus: "Status",
      orderNo: "Order No",
      orderChannel: "Order Channel",
      orderVolumeFilled: "Matched amount",
      orderPriceFilled: "Matched price",
      orderType: "Order type",
      order: "Order",
      slipMargin: "sliding",
      adjustableStopPrice: "Price cut loss control",
      cancelTime: "Cancel time",
      cancelVolume: "Cancel amount",
      channel: "Channel",
      time: "Time",
      action: "Action",
      reasonReject: "Reason for refusal",
      conditionPrice: "Condition price/Stop loss",
      takeProfitPrice: "Take profit price",
      orderVolumeShort: "KL đặt",
      match: "Khớp",
      conditionOrder: "Lệnh điều kiện",
      method: "Phương thức",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      referencePrice: "Giá TC",
      channelOrder: "Kênh ĐL",
      difference: "Chênh lệch",
      byPercent: "Theo %",
      byValue: "Theo giá trị",
      value: "Giá trị",
      pause: "Khoảng dừng",
      activePrice: "Giá kích hoạt",
      status: {
        all: "All",
        filled: "Matched",
        pending: "Matched partial",
        cancel: "Cancel",
      },
      fsStatus: {
        all: "All",
        reject: "Reject",
        cancel: "Cancel",
        filled: "Match",
        pending: "Wait",
      },
      fsStatusHistory: {
        inActive: "Not active",
        stopReady: "Stop",
        readyToSend: "Ready to send",
        queue: "Wait",
        sending: "Sending",
        sent: "Sended",
        reject: "Reject",
        cancel: "CANCEL",
        partialMatch: "Matched partial",
        partialFilledCancel: "Canceled",
        fullMatch: "Matched",
        matchAndCancel: "Canceled",
        waitApprove: "Pending",
        waitEdit: "Pending edit",
        waitCancel: "Waiting",
        priceWarning: "Warning Price",
        accomplished: "Finished",
        expried: "expried",
        partialExpried: "Expired partial",
        conditionalOrder: "Conditional order",
        coi: "COI",
        oco: "OCO",
        oci: "OCI",
        undefined: "Undefined",
        beAllocated: "Be Allocated (Fully Filled)",
      },
      side: {
        all: "all",
        buy: "Buy",
        sell: "Sell",
      },
      tradeDate: "order date",
      seriesId: "ID contract",
      fsSide: {
        all: "all",
        short: "Short",
        long: "Long",
        longUp: "LONG",
        shortUp: "SHORT",
      },
      fsAction: {
        IC: "order",
        AS: "Order successful",
        PE: "Matched",
        MC: "Edit",
        MS: "Edit successful",
        FL: "Matched",
        MO: "Edit",
        AF: "Reject",
        CO: "Cancel",
        DC: "Cancel successful",
        AZ: "Cancel",
        IS: "Place Order",
        CI: "tranfer not active",
        CA: "Active",
        KL: "Reject",
        II: "Place Order",
        SC: "Active",
        EX: "Reject",
        CS: "Cancel successfully",
        IJ: "Place Order",
        IA: "Place Order",
        AD: "Allocation done",
        AM: "Approve OK (Local modify)",
        AP: "Approve OK",
        AX: "Disapprove",
        BC: "BIX Locally Cancel",
        BM: "BIX Locally Modified",
        BR: "BIX Locally Reduced",
        CF: "Notify Client for fully-matched",
        CM: "Cancel Modify Request",
        CW: "Cancel Price Warning",
        DA: "Deactive Order",
        DE: "Request Deallocation",
        DI: "Dehold Inactive",
        FA: "Fill Auction",
        FC: "Fully Cancel",
        FF: "Fully Filled",
        HF: "Hold Fund",
        ID: "Input C.D. Order",
        IG: "Inactivate Today GTD Order",
        IK: "Input Inactive Conditional Order",
        IM: "Input Conditional Order (Odd Lot)",
        IN: "Input Inactive Con Order(Odd Lot)",
        IP: "Input Split Order",
        LM: "Local Modify",
        MJ: "Modify Reject",
        ML: "Modify Local Order",
        NA: "Notify client (No Answer)",
        OI: "Order Inputed to host",
        PC: "Partially Cancel",
        PF: "Partially Filled",
        PM: "Pending Modify",
        PR: "Trade Partially Reject",
        SS: "Send",
        RA: "Request Allocation",
        RB: "Resubmit for price chasing",
        RD: "Reduce Order",
        RJ: "Host Reject",
        RM: "Reject Modify (Local Modify)",
        RR: "Reject Resubmit (Market Close)",
        SD: "Split C.D. Order",
        PW: "Price Warning",
        ST: "Submit Stop Order",
        SX: "Undo Send by Manual Reporting",
        TB: "User Manually Unfill Executed Trade",
        TM: "Trade Modify",
        TR: "Trade Reject",
        TS: "Trigger Send",
        UC: "Unsolicited Cancel",
      },
      fsOrderType: {
        L: "LO",
        M: "MO",
        A: "ATO",
        C: "ATC",
        K: "MTL",
      },
      channelName: {
        I: "Internet",
        M: "Mobile",
        D: "Broker",
        B: "Bloomberg",
        H: "Home",
        O: "Omnibus",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      channelFSName: {
        WT: "D24",
        FOT: "FOT",
        BR: "Broker",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      fsStopType: {
        N: "Thường",
        D: "Down",
        U: "Up",
        V: "T Up",
        E: "T Down",
        O: "OCO",
        B: "Bull & Bear",
      },
      fsDealStatus: {
        waitApprove: "Wait",
        waitActive: "Waiting active",
        partialMatch: "Matched partial",
        fullMatch: "Matched",
        cancel: "Canceled",
        reject: "Reject",
        edited: "edited",
        waiting: "pending",
        waitCancel: "Pending cancel",
        rejectEdit: "Reject edit",
        waitEdit: "Pending edit",
        rejectCancel: "Reject cancel",
      },
      filterOrderType: {
        normal: "Lệnh thường",
        condition: "Lệnh điều kiện",
      },
      filterCondition: {
        all: "Tất cả",
        beforeDay: "Trước ngày",
        trending: "Xu hướng",
        competitive: "Tranh mua/bán",
        takeProfit: "Chốt lãi",
        stopLoss: "Cắt lỗ",
      },
      statusCondition: {
        all: "Tất cả",
        complete: "Hoàn thành",
        expired: "Hết hạn",
        cancel: "Đã hủy",
      },
      sideCondition: {
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      methodCondition: {
        once: "Phát sinh 1 lần",
        full: "Khớp đủ KL đặt",
      },
      channelCondition: {
        N: "Mobile App",
        W: "New Web",
      },
    },
    null: "--",
    modal: {
      link: "Details order",
      title: "Derivative",
    },
  },
  infoWarrant: {
    registerBuy: {
      errorShareRegister0: "Quantity must be larger than 0 ",
      errorShareRegister_shareCanBuy:
        "Amout of the stock not bigger than the amount orderd",
      errorBuyPrice_cashBalance: "The current balance is not enough",
      accountCode: "Account registed",
      shareCode: "Ticket",
      registerFromDate: "Begin reg date",
      registerToDate: "To date",
      shareCanBuy: "Quantity available",
      buyPrice: "Price",
      shareRegister: "Quantity",
      placeholder_shareRegister: "Enter quantity",
      sum: "Total cash order",
      accountPay: "Payment Account",
      cashBalance: "Cash balance",
      success: "Register successfully",
    },
    filter: {
      all: "All",
      CHUA_THUC_HIEN: "not perform",
      DA_THUC_HIEN: "performed",
      account: "Acconut Number",
      sticker: "Stock code",
      status: "Status",
      search: "Search",
      RIGHT_BUY: "Order rights",
      RIGHT_DIVIDEND: "Dividend rights as cash",
      RIGHT_STOCK: "Dividend rights as stock",
      RIGHT_VOTE: "Meeting rights ĐHCĐ",
      CONVERSION_STOCK: "tranfer rights stock",
      RIGHT_CASH_ORIGIN_BOND: "Received the orgin Bond rights",
      CHUA_CHOT_DS_CO_DONG: "Unfinish the list shareholder",
      RIGHT_COVERED_WARRANT: "Right to maturity CW",
      DA_DUYET: "Approved",
      RIGHT_BUY_CHUA_THUC_HIEN: "order rights pending",
      RIGHT_BUY_DA_THUC_HIEN: "Rights to order",
      CHUA_CHOT_CO_DONG: "Unfinsh the list owner",
      DA_CHUYEN_T_THAI: "The account {} transfer securities state",
      DA_DUYET_T_THAI: "Approved",
      RIGHT_STOCK_CHUA_CHOT_DS_CO_DONG: "Unfinsh the list owner",
      CHUA_CHOT_DANG_KY: "Unfinish the list order",
      RIGHT_STOCK_CHUA_THUC_HIEN: "Not Right Executed",
      RIGHT_STOCK_DA_THUC_HIEN: "Right Executed",
      type: "Rights",
      closeFromDate: "Close date",
      execFromDate: "Implementation date",
    },
    table: {
      index: "#",
      DA_THUC_HIEN: "Received",
      CHUA_THUC_HIEN: "Not received yet",
      accountCode: "ACC number",
      shareCode: "Stock",
      shareRegister: "The amount of registed",
      buyPrice: "Price",
      cashMoney: "Cash",
      registerDate: "The register day",
      excuteDate: "Impleme date",
      statusCode: "Status",
      registerFromDate: "The begin day register",
      registerToDate: "The last day register",
      rightTotal: "Amount of right",
      shareCanBuy: "Amount can buy",
      valueShareRegister: "Amount registed",
      type: "Right",
      closeDate: "Last day",
      volumeCanBuy: "Amount can buy",
      registedVolume: "Amount of registed",
      shareDividend: "Cash received",
      cashDividend: "Dividend by cash",
      rightCashRate: "cash DIV(%)",
      rightShareRate: "DIV",
      registerTime: "Register to buy time",
      transferTime: "Tranfer time",
      rightBuyRate: "Rate order",
      price: "Order price",
      confirmDate: "Implementation date",
    },
    tab: {
      RESIGNTER: "Right to buy registed",
      HISTORY: "Right to buy registed history",
      INFORMATION: "Infor rights search",
      register: "RegisterBuy",
    },
  },
  transferShare: {
    title: "Transfer Share",
    filter: {
      fromDate: "From Date",
      toDate: "To Date",
      account: "Account",
      shareCode: "ShareCode",
      search: "Search",
    },
    table: {
      index: "STT",
      transactionDate: "Transaction Date",
      accountOut: "Account Out",
      accountIn: "Account In",
      shareCode: "ShareCode",
      shareStatusOut: {
        title: "Share Status Out",
        normal: "Normal",
        outR: "OutR",
        tt68: "TT68",
      },
      shareVolume: "Share Volume",
      statusName: "Status Name",
      content: "Content",
    },
    tab: {
      transferShare: "Transfer Share",
      transferShareHistory: "Transfer Share History",
      transferShareForm: "Transfer Share Form",
    },
    transferForm: {
      accountOut: "Account Out",
      accountIn: "Account In",
      content: "Content",
      content_value: "TK {accountOut} chuyển khoản sang {accountIn}",
      content_value1: "TK",
      content_value2: "chuyển khoản sang",
      placeholder_content: "Nhập nội dung",
      table: {
        shareCode: "Share Code",
        statusName: "Status Name",
        shareBalance: "Share Balance",
        maxWithDraw: "Max With Draw",
      },
      button: {
        cancel: "Cancel",
        confirm: "Confirm",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      errorMaxWidthdraw: "Vui lòng nhập SL chuyển lớn hơn 0",
      transferShareSuccess: "Chuyển khoản thành công",
    },
  },
  packServices: {
    title: "Transfer Share",
    filter: {
      fromDate: "From Date",
      toDate: "To Date",
      status: {
        title: "Status",
        all: "All",
        notApprovedYet: "Not Approved Yet",
        approved: "Approved",
        rejectApproved: "Cancel Approved",
        canceled: "Canceled",
        cancelApprovedWait: "Cancel Approved Wait",
      },
      search: "Search",
    },
    table: {
      accountCode: "Account Code",
      createdDate: "Created Date",
      packServicesName: "Pack Services Name",
      packServicesCode: "Pack Services Code",
      expiredDate: "Expired Date",
      status: "Status",
      cancel: "Cancel",
      cancelSuccess: "Hủy đăng ký thành công",
    },
    tab: {
      packServices: "Pack Services",
      packServicesHistory: "Pack Services History",
      packServicesForm: "Pack Services Form",
    },
    packServicesForm: {
      accountCode: "Account Code",
      fullName: "Full Name",
      packServicesName: "Pack Services Name",
      packServicesCode: "Pack Services Code",
      content: "Content",
      createdDate: "Created Date",
      expiredDate: "Expired Date",
      contentCondition:
        "Tôi đã đọc, hiểu và xác nhận đồng ý với các chính sách, dịch vụ liên quan đã đăng ký",
      placeholder_content: "Nhập nội dung",
      button: {
        cancel: "Cancel",
        confirm: "Confirm",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      packServicesSuccess: "Đăng ký chính sách thành công",
    },
  },
  coveredWarrant: {
    register: "RegisterBuy",
    purchaseHistory: "Order register history",
    information: "Infor the warrant",
    all: "All",
    buy: "Buy",
    sell: "Sell",
    history: {
      filter: {
        fromDate: "From date",
        toDate: "To date",
        cutAccount: "Cut cash on the account",
        coveredWarrantID: "W",
      },
      table: {
        index: "ID",
        coveredWarrantID: "W",
        registerAccount: "Accout off registed",
        cutAccount: "Cut cash on the account",
        ckcs: "UDS",
        publishDate: "Issued",
        registerDate: "Register date",
        quantity: "QTY",
        purchasePrice: "Price",
      },
      register: {
        coveredWarrantID: "Warrant code",
        cwIDPlaceHolder: "Select the warrants",
        coveredWarrantPlaceholder: "Warrant code",
        quantityPlaceholder: "Amount registed order",
        codeList: "FPT/BSC/C/6M/EU/ warrants",
        ckcs: "UDS",
        publishDate: "Issued",
        publishDatePlaceHolder: "Select  issued",
        purchaseAccount: "Account registed",
        cutAccount: "Account",
        availableAmount: "Cash available",
        quantity: "Amout registed order",
        purchasePrice: "Order price",
        total: "Total value order price",
        agree: "I read and agreed with ",
        terms: "Terms and conditions",
        registerToBuy: "Register buy the warrants at MBS",
        modalTerms: {
          title: "Terms and conditions",
          close: "Closed",
        },
        success: "Register successfully",
        error: {
          noCWCode: "Warrant code not empty",
          noVolume: "Volume registed not empty",
          overPriced: "Value of order must less than cash available",
          overDate: "From date must to less than to date",
          lengthDate: "Exceeded the 100-day search",
          divisible: "Amount must to divisible by 100",
          overVolume:
            "Amout of order exceeded amount of the warrants available",
          notYet: "Not time to sign. Please try again",
          account: " This account is Pending or editting",
        },
        button: {
          cancel: "CANCEL",
          confirm: "CONFIRM",
        },
      },
    },
    info: {
      filter: {
        coveredWarrantID: "Warrant code",
        all: "All",
        type: "Warrant",
        underlyingSecuritiesID: "UDS",
        underlyingSecuritiesIDPlaceHolder: "Type UDS code",
      },
      table: {
        index: "ID",
        coveredWarrantID: "Code",
        name: "Name",
        ckcs: "UDS code",
        tcph: "Organization",
        amount: "Amount",
        exerciseRatio: "convert rate",
        period: "Period (M)",
        gdccDate: "Tran ate",
        dueDate: "Expired date",
        estimatedPaymentDate: "Es paym date",
        exercisePrice: "Price",
        estimatedPaymentPrice: "Es paym price",
      },
      description:
        "Disclaimer: The estimated payment price is for reference only. MBS is not responsible for deviations.",
    },
  },
  screeners: {
    tabs: {
      screener: {
        title: "Screener",
        market: {
          legend: "Market",
          exchange: "Exchange",
          industry: "Industry",
          select: "--- Select filter ---",
        },
        quote: {
          legend: "Quote Indicator",
          marketCap: "Market Cap",
          price: "Price",
          changeRate: "% Change",
        },
        financial: {
          legend: "Financial Indicator",
          pe: "P/E",
          eps: "EPS",
        },
        technical: {
          legend: "Technical Indicator",
        },
        other: {
          legend: "Other",
          analystRating: "Analyst Rating",
          marginAvailable: "Margin Available",
        },
        actions: {
          reset: "Reset",
          save: "Save",
        },
      },
      myScreener: {
        title: "My screener",
      },
    },
    card: {
      cardTitle: "{total} Result",
      tickerTooltip: "Detail code",
      table: {
        order: "ID",
        shareCode: "stock code",
        companyName: "company name",
        price: "PRICE",
        change: "CHANGE",
        changeRate: "%CHANGE",
        marketCap: "MARKET CAP",
        pe: "P/E",
        pb: "P/B",
        netMargin: "NET SALES (B)",
        plusButton: "Add to Watchlist {number}",
        roa: "ROA (%)",
        roe: "ROE (%)",
        eps: "EPS",
      },
      modal: {
        title: "ADD FILTER",
        screenerName: "Filter name",
        placeholder: "Type name filter",
        cancel: "Cancel",
        save: "save",
        errorMessage: "Filter {name} exist, please select other filter name",
      },
    },
  },
  loanExtension: {
    tabHeader: {
      loanTracking: {
        title: "Flow debit",
      },
      extendHistory: {
        title: "Online extend history",
      },
    },
    tabPanel: {
      loanTracking: {
        filterActions: {
          account: "Acount",
          status: "Status",
          search: "Search",
          all: "All",
          canExtend: "Can extension'",
        },
        table: {
          header: {
            deliverDate: "DISB DATE",
            oldExpireDate: "EXPIRED DATE",
            numDayDiff: "NUMBER DAYS",
            feeRate: "INTEREST (%/DAY)",
            intermLoan: "DEBIT DATE",
            intermFee: "INTEREST OF DATE",
            exterLoan: "DEBIT OUT DATE",
            exterFee: "INTEREST OUT DATE",
            totalLoanBLN: "Total debit",
            extendFeeRate: "Interest after extended (%/DAY)",
            newExpireDate: "NEW EXPIRE DATE",
          },
          footer: {
            extend: "Extension",
            total: "TOTAL",
            notCheckedError: `Loan hasn't been selected`,
          },
        },
      },
      extendHistory: {
        filterActions: {
          fromDate: "From date",
          toDate: "To date",
          status: "Status",
          search: "Search",
          all: "All",
          extendStatus: {
            approved: "Approved",
            notControl: "Not control",
            notApprove: "Not approve",
          },
          error: {
            dateRange: "Exceeded the 30-day search",
            invalidDate: "From date must to less than to date",
          },
        },
        table: {
          header: {
            order: "ID",
            accountNum: "Acc number",
            deliverDate: "Delidate",
            oldExpireDate: "Old expice date ",
            numDayDiff: "Borrowed date",
            loanBalance: "Current deb",
            feeBalance: "Debit interest",
            totalLoanBLN: "Total debit",
            newExpireDate: "New expice date",
            extendFeeRate: "Interest (%/year)",
            approveDate: "Extension date",
            channel: "Channel",
            statusName: "Status",
          },
        },
      },
      extendExpireSuccessMsg: "Extend success",
    },
    card: {
      header: "Online extension",
      accountNum: "Account number",
      deliverDate: "Disbursement date",
      oldExpireDate: "Date of maturity",
      numDayDiff: "Loan number",
      loanBalance: "Original debt currenti",
      feeBalance: "Debit interest",
      totalFeeRate: "All debit",
      newExpireDate: "Date of maturity after extended",
      extendFeeRate: "Interest after extension (%/năm)",
      acceptTerms:
        "I have read and agree to the terms and conditions of the margin trading and service policy at MBS",
      cancelButton: "Cancel",
      confirmButton: "Confirm",
      closeButton: "Close",
      note: {
        r1: "Loan isn't block the extension",
        r2: "Not exit current account is out of date",
        r3: " Loan is still renewable: Total loan term from disbursement to new maturity date must be less than or equal to 275 days (total number of days may change according to MBS policy from time to time))",
        r4: "The number of days remaining of the loan until the current expiration date must be less than or equal to 20 days (the number of loan days may change according to MBS's policy from time to time)",
        r5: "The loan isn't extend at the transaction office",
      },
    },
  },
  rangeDateSearch: {
    success: "",
    errorRange: "From dat must not the bigger than to date",
    exceededLimit: "Exceeded the 30-day search ",
  },
  marginLimit: {
    tabs: {
      marginLimitHistory: {
        header: {
          title: "Limit change history",
        },
        filterActions: {
          fromDate: "From date",
          toDate: "To date",
          account: "Account",
          status: "Status",
          search: "Search",
        },
        statusList: {
          all: "All",
          approved: "Approved",
          modified: "Edited",
          modifying: "Editting",
          cancel: "Cancel",
          reject: "Reject",
        },
        dateError: {
          overRange: "Exceeded the 30-day search",
          underRange: "From date must less than to date",
        },
        table: {
          header: {
            order: "ID",
            limitLoanOld: "Value old",
            limitLoan: "Value request new",
            modifyDate: "Request date",
            approveDate: "Approve date",
            status: "Status",
          },
        },
      },
    },
    cards: {
      marginLimitChange: {
        header: {
          title: "LIMIT CHANGE",
        },
        body: {
          placeholderMarginRequire: "Type requestLimit",
          underLimitError: "The new request limit cannot be less than minLimit",
          overLimitError:
            "The new limit of request the bigger than the max limit",
          stepError: " The new limit of request must follow the value step ",
          emptyError: "New limit of request is not . Please type again",
          accountNum: "Acconut number",
          currentLimit: "Current limit",
          requestLimit: "Required limit",
          requestLimitPlaceHolder: "Type required",
          acceptTerms:
            "I have read and agree to the terms and conditions of the margin trading and service policy at MBS",
          note: "Note: The time to change credit limit is before 16:00 on working days",
        },
        actions: {
          cancel: "Cancel",
          agree: "Confirm",
        },
        limitRules: {
          title: "Rules of limit",
          minLimitLabel: "Min credit limit:",
          minLimitUnit: " Million",
          maxLimitLabel: "Max credit limit:",
          maxLimitUnit: " Billion",
          stepValueLabel: "Step value:",
          stepValueUnit: " Million",
          confirmPrefix: "Raise the request limit ",
          confirmSuffix: " MBS need expertise",
        },
      },
    },
    message: {
      modifySuccess: "Change limit successfully",
    },
  },
  tradingHelper: {
    checkPlaceOrder: "Not determined the order",
    symbolDataNotFound: "Not found infor stock {symbol}",
    symbolStatusHalt: "Stock code stoped or limited transaction",
    invalidPriceStep: "Invalid price {priceStep}đ)",
    invalidSecurityType1: "Not yet support placing orders for corporate bonds",
    invalidSecurityType2: "Invalid symbol type",
    invalidMarketCode: "Invalid trading session {marketCode}",
    checkVolumeLot1: "Board lot unit invalid",
    checkVolumeLot2: "The volume is not round lot",
    invalidVolumeLot: "The volume is not round lot",
    invalidVolumeLotEdit:
      "Sàn HNX, Upcom không được sửa KL lô chẵn thành lẻ, lẻ thành chẵn",
    invalidMarketCode2: "Invalid trading session",
    invalidTradingSession: "Unspecified trading session {tradingSessionId}",
    invalidTradingSession2: "The market is closed",
    invalidMarketState: "The market is closed",
    invalidMarketState2:
      "Invalid prics. OT trading session can only place with LO, ATO, ATC price",
    invalidMarketState3:
      "Invalid prics. ATO trading session can only place with LO, ATO, ATC price",
    invalidMarketState4:
      "Invalid prics. OPEN trading session can only place with LO, MP, ATC price",
    invalidMarketState5:
      "Invalid price. BREAK trading session can only place with LO, MP, ATC price",
    invalidMarketState6:
      "Invalid prics. ATC trading session can only place with LO, ATC price",
    invalidMarketState7:
      "Invalid price. OT trading session can only place with LO, ATC price",
    invalidMarketState8:
      "Invalid price. OPEN trading session can only place with LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState9:
      "Invalid price. BREAK trading session can only place with LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState10:
      "Invalid price. ATC trading session can only place with LO, ATC, PLO",
    invalidMarketState11:
      "Invalid price. RUNOFF rading session can only place with PLO",
    invalidMarketState12: "Odd lot orders allow only LO price",
    invalidMarketState13:
      "Odd lot orders allow to place in OPEN, BREAK, OT, OutTime and BOD trading session",
    invalidMarketState14: "Invalid price. UPCOM exchange allow only LO price",
    invalidMarketState15:
      "Invalid price. OPEN trading session can only place LO, MOK, MAK, MTL, ATC",
    invalidMarketState16:
      "Invalid price. OPEN trading session can only place LO, MOK, MAK, MTL, ATC",
    invalidMarketState17:
      "Invalid price. ATC trading session can only place with LO, ATC",
    invalidMarketState18: "Invalid trading session",
    volumeShouldNotBeExceeded: "Quantity cannot to be exceeded {limit}",
    invalidPrice0: "Only order LO price with condition type",
    invalidPrice: "Cut loss price must to bigger than current price",
    invalidPrice2: "Cut loss price not bigger than ceilingPrice",
    invalidPrice3: "Cut loss price other price",
    invalidPrice4: "Cut loss price have to less than current price",
    invalidPrice5: "Cut loss price have to bigger than floorPrice ",
    invalidPrice6: "TakeProfit price have to bigger than price",
    invalidPrice7:
      "Price takeProfit have to less or the same than ceilingPrice",
    invalidPrice8: "Cut loss price have to less than price",
    invalidPrice9: "Cut loss price have to  bigger or the same floorPrice",
    invalidPrice10: "Cut loss price or take profit have to other price",
    invalidPrice11: "Take profit price less than price",
    invalidPrice12: "Take profit pricebigger than floor",
    invalidPrice13: "Cut loss price have to bigger than price",
    invalidPrice14: "Cut loss price less than ceiling price",
    invalidPrice15: "Trigger price cannot be larger than ceiling price",
    invalidPrice16: "Trigger price cannot be smaller than floor price",
    invalidPrice17:
      "Trigger price have to be larger than market price {priceToCheckCond}",
    invalidPrice18:
      "Trigger price  must be lower than last price {priceToCheckCond}",
    invalidPrice19: "Price change cannot be larger than ceiling price",
    invalidPrice20: "Price change cannot be smaller than floor price",
    invalidPrice21: "price take profit less than celling",
    invalidPrice22: "price cut loss bigger than floor",
    invalidPrice23: "Do not edit FAL ordeer",
    invalidOrderData: "Not determined the order to edit",
    marketSessionCloseErr: "The market is closed",
    couldNotGetStockCodeInformationErr: "Not found symbol infomation {symbol}",
    dataDoesNotChange: "There is no change in the data",
    invalidVolumne: "Quantity change have to be larger than 0",
    invalidVolumne2: "Quantity change have to be larger than matched quantity",
    invalidVolumne3: "price cut loss bigger than current price",
    invalidVolumne4: "price cut loss not big celling",
    invalidVolumne5: "price cut loss other price",
    invalidVolumne6: "price cut loss less than last price",
    invalidVolumne7: "price cut loss not less than floor",
    invalidVolumne8: "Not allow to increase quantity",
    doNotModifyOrCancelThisOrderErr: "Do not edit/ cancel this order",
    invalidPriceAndQty: "Price and quantity cannot be edited at the same time",
    invalidMarketCd: "Not allow to edit HSX's order",
    volumeAllowedAndNotChangeprice:
      "Market order is pending to send that allow to edit quantity only",
    checkEditOrderStatus:
      "In the ATO/ATC trading session, do not edit or cancel orders",
    checkEditOrderStatus2: "Do not edit/ cancel this order",
    checkEditOrderStatus3: "Do not edit this order",
    checkEditOrderStatusDer:
      "In the ATO/ATC trading session, do not edit or cancel orders",
    checkEditOrderStatusDerCond:
      "In the ATO/ATC trading session, do not edit or cancel orders",
    checkEditOrderStatusDerCond2: "Do not edit/ cancel this order",
    checkCancelOrder: "Not determine the order to cancel",
  },
  netAssetsValue: {
    nav: {
      tabHeaderTitle: " Net asset NAV",
      card: {
        navSub: "NET ASSET (NAV):",
        marketValueSub: "Portfolio value:",
        profitSub: "Profit/loss (temporary):",
        totalAsset: "Total asset:",
      },
      assetInfo: {
        nav: "TOTAL NAV",
        totalAsset: "TOTAL ASSET",
        totalCashBalance: "1. Cast",
        cashBalance: "1.1 Cash balance",
        matchedBuyT0: "1.2 Order pending payment",
        unMatchBuy: "1.3 Order pending",
        cashAdvanceAble: "1.4 Advance available",
        cashWithdrawable: "1.5 Money can withdraw",
        totalShareValue: "2. Stock",
        shareValue: "2.1 Value of stock balance",
        shareValueBuy: "2.2 Value of stock buy wait for return",
        shareValueSell: "2.3 Value of rights wait a payment",
        sumRight: "2.4 Value of rights wait for return",
        rightCash: "Dividends to cash",
        rightShare: "Dividends to stock ",
        rightBuyStock: "Right to buy",
        totalCWValue: "3. Warrant",
        cwValue: "3.1 Value of warrant present",
        cwValueBuy: "3.2 Value of warrant buy wait for return",
        cwValueSell: "3.3 Value of warrant sell wait a payment",
        cwValueBuyMaturity: "3.4 Value of warrant buy wait a payment expired",
        bondValue: "4. Bond",
        capValue: "5. Bussiness contract ",
        fsTotalAssets: "6. Derivative",
        fsMBS: "6.1 Money of derivative at MBS",
        fsVSD: "6.2 Money of derivative margin VSD ",
        fsCashWithdrawable: "6.3 Money of derivative can withdraw",
        totalDebit: "7. Debit",
        rootDebit: "7.1 Debit balance",
        fee: "7.2 Interest/fee Financial service ",
      },
    },
    financialServices: {
      tabHeaderTitle: "Financial service",
      account: "Account",
      normal: "normal",
      table: {
        tableName: "Menu stock",
        order: "ID",
        accountNoDetail: "ACCOUNT",
        symbol: "Stock code",
        tradeType: "TRANSACTION",
        marginRatio: "Rate of margin (%)",
        availableBalance: "Available",
        sharesBalance: "QTY",
        unmatchSell: "unmatch sell",
        qtyT0: "T + 0",
        qtyT1: "T + 1",
        qtyT2: "T + 2",
        bought: "buy",
        sold: "sell",
        marketValue: "price market",
        grossValue: "Total value",
        total: "Sum",
        total2: "Sum",
        rightShares: "Right Shares",
      },
      cards: {
        totalShareValue: "I. Total value of stock",
        totalCashBalance: {
          title: "II. Money (1-2-3+4+6)",
          cashBalance: "1. Balance",
          buyingCashBlock: "2. Wait_match",
          matchedBuyT0: "3. Matched order wait payment",
          receivingSellCash: "4. Wait sell money (T0, T1, T2)",
          withDrawCash: "5. Money can withdraw",
          cashDividend: `6. Value of rights (temporary)`,
        },
        totalDebit: {
          title: "III. Total debit DVTV (7+8+9)",
          principalBalance: "7. Debit balance",
          paidInterest: "8. Interest/fee financial service",
          cashAdvanced: "9. Used money",
        },
        totalAsset: "IV. Net asset",
        financialServicesAccInfo: {
          title: "INFOR FINANCIAL SERVICE ACCOUNT",
          callRatio: "Rate Call off MBS",
          forceSellRatio: "Rate Force Sell off MBS",
          afterBuyRatio: "Rate after buy",
          interestRatio:
            "Rate of interest loan service not includes extension ",
          creditLimit: "Limit of account",
          falLimit: "Limit of FAL",
          falRemainCredit: "Limit of Fal balance",
          buyCredit: "Purchasing power",
          portfolioValue: "GTDM count QTRR",
          netWorth: "TSR count QTRR",
          currentMarginRatio: "Rate of margin present (TSR/GTDM)",
          capValueLink: "Link purchasing power of business contract",
          bPLinkMB: "link purchasing power of account MB",
          bPLinkMBUsed: "Used link purchasing power MB",
          falUsed: "Limit of Fal used",
        },
      },
    },
    derivative: {
      tabHeaderTitle: "Derivative",
      noAccount: "Account not derivative sub-account",
      filter: {
        account: "Account",
      },
      card: {
        overCash: {
          cardTitle: "Total money balance",
          commissionPerFee: "Fee",
          interest: "Interest",
          deliveryAmount: "Transfer value",
          floatingPLPerTradingPL: "Profit loss position (open/close)",
          totalPL: "Total profit/loss",
          minreservPerMarginable: "Money balance can deposit on the VSD",
          cashDrawablePerNonCashDrawable: "Total money",
        },

        assetPortfolio: {
          cardTitle: "Menu assets",
          MBS: "MBS",
          VSD: "VSD",
          cash: "Cash",
          validNonCash: "Value of margin stock",
          totalValue: "Total value of margin",
          maxValidNonCash: "Value max of margin valid",
          cashWithdrawable: "Money can withdraw",
          ee: "Position",
        },

        signFund: {
          cardTitle: "Use margin",
          MBS: "MBS",
          VSD: "VSD",
          signInitValue: "Value of margin initial",
          signTransValue: "Transfer value of margin",
          marginRequirement: "Total value of margin request",
          accountRatio: "Rate use asset of margin (%)",
          usedLimitWarning: "Warning - level 1/2/3 (%)",
          marginCall: "Add to money",
        },
      },
      table: {
        positionOpen: {
          title: "Open position",
          rowNum: "ID",
          seriesID: "Contract code",
          dueDate: "Duedate",
          long: "Close_position",
          short: " short position",
          net: "net position",
          bidAvgPrice: "Buy_tb",
          askAvgPrice: "sell_tb",
          marketPrice: "Market Price",
          tradingPLPerFloatingPL: "PROFIT/LOSS",
        },
        positionClose: {
          title: "Close_position",
          rowNum: "ID",
          seriesID: "Contract code",
          long: "Close_position",
          net: "Net position",
          bidAvgPrice: "Buy_tb",
          askAvgPrice: "Sell_tb",
          tradingPLPerFloatingPL: "PROFIT/LOSS",
        },
      },
    },
    charts: {
      beta: "(BETA)",
      betaText1: "Dữ liệu HQDT được thống kê từ 23/11/2021",
      betaText2:
        "TSSL tính theo công thức Time-Weighted Rate(TWR) = {End NAV - (Begin NAV + Cash Flow)}/(Begin NAV + Cash Flow)",
      betaText3:
        "Công thức chỉ mang tính chất tham khảo, Chúng tôi không chịu trách nhiệm về các quyết định của Khách hàng khi dựa vào số liệu này",
      navLineChart: {
        title: "NET ASSET VALUE",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Account",
        growth: "Growth:",
        nav: "NAV:",
      },
      investLineChart: {
        title: "INVESTMENT EFFICIENCY",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Account",
        indexLabel: "Index",
        profitLabel: "Profit rate (PR)",
        profitLabelShort: "PR",
        idxVal: "VN-Index",
        percentDelta: "NAV",
      },
      assetDistributionChart: {
        title: "PHÂN BỔ TỔNG TÀI SẢN",
        totalCashBalance: "Tiền",
        totalShareValue: "Cổ phiếu",
        totalCWValue: "Chứng quyền",
        bondValue: "Trái phiếu",
        capValue: "Hợp tác kinh doanh",
        fsTotalAssets: "Phái sinh",
      },
      debtAndNavChart: {
        title: "NỢ VÀ TÀI SẢN RÒNG",
        debt: "Nợ",
        nav: "Tài sản ròng",
      },
      tickerRateChart: {
        title: "TỶ TRỌNG MÃ CỔ PHIẾU",
      },
      indexRateChart: {
        title: "TỶ TRỌNG NHÓM CHỈ SỐ",
        account: "Tài khoản",
        ticker: "VN-Index",
      },
      industryRateChart: {
        title: "TỶ TRỌNG NGÀNH",
        account: "Tài khoản",
        ticker: "VN30",
      },
      unit: "Unit: Million VND",
    },
  },
  onlineService: {
    careService: "Customer service",
    authenticationMethod: "Accurate",
  },
  alertSettings: {
    modalTitle: "Warning setting",
    symbolWarning: "Please choose code to waring setting",
    setAlert: {
      tabTitle: "Warning setting",
      volume: "Amount",
      ceiling: "Ceiling",
      floor: "Floor",
      referenceShort: "REF",
      priceMatchGTE: "Match price >=",
      priceMatchLTE: "Match price <=",
      overBoughtGTE: "Buy >=",
      overSoldtLTE: "Sell <=",
      priceIncreasePercent: "% Increase price",
      priceDecreasePercent: "% Decrease price",
      timesPerDay: "Repeat",
      fromDate: "From date",
      toDate: "To date",
      createSuccessMsg: "Creat a warning successfully",
      editSuccessMsg: "Edit a warning successfully",
      deleteSuccessMsg: "Delete successfully",
      notExistMsg: "Code isn't exist",
      dateError1: "From date can not exceeded to date",
      dateError2: "From date and to date is not past date",
      indexValUp: "Index >=",
      indexValDown: "Index <=",
      indexChangePerUp: "% Increase Index",
      indexChangePerDown: "% Decrease Index",
      totalQtyUp: "A >= (MILL)",
      totalAmtUp: "Value >= (BILL)",
      totalValue: "VALUE",
      repeatType1: "Once a day",
      repeatType2: "Once a minute",
      repeatType3: "Once a half hour",
    },
    manageAlert: {
      tabTitle: "Warning Manager",
    },
    action: {
      cancel: "Cancel",
      save: "Save",
    },
    emptySymbolError: "The symbol cannot be empty",
  },
  businessCooperation: {
    businessCooperationTab: {
      tableTitle: "Business cooperation",
      filterActions: {
        fromDate: "From date",
        toDate: "To date",
        account: "Account",
        status: "Status",
        search: "Search",
        disapprove: "Pending",
        approved: "Approved",
        liquidated: "Liquidation",
        all: "All",
      },
      table: {
        appendixNumber: "App number",
        accountNo: "account No",
        value: "Capital value",
        period: "period",
        openDate: "OPEN DATE",
        expireDate: "EXPIRED DATE",
        commission: "INTEREST (%/Y)",
        estimatedIncome: "Profit est",
        buyCredit: "Pur_power",
        accountNoLinkBuyCredit: "Pur_power Acc",
        extendMethod: "Extension method",
        introduceStaffName: "Intro",
        introduceStaffCode: "Intro code",
        channel: "Channel",
        status: "Status",
        total: "All",
        liquidation: "Liquidation",
        periodMsg1: "From 1 day to 7 days",
        periodMsg2: "From 8 days to 14 days",
        periodMsg3: "From 15 days to 21 days",
        periodMsg4: "From 22 days to 29 days",
        periodMsg5: "1 month",
        periodMsg6: "2 months",
        periodMsg7: "3 months",
        periodMsg8: "4 months",
        periodMsg9: "5 months",
        periodMsg10: "6 months",
        periodMsg11: "9 months",
        periodMsg12: "1 year",
      },
      doubleClickTooltip: "Double click to change",
    },
    createAppendixCard: {
      cardTitle: "Add Appendix",
      cardInfo: {
        accountNum: "Acc number",
        withDrawCash: "Cash balance",
        period: "Period",
        days: "Date",
        openDate: "Open date",
        expireDate: "Expired date",
        cooperationDays: "Cooperation days",
        capitalValue: "Capital value",
        enterValue: "Enter capital value",
        interestRate: "Interest (%/year)",
        profitEstimate: "Total income",
        calcPurchaseAbility: "Purchase_power",
        linkType0: "Not Purchase_power",
        linkType1: "Purchase_power",
        accountDesignation: "Account",
        accountNoLinkBuyCredit: "Purchase_power Acc",
        extensionMethod: "Extension method",
        extensionType0: "Expand original auto",
        extensionType1: "Expand original and interest auto",
        extensionType2: "Not expand",
        introduceStaffName: "Introducer code",
        introduceStaffCode: "Intro Name",
        enterStaffCode: "Enter code",
        staffCodeError: "Introducer code isn't exist",
        confirmationText:
          "I read and agreed with terms and conditions service at MBS.",
        createSuccessMsg: "Add appendix successfully",
        valueMoreWithdrawCashError:
          "Balance is not enough to make this transaction",
        valueEmpty: "Please enter capital value",
        noteTitle: "NOTE",
        noteText1: `"Real date" > "active date" customer registed cooperate.`,
        noteText2: `You choose priod name and real date to accept interest.`,
        choose: "-----Chọn-----",
      },
      cardActions: {
        cancel: "Cancel",
        agree: "Confirm",
      },
    },
    liquidationModal: {
      modalTitle: "Liquidation",
      accountNum: "Acc number",
      appendixNum: "Appendix no",
      openDate: "Open date",
      expireDate: "Expire date",
      capitalValue: "Capital value",
      liquidationDate: "Liquidate",
      numberDayBorrow: "Date",
      liquidFeeRate: "Interest (%/day)",
      liquidValue: "Liquid Value",
      liquidFee: "Total Income",
      confirmationText:
        "I read and agreed with terms and conditions service at MBS",
      cancel: "Cancel",
      agreeLiquid: "Liquidation",
      liquidSuccessMsg: "Liquid successful",
    },
    interestPercentTable: {
      tableTitle: "Interest",
      feeType: "Interest",
      feeTypeInterest: "Contract",
      feeTypeAuto: "Contract auto",
      feeTypeInterestLiquid: "Early_liquidation",
      customerGroup: "Customer team",
      personnal: "Person",
      organizational: "Organization",
      search: "Search",
      rowNum: "ID",
      period: "PERIOD",
      fromDate: "Issued date",
      fromValue: "From value(VNĐ)",
      toValue: "To value (VNĐ)",
      interestPercent: "Interest (%/year)",
    },
    editModal: {
      modalTitle: "Edit",
      accountNum: "Acc number",
      appendixNum: "Apendix no",
      openDate: "Open date",
      capitalValue: "Capital value",
      calcBuyCredit: "Purchase_power",
      calcBuyCreditAccount: "Purchase_power Acc",
      designationAccount: "Account",
      extensionMethod: "Expand method",
      cancel: "Cancel",
      agreeLiquid: "Update",
      editSuccessMsg: "Edit appendix successful",
    },
    createContractFrameModal: {
      modalTitle: "Register business service contract",
      accountNum: "Acc number",
      openDate: "Open date",
      expireDate: "Expired date",
      confirmationText:
        "I read and agreed with terms and conditions service at MBS.",
      HTKD: "Contract business service",
      DVCalcBuyCredit: "Purchase_power service",
      cancel: "Cancel",
      agree: "Confirm",
      registerSuccessMsg: "Register successful",
    },
  },
  statement: {
    errorDay: "Close date do not the larger to date",
  },
  bonds: {
    cancelBuy: {
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      bondCode: "Mã trái phiếu",
      contractCode: "Số HĐ",
      buyDate: "Ngày GD mua",
      maturityDate: "Ngày kết thúc đầu tư dự kiến",
      termRate: "Lợi suất (%/năm)",
      sellVol: "KL bán",
      price: "Đơn giá",
      contractValue: "Tổng GTGD",
      cancel: "ĐĂNG KÝ HỦY",
      cancelLeft: "Yêu cầu hủy",
      cancelRight: "Hủy bán để nắm giữ đến khi đáo hạn",
      cancelButton: "Hủy bán",
    },
    manageBond: {
      createDateFrom: "Từ ngày",
      createDateTo: "Đến ngày",
      buy: "Mua",
      sell: "Bán",
      DA_GIAO_DICH_MUA: "Đã giao dịch mua",
      DA_GIAO_DICH_BAN: "Đã giao dịch bán",
      CHO_BAN: "Chờ bán",
      contractCode: "SỐ HỢP ĐỒNG",
      transType: "LOẠI GIAO DỊCH",
      vol: "KHỐI LƯỢNG (TP)",
      price: "ĐƠN GIÁ",
      contractValue: "GTGD",
      termRate: "LỢI SUẤT (%/NĂM)",
      buyDate: "NGÀY MUA DỰ KIẾN",
      finishDate: "NGÀY KT DỰ KIẾN",
      sellDate: "THỜI GIAN GIAO DỊCH",
      buyChannel: "KÊNH MUA",
      sellChannel: "KÊNH BÁN",
      accountReceiveBank: "MỞ TẠI",
      statusCode: "TRẠNG THÁI",
      accountReceiveCode: "TK nhận tiền",
    },
    buyBond: {
      rowNum: "STT",
      bondCode: "MÃ TRÁI PHIẾU",
      roomBalance: "KHỐI LƯỢNG TP CÒN LẠI",
      termRate: "LỢI SUẤT ĐÁO HẠN NET (%/NĂM)",
      maturityDate: "NGÀY ĐÁO HẠN",
      timeRemaining: "THỜI GIAN CÒN LẠI (THÁNG)",
      minInvestmentValue: "SỐ TIỀN TỐI THIỂU",
    },
    owningBond: {
      contractValue: "GIÁ TRỊ ĐẦU TƯ",
      cashNonInvestSum: "TỔNG GT ĐẦU TƯ DỰ KIẾN",
      accountReceiveCode: "TK NHẬN TIỀN",
      accountReceiveAddr: "MỞ TẠI",
      channel: "KÊNH ĐẶT LỆNH",
      cancelSell: "HỦY BÁN",
      sell: "BÁN",
    },
    sellBond: {
      HUY_DAT: "Hủy đặt",
      HUY_GIAO_DICH: "Hủy giao dịch",
      KY_THUAT: "Kỹ thuật",
      SUA_GIAO_DICH: "Sửa giao dịch",
      DA_GIAO_DICH: "Đã giao dịch",
      DA_SUA_GIAO_DICH: "Đã sửa giao dịch",
      buyPrice: "GIÁ MUA",
      buyDate: "NGÀY GIAO DỊCH",
      footerTable:
        'Quý khách sử dụng chức năng Bán trái phiếu để thay đổi thời gian đầu tư của HĐ, từ "Tới khi đáo hạn Trái phiếu" sang kỳ hạn ngắn hơn.',
    },
    infoBond: {
      issuerBond: "Tổ chức phát hành",
      bondCode: "Mã trái phiếu",
      parPrice: "Mệnh giá trái phiếu",
      bondNum: "Khối lượng phát hành",
      bondVal: "Tổng giá trị mệnh giá phát hành",
      issuePurpose: "Mục đích phát hành",
      moneyAndPay: "Đồng tiền phát hàng và thanh toán",
      VND: "Đồng Việt Nam",
      termBond: "Kỳ hạn trái phiếu",
      releaseDate: "Ngày phát hành",
      maturityDate: "Ngày đáo hạn",
      interestbonds: "Lãi suất danh nghĩa Trái Phiếu",
      applyInterest: "Lãi suất áp dụng cho ",
      firstInterest: " kỳ tính lãi đầu tiên của Trái Phiếu là ",
      year: " %/năm.",
      applyYear:
        " %/năm.Lãi suất áp dụng cho các kỳ tính lãi tiếp theo của Trái Phiếu sẽ được xác định bằng lãi suất tham chiếu cộng (+) biên độ ",
      datePayment: "Ngày thanh toán lãi",
      couponPayment1: "Tiền lãi Trái phiếu được trả sau, định kỳ mỗi ",
      couponPayment2: " tháng một lần vào ngày cuối cùng của mỗi Kỳ Tính Lãi",
      paymentMethod: "Phương thức thanh toán gốc, lãi Trái phiếu",
      typeOfBond: "Loại hình Trái phiếu",
      releaseForm: "Hình thức Trái phiếu",
      depositAgents: "Đại lý đăng ký lưu ký",
      paymentAgents: "Đại lý thanh toán",
      collateral: "Tài sản đảm bảo",
      collateralAgents: "Đại lý quản lý TSĐB",
      repreOwner: "Đại diện NSH Trái phiếu",
      issuerBond1:
        "Các điều khoản, điều kiện khác của Trái Phiếu theo thông tin nêu tại Bản Công bố thông tin sơ bộ do ",
      ocDate1: " công bố ngày ",
      ocDate2:
        " và các tài liệu khác có liên quan (nếu có) (gọi chung là 'Các Văn Kiện Trái Phiếu')",
    },
    registerBuy: {
      errorVolNull: "Vui lòng nhập khối lượng trái phiếu",
      errorVol0: "Vui lòng nhập khối lượng trái phiếu",
      EXTERNAL_ACCOUNT: "TK Ngân hàng",
      INTERNAL_ACCOUNT: "TK GDCK",
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      termRate: "Lợi suất đáo hạn NET",
      numInvestDate: "Số ngày đầu tư",
      maturityDate: "Ngày đáo hạn TP",
      roomBalance: "Khối lượng TP còn lại",
      infoTrading: "THÔNG TIN GIAO DỊCH",
      accountPay: "TK GDCK",
      cashBalance: "Số dư tiền (VND)",
      buyVolMax: "KL mua tối đa",
      buyVolMin: "KL mua tối thiểu",
      date: "Ngày đăng ký mua",
      buyDate: "Ngày giao dịch mua",
      buyVol: "Khối lượng (TP)",
      enterVol: "Nhập khối lượng",
      buyPrice: "Đơn giá giao dịch",
      buyPrice2: "Giá trị giao dịch",
      buyFee: "Phí chuyển nhượng",
      buyFee2: "Tổng giá trị giao dịch",
      termCodeFuture: "Thời gian đầu tư dự kiến",
      termDate: "Ngày kết thúc đầu tư dự kiến",
      termRate2: "Lợi suất đầu tư NET ",
      detail: "Xem chi tiết dòng tiền đầu tư dự kiến",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      accountReceiveType: "Loại TK",
      accountReceiveCode: "Số TK",
      accountName: "Tên chủ TK",
      bankName: "Mở tại",
      bankBranch: "Chi nhánh",
      infoFrontUser: "THÔNG TIN NHÂN VIÊN TƯ VẤN ",
      optional: "(KHÔNG BẮT BUỘC)",
      contractMgtCode: "Mã NV quản lý",
      enterContractMgtCode: "Nhập mã NV quản lý",
      frontUserName: "Tên NV quản lý",
      distrCustomerCode: "Mã NV tư vấn",
      enterDistrCustomerCode: "Nhập mã NV tư vấn",
      distrCustomerName: "Tên NV tư vấn",
      contractDistrCode: "Sổ HĐ NV tư vấn",
      one: "1. Các điều khoản và Điều kiện mua Trái phiếu",
      two: "2. Đề nghị chuyển nhượng mua Trái phiếu",
      confirm:
        'Tôi xác nhận và đồng ý với "Các Điều khoản và Điều kiện Mua Trái Phiếu", " Đề nghị chuyển nhượng Mua Trái phiếu" đã nêu trên và các Văn kiện Trái Phiếu liên quan',
      registerBuy: "Đăng ký mua",
    },
    investmentDetail: {
      content: "NỘI DUNG",
      payCouponDate: "NGÀY TT",
      cashNonInvest: "SỐ TIỀN DỰ KIẾN NHẬN",
      cashCouponReInvest: "SỐ TIỀN COUPON TÁI ĐẦU TƯ",
      cashReInvest: "LÃI/LỢI SUẤT",
      payCouponDate2: "NGÀY ĐẦU TƯ",
      lastPayCouponDate: "NGÀY KẾT THÚC",
      detail: "Chi tiết dòng tiền đầu tư dự kiến nhận",
      sumCashFlowNonInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowNonInvest: "Lợi suất chưa bao gồm tái đầu tư Coupon",
      flowCashReInvests: "GIẢ ĐỊNH KH TÁI ĐẦU TƯ COUPON NHẬN ĐƯỢC",
      sumCashFlowReInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowReInvest: "Lợi suất đã bao gồm tái đầu tư Coupon ",
      year: " %/năm",
      tooltip1:
        "Tái đầu tư coupon: Là việc Khách Hàng tiếp tục đầu tư khoản coupon nhận được vào các hạng mục đầu tư khác trong thời gian nắm giữ Trái Phiếu (ví dụ gửi tiết kiệm….)",
      tooltip2:
        "Lợi suất đã tái đầu tư: Là lợi suất đầu tư Trái Phiếu Khách Hàng nhận được đã bao gồm tái đầu tư coupon (với giả định Khách Hàng tiếp tục đầu tư khoản coupon với lãi suất 7.2%/năm)",
      tooltip3:
        "Là lợi suất khách hàng thực nhận được trong thời gian đầu tư (MBS đã hỗ trợ các khoản thuế/phí liên quan)",
      dateCoupon: "Ngày thanh toán lãi Coupon",
      tooltipCoupon:
        "COUPON: Là lãi Trái Phiếu (đã trừ thuế thu nhập cá nhân (nếu có)) do Tổ Chức Phát Hành thanh toán. Lãi suất coupon và cách tính lãi coupon căn cứ theo quy định của Tổ Chức Phát Hành đối với Trái Phiếu.",
    },
    referenceRate: {
      termName: "THỜI GIAN ĐẦU TƯ",
      termRate: "LỢI SUẤT (%/NĂM)",
      content: "Bảng lợi suất tham khảo",
      bondCode: "Mã trái phiếu",
      buyValue: "Giá trị đầu tư",
    },
    transferBond: {
      dear: "Kính gửi:",
      mb: " Công ty cổ phần Chứng khoán MB",
      issuerBond:
        "Sau khi thỏa thuận, Bên chuyển nhượng và Bên nhận chuyển nhượng thống nhất thực hiện việc chuyển nhượng Trái phiếu ",
      bondCode1: " - Mã số Trái Phiếu: ",
      bondCode2:
        " từ bên chuyển nhượng sang bên nhận chuyển nhượng theo thông tin được nêu dưới đây:",
      taxValue: "Thuế TNCN (do MBS chi trả)",
      taxValue2: "(Thuế TNCN tạm khấu trừ = Tổng giá trị x 0.1%)",
      content: "ĐỀ NGHỊ CHUYỂN NHƯỢNG MUA TRÁI PHIẾU",
      contentSell: "ĐỀ NGHỊ CHUYỂN NHƯỢNG BÁN TRÁI PHIẾU",
      bondCode: "Mã trái phiếu",
      assignor: "BÊN CHUYỂN NHƯỢNG",
      organizationName1: "Tên tổ chức",
      organizationName2: "CTCP Chứng khoán MB",
      registrationNumber1: "Số ĐKKD",
      registrationNumber2: "116/GP-UBCK do UBCKNN cấp ngày 09/12/2013",
      address1: "Địa chỉ",
      address2: "Tầng M-3-7, Tòa nhà MB, Số 3 Liễu Giai - Ba Đình - Hà Nội",
      representative1: "Người đại diện",
      representative2: "Ông Trần Hải Hà",
      position1: "Chức vụ",
      position2: "Tổng giám đốc",
      transferParty: "BÊN NHẬN CHUYỂN NHƯỢNG",
      fullName: "Tên cá nhân",
      identityCardId: "Số CMND/CCCD",
      issueDate1: "Ngày cấp",
      issuePlace1: "Nơi cấp",
      accountReceiveCode: "Số tài khoản",
      bankName: "Mở tại",
      transferContent: "NỘI DUNG CHUYỂN NHƯỢNG",
      buyVol: "Khối lượng",
      unit: " Trái phiếu",
      parPrice: "Mệnh giá",
      parPrice2: " VND/Trái phiếu",
      buyPrice: "Giá chuyển nhượng",
      valueBuyPrice: "Tổng giá trị chuyển nhượng",
      buyFee: "Phí chuyển nhượng",
      VND: " VND",
      textArea1: "Tổ chức (Ông/ Bà): ",
      textArea2:
        " được ghi tên trên Sổ Đăng Ký và được sở hữu, hưởng mọi quyền lợi và chịu trách nhiệm về số trái phiếu chuyển nhượng nói trên kể từ ngày có xác nhận đăng ký chuyển nhượng của Công ty Cổ phần Chứng khoán MB. Bên nhận chuyển nhượng không chịu ảnh hưởng cũng như không phải chịu trách nhiệm về bất kỳ thỏa thuận liên quan đến Trái Phiếu giữa Tổ Chức Phát Hành và/hoặc Đại Lý Đăng Ký Lưu Ký và/hoặc Bên chuyển nhượng và/hoặc bên thứ ba nào khác mà Bên nhận chuyển nhượng không được thông báo bằng văn bản, ngoại trừ Bản Công Bố Thông Tin, Hợp Đồng Đại lý Đăng Ký Lưu Ký và Hợp đồng mua bán giữa Bên chuyển nhượng và Bên nhận chuyển nhượng.",
      textArea3:
        " ủy quyền cho Công ty Cổ phần Chứng khoán MB xác nhận đăng ký chuyển nhượng theo yêu cầu của các Nhà Đầu Tư (Bên chuyển nhượng, Bên nhận chuyển nhượng). Công ty Cổ phần Chứng khoán MB chỉ xác nhận tính hợp lệ về thủ tục và việc đăng ký quyền sở hữu trái phiếu, không xác nhận việc thanh toán giữa hai bên. Các Bên thừa nhận, trong mọi trường hợp, ",
      textArea4:
        " là đơn vị chịu trách nhiệm thanh toán gốc và/hoặc lãi Trái Phiếu cho Chủ Sở Hữu Trái Phiếu và cam đoan chịu trách nhiệm về tính đầy đủ, hợp pháp của nguồn tiền thanh toán gốc và/hoặc lãi cho Chủ Sở Hữu Trái Phiếu.",
    },
    registerSell: {
      canSell0:
        "Hợp đồng đang sửa đổi, Quý Khách chưa thể thực hiện giao dịch.",
      canSell_1:
        "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch.",
      buyValue: "Giá trị đầu tư (VND)",
      maturityDate: "Ngày kết thúc đầu tư",
      termNonInvest: "Lợi suất NET (%/năm)",
      infoSell: "THÔNG TIN GIAO DỊCH BÁN",
      date: "Ngày đề nghị bán",
      sellDate: "Ngày giao dịch bán",
      termRate: "Lợi suất (%/năm)",
      sellValue: "Tổng giá trị giao dịch (VND)",
      taxPit: "Tỷ lệ thuế TNCN",
      taxValue: "Thuế TNCN (Do MBS chi trả)",
      detail: "Xem chi tiết dòng tiền dự kiến nhận được khi bán TP",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      change: "Thay đổi",
      two: "1. Các điều khoản và Điều kiện bán Trái phiếu",
      three: "2. Đề nghị chuyển nhượng bán Trái phiếu",
      confirm:
        "Tôi xác nhận và đồng ý với Các Điều khoản và Điều kiện Bán Trái Phiếu, Đề nghị chuyển nhượng Bán Trái phiếu đã nêu trên và các Văn kiện Trái Phiếu liên quan",
      registerSell: "Đăng ký bán",
      contractCode: "Mã hợp đồng",
    },
    changeAccountBank: {
      success: "Thay đổi tài khoản nhận tiền thành công",
      changeAccountBank: "Thay đổi tài khoản nhận tiền",
    },
    tab: {
      BUYBONDS: "Mua trái phiếu",
      SELLBONDS: "Bán trái phiếu",
      OWNINGBONDS: "Sở hữu trái phiếu",
      MANAGEBONDS: "Quản lý GD trái phiếu",
      dayCareParams1: "Thời gian được thực hiện Đăng ký bán trong vòng tối đa ",
      dayCareParams2: " ngày làm việc kể từ Ngày GD mua Trái Phiếu.",
      infoTrading: "Thông tin trái phiếu giao dịch",
      registerBuy: "Đăng ký mua trái phiếu",
      registerSell: "Đăng ký bán trái phiếu ",
      cancelSell: "Hủy bán trái phiếu",
    },
    toast: {
      buySuccess: "Đăng ký mua thành công",
      cancelSellSuccess: "Hủy bán thành công",
      sellSuccess: "Đăng ký bán thành công",
    },
  },
  techAnalysis: {
    tab: {
      title: "Tab",
      open: "Open",
      rename: "Rename",
      saveTheTemplate: "Save the template",
    },
    createBoard: {
      chooseABoard: "Choose a Board",
      all: "All",
      default: "Default",
      customized: "Customized",
      newBoard: "New Board",
      descNewBoard: "Adding widggets to an blank board.",
      standard: "Standard",
      descStandard: "For basic and new trader.",
      standard2: "Standard2",
      widgetsSettings: "Widgets Settings",
      suggestionsWidgetsSettings: "Select and drag and drop and board",
      addDisplayArea: "Add display area",
      hoverOverTheAreaToAdd: "Hover over the area to add",
      viewDoesNotExist: "View does not exist",
    },
    modalSaveBoard: {
      titleModal: "Save as a board",
      nameBoard: "Name board",
      description: "Description",
      cancel: "Cancel",
      save: "Save",
    },
    modalRenameBoard: {
      titleModal: "Rename Board",
      ok: "OK",
      cancel: "Cancel",
    },
    widgets: {
      technicalAnalysis: "Technical Analystic",
      priceList: "Step value",
      volumeAndTime: "Volume and time",
      news: "News",
      orderFlowDistribution: "Order flow distribution (Unit: Billion VND)",
      finance: "Financing",
      organizationalInfo: "Business Infomation",
      tickerInfo: "Ticker infomation",
    },
  },
  changeStockStatus: {
    changeStatus: " chuyển trạng thái CK",
    shareStatus: "LOẠI CK",
    shareStatusIn: "TT NHẬN",
    shareVol: "SL CHUYỂN",
    enter: "Chuyển khoản",
    transactionDate: "NGÀY GIAO DỊCH",
    accountOut: "TK CHUYỂN",
    shareVol2: "SỐ LƯỢNG",
    shareStatusOut: "TRẠNG THÁI CHUYỂN",
    shareStatusIn2: "TRẠNG THÁI NHẬN",
    history: "Lịch sử chuyển trạng thái CK",
    changeStatusHeader: "Chuyển trạng thái",
    changeStatusSuccess: "Chuyển trạng thái chứng khoán thành công",
    bonds: "Trái phiếu",
    changeStockStatus: "Chuyển trạng thái chứng khoán",
    fromDateNull: "Quý khách chưa nhập Từ ngày",
    toDateNull: "Quý khách chưa nhập Đến ngày",
  },
  proInvestor: {
    header: {
      registrationHistory: "Lịch sử đăng ký",
      registerPro: "Đăng ký NĐT chuyên nghiệp",
    },
    noti: {
      success: "Thành công",
    },
    button: {
      close: "Đóng",
      search: "Tìm Kiếm",
    },
    popUp: {
      title: "Thông tin nhà đầu tư chuyên nghiệp",
      condition:
        "Theo quy định của Luật chứng khoán số 54/QH2020, từ ngày 1/1/2021, đối tượng mua Trái Phiếu phát hành riêng lẻ phải là nhà đầu tư chứng khoán chuyên nghiệp “NĐTCN”.",
      conditionPeople:
        "Với cá nhân, để là NĐTCN, KH cần đáp ứng 1 trong các tiêu chí sau:",
      coditionCategory:
        "- Sở hữu danh mục chứng khoán niêm yết, đăng ký giao dịch tại các Sở Giao dịch (Theo quy định của Luật Chứng khoán có hiệu lực ngày 1/1/2021) có giá trị tối thiểu là 02 tỷ đồng.",
      conditionFee:
        "- Có thu nhập chịu thuế năm gần nhất tối thiểu là 01 tỷ đồng theo hồ sơ khai thuế đã nộp cho cơ quan thuế hoặc chứng từ khấu trừ thuế của tổ chức, cá nhân chi trả.",
      conditonJob: "- Có chứng chỉ hành nghề chứng khoán.",
    },
    history: {
      number: "STT",
      registDate: "Ngày đăng ký",
      bondCode: "Mã trái phiếu",
      volume: "Khối lượng",
      unitPrice: "Đơn giá",
      transaction: "GT giao dịch",
      purchaseFee: "Phí mua",
      totalValue: "Tổng giá trị",
      status: "Trạng thái",
    },
    form: {
      account: "Tài khoản",
      name: "Họ tên",
      status: "Trạng thái",
      acceptCondition: "Quý KH đã đủ điều kiện trở thành NĐT CN",
      registed: "Quý KH đã đăng ký dịch vụ NĐT CN",
      noInfo: "Quý KH chưa có thông tin NĐT CN tại MBS",
      button: "Trải nghiệm NĐT CN",
    },
    modal: {
      title:
        "ĐK mua Trái phiếu niêm yết từ MBS để có đủ điều kiện xác nhận là NĐT CN:",
      idAccount: "Số tài khoản",
      regisDate: "Ngày ĐK",
      valueMine: "GT DMCK sở hữu",
      valueBone: "GT cần mua trái phiếu",
      moneyBalance: "Số dư tiền",
      codeBone: "Mã trái phiếu",
      release: "TCPH",
      info: "GT cần mua TP = 2 tỷ đồng - GT DMCK sở hữu * Tỷ lệ chiết khấu theo chính sách MBS từng thời kỳ.",
      transactionInfo: {
        title: "Thông tin giao dịch",
        dayTrading: "Ngày GD",
        price: "Đơn giá",
        volume: "Số lượng",
        valueTransaction: "Gía trị GD",
        feeTransaction: "Phí GD",
        tax: "Thuế bán + phí CK",
        sumTransaction: "Tổng tiền GD",
        account: "TK cắt tiền",
        moneyBalance: "Số dư tiền",
        buyTransaction: "GD MUA",
        sellTransaction: "GD BÁN",
      },
      footer: {
        note: "Ghi chú:",
        conditionMoney:
          "- Để trở thành NĐT CN, Quý khách cần nắm giữ danh mục CK NY từ 02 tỷ đồng.",
        conditionDate:
          "- Sau 1 ngày làm việc kể từ ngày GD Mua Trái phiếu thành công, Quý khách sẽ được MBS xác nhận là NĐT CN.",
        conditionAccept:
          "- Sau 02 ngày làm việc kể từ ngày thực hiện GD Mua thành công, hệ thống sẽ tự động Bán lại toàn bộ Trái phiếu mà Quý khách đã   Mua trong giao dịch này!",
        conditionInTime:
          "- Trong thời gian đăng ký trải nghiệm NĐTCN tại MBS, kính đề nghị Quý KH không bán chứng khoán niêm yết trên tài khoản để đảm bảo duy trì số dư khi MBS xác nhận KH đủ điều kiện trở thành NĐTCN.",
        register: "Đăng ký",
      },
      error: {
        noVolume: "Vui lòng nhập số lượng",
        min: "Vui lòng nhập số lượng",
        room: "Hiện tại đã hết Hạn mức giao dịch Nhà đầu tư chuyên nghiệp. Quý Khách vui lòng đăng ký lại sau.",
      },
      placeHolder: {
        input: "Nhập số lượng",
      },
    },
  },
  textConfirmLoanExtension:
    "Tôi đã đọc và đồng ý với các điều khoản thỏa thuận trong hợp đồng ký quỹ, phụ lục đồng và các chính sách dịch vụ tài chính đang áp dụng tại MBS",
  toast: {
    success: "Notice",
    warning: "Warning",
    error: "Error",
    default: "Notice",
  },
  smartOtp: {
    alert: "Vui lòng mở ứng dụng MBS Mobile và lấy mã SmartOTP",
  },
  statusWarrant: {
    QUYEN_CHUA_THUC_HIEN: "Quyền chưa thực hiện",
    QUYEN_CHO_VE: "Quyền chờ về",
  },
  registerCKService: {
    title: "Đăng ký tài khoản dịch vụ Phái sinh",
    confirm:
      "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện về Giao dịch chứng khoán phái sinh tại MBS.",
  },
  cleanCache: {
    title: "Xóa cache",
    contentConfirm: "Bạn chắc chắn muốn xóa bộ nhớ tạm thời",
  },
  tooltipAccountReceiveCode: "Kích để thay đổi TK nhận tiền",
  modalTransferMoney: {
    sidebar: "HD chuyển tiền vào TKCK",
    headerTitle: "HƯỚNG DẪN CHUYỂN TIỀN VÀO TKCK",
    TAB_MB: "Từ NH MB",
    TAB_FAST: "Siêu nhanh - 9612",
    TAB_FAST_MBS: "Siêu nhanh-MBS",
    TAB_NORMAL: "Chuyển thường",
    toastSuccessNumber: "Đã sao chép số tài khoản chuyển tiền",
    toastSuccessContent: "Đã sao chép nội dung chuyển tiền",
    TabFastTextRed:
      "Quý khách có TK tại các NH khác MB/BIDV, sử dụng DV Ngân hàng điện tử trên web/app để chuyển tiền vào TKCK tại MBS 24/7, tự động hạch toán như dưới đây:",
    step1: "Bước 1:",
    TabFastStep1: "Đăng nhập app/web Ngân hàng chọn Chuyển tiền",
    TabFastStep2_1: "Thông tin Người nhận chọn:",
    TabFastStep2_2: "Ngân hàng: Đầu tư phát triển - BIDV",
    TabFastStep2_3: "Số Tài khoản: 9612+ Số TKCK 7 số (tiểu khoản tại MBS)",
    TabFastStep2_4: "Họ tên: Tự động hiển thị tên theo số TKCK nhập ở trên",
    TabFastStep2_5: "Số tiền: Nhập số tiền muốn chuyển vào TKCK",
    TabFastStep2_6: "Ví dụ: ",
    TabFastStep2_7: "Nhập Số tài khoản nhận ",
    TabFastStep2_8: "khi nộp tiền vào TKCK",
    TabFastStep2_9: "- Nộp tiền vào tài khoản thường (tiểu khoản 1): ",
    TabFastStep2_10: "- Nộp tiền vào tài khoản Margin (tiểu khoản 8): ",
    TabFastStep2_11: "- Nộp tiền vào tài khoản M-Credit (tiểu khoản 7): ",
    TabFastStep2_12: "(abcdef là số TKCK 6 số của Quý khách tại MBS)",
    step2: "Bước 2:",
    step3: "Bước 3:",
    TabFastStep3_1: "Xác nhận OTP và ấn Tiếp tục",
    step4: "Bước 4:",
    TabFastStep4_1: "Giao dịch thành công, TKCK nhận được tiền ngay",
    note: "Nếu Quý Khách có tài khoản tại BIDV, Quý khách chọn mục “Thanh toán”, chọn chức năng “Nộp tiền chứng khoán” và nhập thông tin thụ hưởng: Công ty CP Chứng khoán MB và số tài khoản 9612+TKCK 7 số của Quý khách như trên.",
    step5: "Bước 5:",
    TabFastMBSTextRed:
      "Sử dụng mã định danh “MBS” qua kết nối MBBank để chuyển tiền vào TKCK tại MBS từ các Ngân hàng có liên kết Napas, Quý khách sẽ nhận được tiền ngay lập tức 24/7 với thao tác đơn giản như sau:",
    TabFastMBSStep1:
      "Đăng nhập app/web Ngân hàng (khác MBBank) chọn Chuyển tiền",
    TabFasMBStStep2_1: "Thông tin Người nhận chọn:",
    TabFastMBSStep2_2: "-	Ngân hàng nhận: Ngân hàng TMCP Quân đội (MB/ MBBank)",
    TabFastMBSStep2_3:
      "-	Số Tài khoản: MBS + Số TKCK 7 số (bao gồm cả tiểu khoản tại MBS)",
    TabFastMBSStep2_4: "-	Họ tên: Tự động hiển thị tên theo số TKCK nhập ở trên",
    TabFastMBSStep2_5: "-	Số tiền: Nhập số tiền =< 500 triệu đồng/ giao dịch",
    TabMBTextRed:
      "Quý khách vui lòng đăng nhập vào App MBBank và làm theo hướng dẫn:",
    TabMBStep1_1: "Chọn mục Sản phẩm",
    TabMBStep2_1: "Chọn Bảo hiểm, Chứng khoán và Vay tiêu dùng",
    TabMBStep3_1: "Chọn Chứng khoán MB",
    TabMBStep4_1: "Chuyển tiền đến tài khoản MBS",
    TabMBStep5_1: "Thêm (+) Người thụ hưởng mới và nhập thông tin",
    detail: "Xem chi tiết hướng dẫn ",
    here: "tại đây",
    TabNormalTextRed:
      "Quý khách lưu ý chuyển khoản đúng nội dung hướng dẫn để tiền được hạch toán tự động vào TKCK tại MBS",
    left: "Người thụ hưởng",
    right: "Công ty CP Chứng khoán MB",
    title: "Chuyển khoản tới một trong các tài khoản sau",
    bottom1: "MBBank - CN Ba Đình",
    bottom2: "MBBank - CN Hàm Nghi",
    bottom3: "Vietcombank - CN Hà Nội",
    bottom4: "BIDV - CN Hà Thành",
    bottom5: "Techcombank - CN Hội Sở",
    content: "NỘI DUNG CHUYỂN KHOẢN",
    top1: "Nộp tiền vào TK thường (đuôi 1)",
    top8: "Nộp tiền vào TK Margin (đuôi 8)",
    top7: "Nộp tiền vào TK Margin (đuôi 7)",
    bottom6: "Nop tien TKCK ",
    bottom7: "1 cua ",
    bottom8: "8 cua ",
    bottom9: "7 cua ",
  },
  saveQRSuccess: "Quét QR code thành công",
  saveOtpSuccess: "Đã lưu mã xác thực",
  smartOTP: {
    success: "Gửi mã OTP thành công",
    title1: "Quý khách vui lòng ",
    title2: "Lấy mã OTP",
    title3: " qua sms/email và lưu tại ô dưới đây",
    title4: "Quý khách đã lấy OTP thành công, vui lòng kiểm tra sms/email.",
  },
  industry: {
    name: "Chuyển động ngành",
    topIndex: "TOP đóng góp INDEX",
    transactionIndustry: "GD theo ngành",
  },
  foreignWatch: {
    topStocks: "TOP cổ phiếu mua bán ròng",
    topIndustry: "TOP ngành mua bán ròng",
  },
  modalSell: {
    shareStatus: "Trạng thái CK",
    shareOddLot: "CP lẻ",
    sellValue: "Giá trị bán",
    confirm1: "Tôi đã đọc và đồng ý với ",
    confirm2: "các điều khoản và điều kiện",
    confirm3:
      " về Giao dịch lô lẻ trực tuyến của MBS và xác nhận đăng ký dịch vụ.",
    note: "Ghi chú: giao dịch lô lẻ với giá sàn, phí dịch vụ 10% tổng giá trị giao dịch từ 23/07/2021",
  },
  modalSVD: {
    title1: "Trân trọng chào mừng Quý Khách hàng đến với MBS!",
    title2:
      "Kính chúc Quý khách có trải nghiệm giao dịch vượt trội và nhận được những ưu đãi hấp dẫn.",
    acceptText1: "Tôi đã đọc, hiểu và đồng ý với",
    acceptText2: " Bộ các điều khoản và điều kiện ",
    acceptText3: "Mở tài khoản và sửa dụng dịch vụ tại MBS",
    onSubmit: "TIẾP TỤC",
  },
  copi24: {
    tab: {
      TOPLEADER: "Top Leader",
      FOLLOW: "Theo dõi",
      MANAGECOPY: "Quản lý sao chép",
      HISTORY: "Lịch sử đầu tư",
    },
    modalCreateCashPayment: {
      valueAmount0: "Số tiền nộp phải > 0",
      cashBalance: "Số tiền nộp không được vượt quá Số dư tài khoản Cash",
      cashPayment: "Nộp tiền",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      amount: "Số tiền nộp",
      placeholder: "Nhập số tiền",
      noteRed:
        "Ghi chú: Sau khi nộp thêm tiền, đầu ngày giao dịch tiếp theo hệ thống sẽ tự động renew lại danh mục theo tỷ trọng Leader.",
      cancel: "Hủy",
    },
    modalCreateCashWithdraw: {
      valueAmount0: "Số tiền rút phải > 0",
      maximumCashWithdraw0: "Lớn hơn số tiền rút tối đa",
      cashWithdraw: "Rút tiền",
      account: "Tài khoản Copi24",
      totalAsset: "NAV theo giá hiện tại",
      navBasic: "NAV theo giá tham chiếu",
      maximumCashWithdraw: "Số tiền rút tối đa",
      amount: "Số tiền rút",
      placeholder: "Nhập số tiền",
      cancel: "Hủy",
    },
    modalCreateCopy: {
      valueAmount0: "Giá trị sao chép phải > 0",
      cashBalance: "Giá trị sao chép không được vượt quá Số dư tài khoản Cash",
      COPY_MIN_AMOUNT0:
        "Giá trị sao chép không được nhỏ hơn giá trị sao chép tối thiểu",
      secPerNavRate0: "Bạn chưa nhập Tỷ trọng tối đa 1 mã",
      priceMaxPercent0: "Bạn chưa nhập Giá đặt chênh lệch tối đa",
      stopLossPercent0: "Bạn chưa nhập Danh mục lỗ",
      stopProfitPercent0: "Bạn chưa nhập Danh mục lãi",
      optionPriceType1: "Lệnh thị trường",
      optionPriceType2: "Lệnh LO",
      rule: "Quy tắc sao chép",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      COPY_MIN_AMOUNT: "Giá trị sao chép tối thiểu",
      amount: "Giá trị sao chép (VND)",
      ALLOW_CHOOSE_PRICE_TYPE: "Nguyên tắc giá sinh lệnh",
      priceBuyPercent: "Chênh lệch giá MUA (%)",
      priceSellPercent: "Chênh lệch giá BÁN (%)",
      secPerNavRate: "Tỷ trọng tối đa 1 mã (%)",
      textLine1: "Lệnh sao chép phát sinh khi",
      textLine2: "Lệnh Leader khớp",
      isCopyCurrentPortfolio: "Sao chép DM hiện tại",
      stop: "Điều kiện lệnh dừng sao chép",
      stopLossPercent: "% Biến động tài sản giảm",
      stopProfitPercent: "% Biến động tài sản tăng",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      cancel: "Hủy",
      copy: "Sao chép",
    },
    modalStopCopy: {
      title: "Dừng sao chép",
      account: "Tài khoản Copi24",
      amount: "Giá trị sao chép",
      profitAmt: "Lãi/lỗ",
      feeCopy: "Phí sao chép",
      total: "Tổng giá trị",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      confirm: "Xác nhận chuyển toàn bộ danh mục sang tài khoản 1",
      cancel: "Hủy",
      stop: "Dừng sao chép",
    },
    modalUpdateAvatar: {
      title: "Cập nhật ảnh đại diện",
      error: "Ảnh đại diện của bạn không được vượt quá xxx Kbs",
      cancel: "Đóng",
      confirm: "Xác nhận",
    },
    modalCancelCopier: {
      title: "Đóng COPIER",
      confirm: "Bạn có chắn chắn muốn đóng Copier?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    modalCancelLeader: {
      toastSuccess:
        "Yêu cầu đóng leader đã được gửi tới hệ thống. Yêu cầu của quý khách sẽ được xử lý trong {day} ngày tới",
      title: "Đóng LEADER",
      confirm: "Bạn có chắn chắn muốn đóng Leader?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    cardUser: {
      month: " /tháng",
      month6: " trong 6 thángg",
      portfolioPoint: "Điểm danh mục: ",
      totalFollow: "Tổng theo dõi:",
      totalCopy: "Tổng sao chép:",
      copy: "Sao chép",
      stop: " Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    topLeader: {
      topLeader: "TOP LEADER",
      registerCopier: "Đăng ký COPIER",
      cancelCopier: "Đóng COPIER",
      delay: "Chờ xử lý đóng",
      registerLeader: "Đăng ký LEADER",
      cancelLeader: "Đóng LEADER",
      stringReturn1: "Hiệu quả 1 tháng",
      stringReturn3: "Hiệu quả 3 tháng",
      stringReturn6: "Hiệu quả 6 tháng",
      filterLeaderAccountName: "Tên Leader",
      placeholder: "Nhập tên Leader",
      fetchDataReset: "Reset",
      registerCopierSuccess: "Đăng kí Copier thành công",
      cancelCopierSuccess: "Gửi yêu cầu Đóng Copier thành công",
      registerLeaderSuccess: "Gửi yêu cầu Đăng ký leader thành công",
      followSuccess: "Follow thành công",
      cancelFollowSuccess: "Hủy follow thành công",
      INDIVIDUALERR: "Sản phẩm dành riêng khách hàng Cá nhân",
    },
    registerCopier: {
      registerCopier: "Đăng ký COPIER",
      customerId: "Tài khoản",
      confirm:
        "Tôi đã đọc, hiểu và đồng ý với điều khoản & điều kiện của sản phẩm. Sản phẩm có tiềm ẩn rủi ro đối với các khách hàng thuộc diện phải công bố thông tin theo quy định của Pháp luật, khách hàng lưu ý cân nhắc trước khi đăng ký sử dụng.",
      cancel: "Hủy",
      rules: "điều khoản & điều kiện",
    },
    registerLeader: {
      disableButtonRegister: " của tài khoản không đáp ứng giá trị tối thiểu",
      registerLeader: "Đăng ký LEADER",
      accountRegister: "Tài khoản đăng ký ban đầu:",
      confirm:
        "Tôi đã đọc, hiểu và đồng ý với điều khoản & điều kiện của sản phẩm. Sản phẩm có tiềm ẩn rủi ro đối với các khách hàng thuộc diện phải công bố thông tin theo quy định của Pháp luật, khách hàng lưu ý cân nhắc trước khi đăng ký sử dụng.",
      accountName: "Tên hiển thị",
    },
    tableRegisterLeader: {
      accountRegisterErr: "Quý khách vui lòng nhập Tài khoản đăng ký",
      accountNameErr: "Quý khách vui lòng nhập Tên hiển thị",
      navValue: "Tài sản ròng",
      tradeMonthNo: "Số tháng đã giao dịch",
      tradingDiaryRate1: "Hiệu quả đầu tư (",
      tradingDiaryRate2: " tháng)",
      portfolioPoint: "Điểm số danh mục",
      title: "TIÊU CHÍ",
      valueMin: "GIÁ TRỊ TỐI THIỂU",
      valueAccount: "GIÁ TRỊ CỦA TÀI KHOẢN",
    },
    topFollow: {
      listTopFollow: "DANH SÁCH THEO DÕI",
    },
    manager: {
      popoverProfitAmt:
        "Biến động tài sản = Tiền mặt còn lại - Tiền mua chưa thanh toán + Tiền bán chờ thanh toán + Giá trị chứng khoán - Giá trị đầu tư",
      total_amount: "Tổng giá trị đầu tư: ",
      total_profit_amt: "Lãi/lỗ:",
      total_value: "Tổng giá trị:",
      leaderAccoStat: "Tài khoản leader đã đóng hoặc đang xử lý đóng",
      amount: "Giá trị đầu tư",
      beginTotalAsset: "NAV đầu ngày",
      nav: "NAV theo giá thị trường",
      profitAmt: "Biến động tài sản",
      profitPercent: " % Biến động tài sản",
      totalFeeDebt: "Phí sao chép",
      createCashPayment: " Nộp tiền",
      withdrawMoney: "Rút tiền",
      stopCopy: "Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    history: {
      tradeDate: "NGÀY SAO CHÉP",
      leader: "LEADER",
      amount: "GIÁ TRỊ ĐẦU TƯ",
      profitAmt: "LÃI LỖ",
      profitPercent: "% LÃI LỖ",
      stopDate: "NGÀY DỪNG SAO CHÉP",
    },
    portTransaction: {
      popover:
        "Tiếp tục tính toán Lãi lỗ danh mục đến khi tài khoản dừng sao chép thành công",
      tradeDate: "NGÀY GIAO DỊCH",
      secCd: "MÃ CK",
      tradeType: "LOẠI GD",
      profitAmt: "LÃI/LỖ DANH MỤC",
      profitAmtPercent: "% LÃI/LỖ DANH MỤC",
      profitPercent: "% LÃI LỖ",
      qty: "KHỐI LƯỢNG",
      price: "GIÁ",
      avgPrice: "GIÁ VỐN",
      feeAmt: "PHÍ",
      taxAmt: "PHÍ THUẾ KHÁC",
      history: "Lịch sử giao dịch",
      remarks: "GHI CHÚ",
      ratio: "TỈ TRỌNG",
      totalQty: "TỔNG",
      availableQty: "KHẢ DỤNG",
      waitingReceive: "CHỜ NHẬN",
      avgPrice2: "GIÁ TB",
      currentPrice: "GIÁ HIỆN TẠI",
      buyAmt: "GIÁ TRỊ MUA",
      currentAmt: "GIÁ TRỊ HIỆN TẠI",
      tabRule1: "DANH MỤC SAO CHÉP",
      tabRule2: "QUY TẮC SAO CHÉP",
      priceMaxPercent: "Giá Mua chênh tối đa với DM hiện tại (%)",
      tradeDate2: "Ngày sao chép",
    },
    modalDetailLeader: {
      title: "Chi tiết Leader",
      titleUppercase: "CHI TIẾT LEADER",
      percentDeltaFormatter: "Hiệu quả đầu tư",
      standardDeviationChart: "LÃI LỖ DANH MỤC",
      tickerRateChart: "PHÂN BỔ THEO DANH MỤC",
    },
    modalDetailCopier: {
      title: "Danh mục Copier",
      titleUppercase: "DANH MỤC COPIER",
      compareWithLeaderChart: "HIỆU QUẢ ĐẦU TƯ LEADER SO VỚI COPIER",
      tableTicker: "DANH MỤC LEADER",
    },
  },
  openPosition: {
    orderType: "Loại hợp đồng",
    coefficient: "Hệ số",
    valuePosition: "Giá trị vị thế mở",
  },
  policy: {
    tabHistory: "Lịch sử đăng ký",
    tabRegister: "Đăng ký chính sách ưu đãi",
    all: "Tất cả",
    INIT: "Khởi tạo",
    PROCESS: "Đang xử lý",
    APPROVE: "Đã duyệt",
    REJECT: "Từ chối",
    ERROR: "Không thành công",
    policyCode: "Chính sách",
    status: "Trạng thái",
    proposalDate: "NGÀY ĐĂNG KÝ",
    policyName: "CHÍNH SÁCH",
    preferentialFeeName: "TÊN BIỂU ƯU ĐÃI",
    preferentialFeeValue: "GIÁ TRỊ",
    expiredDate: "THỜI GIAN HIỆU LỰC",
    status2: "TRẠNG THÁI",
    approvalDate: "NGÀY DUYỆT",
    duration: "TG HIỆU LỰC",
    day: "(ngày)",
    register: "Đăng ký",
    registerSuccess: "Đăng ký thành công, yêu cầu của Quý khách đang chờ xử lý",
  },
};

export default en;
