import financeHttp from "src/core/http/singleton/financeService";
import { GET_LIST_REPORT_SYNTHETIC } from "./urls";
import { ReportSyntheticParams } from "./types";
import { ResponseReportSynthetic } from "src/domain/models/reportSynthetic";

//lay chi tiet danh muc dau tu
export const getListReportSyntheticApi = async (
  params: ReportSyntheticParams
): Promise<ResponseReportSynthetic[]> => {
  const res = await financeHttp.get<ResponseReportSynthetic[]>(
    GET_LIST_REPORT_SYNTHETIC,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
