import financeHttp from "src/core/http/singleton/financeService";
import {
  LIST_FINANCE_SERVICE,
  POST_FINANCE_SERVICE,
  POST_LIST_GDKHQ,
  deleteFinancialsSuspend,
  financialsSuspend,
} from "./urls";
import {
  DeleteFinancialsSuspendParams,
  FilterFinanceServiceItem,
  FinanceServiceAddItem,
  PostFinancialsSuspendParams,
  PostGDKHQParams,
} from "./types";

export const getFinanceServiceApi = async (
  params: FilterFinanceServiceItem
): Promise<any> => {
  const res = await financeHttp.get<any>(LIST_FINANCE_SERVICE, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postFinanceServiceApi = async (
  params: FinanceServiceAddItem[]
): Promise<any> => {
  const res = await financeHttp.post<any>(POST_FINANCE_SERVICE, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postFinancialsSuspendApi = async (
  params: PostFinancialsSuspendParams
): Promise<any> => {
  const res = await financeHttp.post<any>(financialsSuspend, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteFinancialsSuspendApi = async (
  params: DeleteFinancialsSuspendParams
): Promise<any> => {
  const res = await financeHttp.delete<any>(
    deleteFinancialsSuspend(params.shareCode)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postListGDKHQApi = async (
  params: PostGDKHQParams[]
): Promise<any> => {
  const res = await financeHttp.post<any>(POST_LIST_GDKHQ, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
