import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const dividendFilterState = (state: RootState) =>
  state.managementDividend.dividendFilterSlice;
export const dividendFilterSelector = createSelector(
  dividendFilterState,
  (state) => ({
    filter: state.data.filter,
    dividends: state.data.dividends,
    totalRecord: state.data.totalRecord,
  })
);
export const dividendCreateState = (state: RootState) =>
  state.managementDividend.dividendCreateSlice;
export const dividendDeleteState = (state: RootState) =>
  state.managementDividend.dividenDeleteSlice;
export const dividenUpdateState = (state: RootState) =>
  state.managementDividend.dividenUpdateSlice;
