import { memo, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import SidebarLayout from "src//layouts/SidebarLayout";
import SuspenseLoader from "src/components/SuspenseLoader";
import { styled } from "@mui/material/styles";
import { roles } from "src/helper/const";
import Auth from "src/modules/Auth/pages/Login";
import { auth } from "src/modules/Auth/redux/selector";
import { ReduxStateType } from "src/redux-store/types";
import routesApp from "src/routes/routesApp";
import { createSelector } from "@reduxjs/toolkit";
const version = process.env.REACT_APP_VERSION;
const VersionWrap = styled("div")`
  color: grey;
  position: absolute;
  bottom: 0px;
  right: 5px;
`;

const authSelector = createSelector(auth, (state) => ({
  statusAuth: state.status,
  rolesAccount: state.dataAuth.roles,
}));

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
function AppRouter() {
  const { statusAuth, rolesAccount } = useSelector(authSelector);
  // khi logout sẽ bắn ra ngoài màn login
  return (
    <div>
      {statusAuth === ReduxStateType.SUCCESS ? (
        <SidebarLayout>
          <Routes>
            {/* Page ko có header, sideBar thì đặt ở đây */}
            {/* <Route path="abc" component={NotFoundPage} /> */}
            {routesApp.map((route, index) => {
              const { roleSide, page } = route;
              const Element = Loader(page);
              switch (rolesAccount[0]) {
                case "ROLE_ADMIN":
                  return <Route key={index} {...route} element={<Element />} />;
                case "ROLE_EDITOR":
                  return roleSide === roles.ROLE_EDITOR ||
                    roleSide === roles.ROLE_USER ? (
                    <Route key={index} {...route} element={<Element />} />
                  ) : null;
                case "ROLE_USER":
                  return roleSide === roles.ROLE_USER ? (
                    <Route key={index} {...route} element={<Element />} />
                  ) : null;
                default:
                  return null;
              }
            })}
          </Routes>
          <VersionWrap>{version}</VersionWrap>
        </SidebarLayout>
      ) : (
        <Auth />
      )}
    </div>
  );
}

export default memo(AppRouter);
