export const errorCodeNew = {
  60002: "Sai otp",
  60003: "Chưa đăng nhập",
  60100:
    "không thể đặt lệnh tự động, Quý khách vui lòng liên hệ admin để đc hỗ trợ !",
  60101: "Đặt quá nhanh, 2 lệnh phải cách nhau 5s",
  60102: "Không đủ sức mua",
  60103: "Không đủ khối lượng bán",
  60104: "Không tìm thấy lệnh",
  60105: "Không phải lệnh đặt tay",
  60106: "Không tìm thấy kho đặt lệnh",
  99999: "internal exception",
};
