import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { regisOtp2FAApi } from "src/core/http/service/auth";
import {
  postRegisOtp2FAFailed,
  postRegisOtp2FAStart,
  postRegisOtp2FASuccess,
} from "../redux/postRegisOtp2FA";
import { accountInfoStaticSelector } from "src/redux-store/commons/staticInfo/redux/selector";
import { getCheckOtpStatusStart } from "../redux/getCheckOtpStatus";
import { RegisOtp2FAParams } from "src/core/http/service/auth/types";

function* postRegisOtp2FAStartWork(action) {
  const { accountCodeStatic } = yield select(accountInfoStaticSelector);
  const { payload } = action;
  let params: RegisOtp2FAParams = {
    accountCode: accountCodeStatic,
    ...payload,
  };

  try {
    yield call(regisOtp2FAApi, params);
    yield put({
      type: postRegisOtp2FASuccess.type,
    });
    toast.success("Chuyển trạng thái thành công");
    yield put({
      type: getCheckOtpStatusStart.type,
    });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: postRegisOtp2FAFailed.type,
    });
  }
}

function* watchPostRegisOtp2FA() {
  yield takeLatest(postRegisOtp2FAStart.type, postRegisOtp2FAStartWork);
}

export default function* postRegisOtp2FASaga() {
  yield all([watchPostRegisOtp2FA()]);
}
