import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const switchControl = (state: RootState) =>
  state.stockTrading.switchControl;
export const formOrder = (state: RootState) => state.stockTrading.formOrder;
export const reportBuyingPower = (state: RootState) =>
  state.stockTrading.reportBuyingPower;
export const getBuyInfo = (state: RootState) => state.stockTrading.getBuyInfo;
export const shareWithdraw = (state: RootState) =>
  state.stockTrading.shareWithdraw;
export const orderTrading = (state: RootState) =>
  state.stockTrading.orderTrading;
export const listAccountsStatic = (state: RootState) =>
  state.staticInfo.listAccountsStatic;
// ====================
const listOrders = (state: RootState) => state.stockTrading.listOrders;
export const listOrdersSelector = createSelector(listOrders, (state) => ({
  dataListOrders: state.data,
}));
export const orderCancel = (state: RootState) => state.stockTrading.orderCancel;
export const orderCancelSelector = createSelector(
  orderCancel,
  (state) => state
);

// ====================
export const portfolioAccounts = (state: RootState) =>
  state.stockTrading.portfolioAccounts;
const filterOrder = (state: RootState) => state.stockTrading.filterOrder;
export const filterOrderSelector = createSelector(
  filterOrder,
  (state) => state
);
