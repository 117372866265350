import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
const initialState = {
  optionslistSharesStaticB: [],
  optionslistSharesStaticS: [],
  status: ReduxStateType.INIT,
};

const listSharesStatic = createSlice({
  name: "listSharesStaticSlice",
  initialState,
  reducers: {
    listSharesStaticStart: (state, action: PayloadAction<boolean>) => {
      state.status = ReduxStateType.LOADING;
    },
    listSharesStaticBuySuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.optionslistSharesStaticB = action.payload;
    },
    listSharesStaticSellSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.optionslistSharesStaticS = action.payload;
    },
    listSharesStaticFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  listSharesStaticStart,
  listSharesStaticBuySuccess,
  listSharesStaticSellSuccess,
  listSharesStaticFailed,
} = listSharesStatic.actions;

export default listSharesStatic.reducer;
