import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
  cancleIDs: Array<string | number>;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
  cancleIDs: [],
};

const orderCancelHand = createSlice({
  name: "orderCancelHandSlice",
  initialState,
  reducers: {
    orderCancelHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    orderCancelHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    orderCancelHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    updateCancleIDs: (state, action) => {
      state.cancleIDs = action.payload;
    },
    resetCancleIDs: () => initialState,
  },
});

export const {
  orderCancelHandStart,
  orderCancelHandSuccess,
  orderCancelHandFailed,
  updateCancleIDs,
  resetCancleIDs,
} = orderCancelHand.actions;

export default orderCancelHand.reducer;
