import { CssBaseline } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "src/modules/Auth/redux/selector";
import { listAccountsStaticStart } from "src/redux-store/commons/staticInfo/redux/listAccountsStatic";
import AppRouter from "src/routes";
import { resetFormOrder } from "./modules/StockTrading/redux/formOrder";
import { accountInfoStart } from "./redux-store/commons/staticInfo/redux/accountInfo";
import { channelListStaticStart } from "./redux-store/commons/staticInfo/redux/channelListStatic";
import { getAccountInfoStaticStart } from "./redux-store/commons/staticInfo/redux/getAccountInfoStatic";
import { listSharesStaticStart } from "./redux-store/commons/staticInfo/redux/listSharesStatic";
import { ReduxStateType } from "./redux-store/types";

const authSelector = createSelector(auth, (state) => ({
  statusAuth: state.status,
}));
const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { statusAuth } = useSelector(authSelector);
  // hàm effect lấy dữ liệu dùng chung cho các màn
  useEffect(() => {
    if (statusAuth === ReduxStateType.SUCCESS) {
      if (location?.pathname !== "/") {
        navigate(location.pathname);
      } else {
        navigate("/command/stockTrading");
      }
      dispatch(listAccountsStaticStart());
      dispatch(accountInfoStart());
      dispatch(getAccountInfoStaticStart());
      dispatch(channelListStaticStart());
      dispatch(listSharesStaticStart(true));
    } else {
      navigate("/");
    }
    return () => {
      dispatch(resetFormOrder());
    };
  }, [statusAuth]);

  return (
    <>
      <AppRouter />
      <CssBaseline />
    </>
  );
};
export default App;
