import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: any[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const orderSessionConnect = createSlice({
  name: "orderSessionConnectSlice",
  initialState,
  reducers: {
    orderSessionConnectStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    orderSessionConnectSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    orderSessionConnectFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
  },
});

export const {
  orderSessionConnectStart,
  orderSessionConnectSuccess,
  orderSessionConnectFailed,
} = orderSessionConnect.actions;

export default orderSessionConnect.reducer;
