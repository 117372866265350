import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  deleteIDs: Array<string | number>;
  status: ReduxStateType;
}
const initialState: InitialState = {
  deleteIDs: [],
  status: ReduxStateType.INIT,
};

const orderCancel = createSlice({
  name: "orderCancelSlice",
  initialState,
  reducers: {
    orderCancelStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    orderCancelSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    orderCancelFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    resetDeleteIDs: (state) => {
      state.deleteIDs = initialState.deleteIDs;
    },
    updateDeleteIDs: (state, action) => {
      state.deleteIDs = action.payload;
    },
  },
});

export const {
  orderCancelStart,
  orderCancelSuccess,
  orderCancelFailed,
  resetDeleteIDs,
  updateDeleteIDs,
} = orderCancel.actions;

export default orderCancel.reducer;
