import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { FilterFinanceServiceItem } from "src/core/http/service/financeService/types";

export type InitialState = {
  controlModal: {
    isShowModal: boolean;
    startDay: Date;
    stopDate: Date;
    shareCodeEdit: string;
  };
};

const initialState: InitialState = {
  controlModal: {
    isShowModal: false,
    startDay: moment().toDate(),
    stopDate: moment().add(7, "days").toDate(),
    shareCodeEdit: "",
  },
};

const controlFinancialModal = createSlice({
  name: "controlFinancialModalSlice",
  initialState,
  reducers: {
    updateFinanceServiceModal: (
      state,
      action: PayloadAction<FilterFinanceServiceItem>
    ) => {
      state.controlModal = Object.assign(state.controlModal, action.payload);
    },
    resetFinanceServiceModal: (state) => {
      state.controlModal = initialState.controlModal;
    },
  },
});

export const { updateFinanceServiceModal, resetFinanceServiceModal } =
  controlFinancialModal.actions;

export default controlFinancialModal.reducer;
