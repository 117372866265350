import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

export type InitialState = {
  status: ReduxStateType;
};

const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const deleteFinancialsSuspend = createSlice({
  name: "deleteFinancialsSuspendSlice",
  initialState,
  reducers: {
    deleteFinancialsSuspendStart: (state, action) => {
      state.status = ReduxStateType.LOADING;
    },
    deleteFinancialsSuspendSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    deleteFinancialsSuspendFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  deleteFinancialsSuspendStart,
  deleteFinancialsSuspendSuccess,
  deleteFinancialsSuspendFailed,
} = deleteFinancialsSuspend.actions;

export default deleteFinancialsSuspend.reducer;
