import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface ItemChannel {
  key: string | number;
  value: string | number;
  label: string;
}
interface InitialState {
  optionsChannelList: ItemChannel[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  optionsChannelList: [],
  status: ReduxStateType.INIT,
};

const channelDetailHand = createSlice({
  name: "channelDetailHandSlice",
  initialState,
  reducers: {
    channelDetailHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    channelDetailHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.optionsChannelList = action.payload;
    },
    channelDetailHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.optionsChannelList = initialState.optionsChannelList;
    },
    resetchannelDetailHand: () => initialState,
  },
});

export const {
  channelDetailHandStart,
  channelDetailHandSuccess,
  channelDetailHandFailed,
  resetchannelDetailHand,
} = channelDetailHand.actions;

export default channelDetailHand.reducer;
