import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  deleteFinancialsSuspendFailed,
  deleteFinancialsSuspendStart,
} from "../redux/deleteFinancialsSuspend";
import { toast } from "react-toastify";
import { deleteFinancialsSuspendApi } from "src/core/http/service/financeService";
import { getFinanceServicesStart } from "../redux/financeServiceList";

function* deleteFinancialsSuspendWork(
  action: ReturnType<typeof deleteFinancialsSuspendStart>
) {
  try {
    const params = {
      shareCode: action.payload,
    };
    yield call(deleteFinancialsSuspendApi, params);

    yield put({
      type: getFinanceServicesStart.type,
    });
    toast.success("Xóa mã thành công");
  } catch (error: any) {
    yield put({
      type: deleteFinancialsSuspendFailed.type,
    });
    toast.error("Xóa mã thất bại");
  }
}

function* watchDeleteFinancialsSuspend() {
  yield takeLatest(
    deleteFinancialsSuspendStart.type,
    deleteFinancialsSuspendWork
  );
}

export default function* deleteFinancialsSuspendSaga() {
  yield all([watchDeleteFinancialsSuspend()]);
}
