import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  postFinancialsSuspendFailed,
  postFinancialsSuspendStart,
} from "../redux/postFinancialsSuspend";
import { toast } from "react-toastify";
import { controlFinancialModalSelector } from "../redux/selector";
import { postFinancialsSuspendApi } from "src/core/http/service/financeService";
import { PostFinancialsSuspendParams } from "src/core/http/service/financeService/types";
import { getFinanceServicesStart } from "../redux/financeServiceList";
import { resetFinanceServiceModal } from "../redux/controlFinancialModal";
import moment from "moment";

function* postFinancialsSuspendWork() {
  try {
    const { startDay, stopDate, shareCodeEdit } = yield select(
      controlFinancialModalSelector
    );
    const params: PostFinancialsSuspendParams = {
      shareCode: shareCodeEdit,
      startDate: moment(startDay).format("YYYY-MM-DD"),
      stopDate: moment(stopDate).format("YYYY-MM-DD"),
    };
    yield call(postFinancialsSuspendApi, params);

    yield put({
      type: getFinanceServicesStart.type,
    });

    yield put({
      type: resetFinanceServiceModal.type,
    });

    toast.success("Cập nhật mã CK thành công");
  } catch (error: any) {
    yield put({
      type: postFinancialsSuspendFailed.type,
    });
    toast.error("Cập nhật thất bại");
  }
}

function* watchPostFinancialsSuspend() {
  yield takeLatest(postFinancialsSuspendStart.type, postFinancialsSuspendWork);
}

export default function* postFinancialsSuspendSaga() {
  yield all([watchPostFinancialsSuspend()]);
}
