export const listCompany = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "MBS",
    label: "MBS",
  },
  {
    value: "TCBS",
    label: "TCBS",
  },
];
export const listStatus = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "ACTIVE",
    label: "Hoạt động",
  },
  {
    value: "INACTIVE",
    label: "Ngừng hoạt động",
  },
];

export const STATUS_INACTIVE = "INACTIVE";
export const STATUS_ACTIVE = "ACTIVE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_CREATE = "CREATE";
export const ERROR_INPUT = "Vui lòng nhập thông tin";
export const ERROR_INPUT_RATE = "Lãi suất lớn hơn 0";

export const categoryDividend = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "SHARE",
    label: "Chứng khoán",
  },
  {
    value: "CASH",
    label: "Tiền mặt",
  },
];

export const categoryDividendCreate = [
  {
    value: "SHARE",
    label: "Chứng khoán",
  },
  {
    value: "CASH",
    label: "Tiền mặt",
  },
];
