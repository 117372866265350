import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

const getReportAccountShare = (state: RootState) =>
  state.reportCategoryCustomer.getReportAccountSharelice;
export const getReportAccountShareSelector = createSelector(
  getReportAccountShare,
  (state) => ({
    dataAccountShare: state.data,
    statusAccountShare: state.status,
  })
);

const filterReportCC = (state: RootState) =>
  state.reportCategoryCustomer.filterReportCCSlice;
export const filterReportCCSelector = createSelector(
  filterReportCC,
  (state) => ({
    accountCode: state.inputReportCC.accountCode,
    shareCode: state.inputReportCC.shareCode,
  })
);
