import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const orderActiveConnect = createSlice({
  name: "orderActiveConnectSlice",
  initialState,
  reducers: {
    orderActiveConnectStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    orderActiveConnectSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    orderActiveConnectFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  orderActiveConnectStart,
  orderActiveConnectSuccess,
  orderActiveConnectFailed,
} = orderActiveConnect.actions;

export default orderActiveConnect.reducer;
