import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { addDays, format } from "date-fns";
import { ReportFeeSyntheticParams } from "src/core/http/service/reportFeeSynthetic/types";
import { ResReportFeeSynthetic } from "src/domain/models/reportFeeSynthetic";

export type ReportFeeSyntheticState = {
  data: ResReportFeeSynthetic[];
  filter: ReportFeeSyntheticParams;
  hasMore: boolean;
  totalRealized: number;
};

const initialState: ReduxData<ReportFeeSyntheticState> = {
  data: {
    data: [],
    filter: {
      referrerCode: "",
      fromDate: format(new Date(), "dd/MM/yyyy"),
      toDate: format(addDays(new Date(), 30), "dd/MM/yyyy"),
    },
    totalRealized: 0,
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const reportFeeSyntheticListSlice = createSlice({
  name: "reportSynthetic",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<ReportFeeSyntheticParams>) => {
      state.data.filter = {
        ...state.data.filter,
        ...action.payload,
      };
    },
    resetTotalRealized: (state) => {
      state.data.totalRealized = initialState.data.totalRealized;
    },
    clearFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    clearData: (state) => {
      state.data.data = initialState.data.data;
    },
    getReportFeeSyntheticStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getReportFeeSyntheticFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      state.data.filter = initialState.data.filter;
    },
    getReportFeeSyntheticSuccess: (
      state,
      action: PayloadAction<{
        data: ResReportFeeSynthetic[];
      }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.data = action.payload.data;
    },
  },
});

export const {
  updateFilter,
  clearFilter,
  clearData,
  resetTotalRealized,
  getReportFeeSyntheticStart,
  getReportFeeSyntheticSuccess,
  getReportFeeSyntheticFailed,
} = reportFeeSyntheticListSlice.actions;

export default reportFeeSyntheticListSlice.reducer;
