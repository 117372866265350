import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
import { OrderHistoryByAccountFilterParams } from "src/core/http/service/orderHistory/types";
import moment from "moment";
import { DEFAULT_LIMIT_OFFSET } from "src/helper/const";

export type OrderHistoryState = {
  orderHistories: [];
  filter: OrderHistoryByAccountFilterParams;
  hasMore: boolean;
};

const initialState = {
  data: {
    orderHistories: [],
    filter: {
      accountCode: "",
      shareCode: "",
      from: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      to: moment(new Date()).format("YYYY-MM-DD"),
      offset: DEFAULT_LIMIT_OFFSET.OFFSET,
      limit: DEFAULT_LIMIT_OFFSET.LIMIT,
      side: "",
    },
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState,
  reducers: {
    updateFilter: (
      state,
      action: PayloadAction<OrderHistoryByAccountFilterParams>
    ) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    resetListOrderHistory: (state) => {
      state.data.orderHistories = initialState.data.orderHistories;
    },
    getOrderHistoriesStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getOrderHistoriesFailed: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.ERROR;
      toast.error(action.payload || "Đã có lỗi xảy ra");
    },
    getOrderHistoriesSuccess: (state, action) => {
      let { items } = action.payload;
      let { orderHistories, filter } = state.data;
      if (!items.length || items.length < (filter?.limit || 0)) {
        state.data.hasMore = false;
      } else {
        state.data.hasMore = true;
      }
      state.status = ReduxStateType.SUCCESS;
      state.data.orderHistories =
        filter.offset && filter.offset > 1
          ? [...orderHistories, ...action.payload.items]
          : action.payload.items;
    },
  },
});

export const {
  updateFilter,
  resetFilter,
  resetListOrderHistory,
  getOrderHistoriesStart,
  getOrderHistoriesSuccess,
  getOrderHistoriesFailed,
} = orderHistorySlice.actions;

export default orderHistorySlice.reducer;
