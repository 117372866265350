import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: {
    precision: number;
  };
  status: ReduxStateType;
}

const initialState: InitialState = {
  data: {
    precision: 0,
  },
  status: ReduxStateType.INIT,
};

const shareWithdraw = createSlice({
  name: "shareWithdrawSlice",
  initialState,
  reducers: {
    shareWithdrawStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    shareWithdrawSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    shareWithdrawFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
  },
});

export const { shareWithdrawStart, shareWithdrawSuccess, shareWithdrawFailed } =
  shareWithdraw.actions;

export default shareWithdraw.reducer;
