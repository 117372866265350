import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "src/components/Logo";
import HeaderUserbox from "./Userbox";
import SecurityIcon from "@mui/icons-material/Security";
import { WrapIconHeader } from "./Userbox/style";
import { getCheckOtpStatusSelector } from "src/modules/SettingsAuth2FA/redux/selector";
import { useSelector } from "react-redux";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        overflow:hidden;
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        z-index: 11;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            width: 100%;
        }
`
);

function Header() {
  const { is2faStatus } = useSelector(getCheckOtpStatusSelector);
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        className="header-menu"
        overflow="hidden"
      >
        <Logo />
        {/* <Hidden mdDown>
          <HeaderMenu />
        </Hidden> */}
      </Box>
      <Box display="flex" alignItems="center" gap="8px">
        <WrapIconHeader display="flex" alignItems="center">
          <SecurityIcon color={is2faStatus ? "success" : "error"} />
        </WrapIconHeader>
        <Box display="flex" alignItems="center">
          {/* <HeaderButtons /> */}
          <HeaderUserbox />
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
