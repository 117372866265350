import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;

  deleteIDs: Array<string | number>;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
  deleteIDs: [],
};

const deleteOrderHand = createSlice({
  name: "deleteOrderHandSlice",
  initialState,
  reducers: {
    deleteOrderHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    deleteOrderHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    deleteOrderHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    updateDeleteIDs: (state, action) => {
      state.deleteIDs = action.payload;
    },
    resetDeleteIDs: (state) => {
      state.deleteIDs = initialState.deleteIDs;
    },
  },
});

export const {
  deleteOrderHandStart,
  deleteOrderHandSuccess,
  deleteOrderHandFailed,
  updateDeleteIDs,
  resetDeleteIDs,
} = deleteOrderHand.actions;

export default deleteOrderHand.reducer;
