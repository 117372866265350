import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const ReferralAccountLazy = lazy(() => import("./pages"));

export const referralAccountRoutes = [
  {
    page: ReferralAccountLazy,
    path: paths.referralAccount,
    roleSide: roles.ROLE_USER,
  },
];
