// import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAccountInfoStaticApi } from "src/core/http/service/staticInfo";
import { updateInputInformation } from "src/layouts/SidebarLayout/Header/redux/information";
// import { logOutSuccess } from "src/modules/Auth/redux/auth";
import {
  getAccountInfoStaticStart,
  getAccountInfoStaticSuccess,
  getAccountInfoStaticFailed,
} from "../redux/getAccountInfoStatic";
import { getCheckOtpStatusStart } from "src/modules/SettingsAuth2FA/redux/getCheckOtpStatus";

function* getAccountInfoStaticWork() {
  try {
    const data = yield call(getAccountInfoStaticApi);

    yield put({
      type: getAccountInfoStaticSuccess.type,
      payload: data,
    });
    yield put({
      type: updateInputInformation.type,
      payload: data,
    });
    yield put({
      type: getCheckOtpStatusStart.type,
      payload: data?.accountCode,
    });
  } catch (error: any) {
    yield put({
      type: getAccountInfoStaticFailed.type,
    });
  }
}

function* watchlistGetAccountInfoStatic() {
  yield takeLatest(getAccountInfoStaticStart.type, getAccountInfoStaticWork);
}

export default function* getAccountInfoStaticSaga() {
  yield all([watchlistGetAccountInfoStatic()]);
}
