import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: {
    buyingPower: number;
    cashBlock: number;
    cashCurrent: number;
    cashDeposit: number;
    navCurrent: number;
    portfolioCurrent: number;
    rate: number;
    realized: number;
    unrealized: number;
    cashWithdraw: number;
    cashCall?: number;
    cashSellCall?: number;
    depositRate?: number;
  };
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: {
    buyingPower: 0,
    cashBlock: 0,
    cashCurrent: 0,
    cashDeposit: 0,
    navCurrent: 0,
    portfolioCurrent: 0,
    rate: 0,
    realized: 0,
    unrealized: 0,
    cashWithdraw: 0,
  },
  status: ReduxStateType.INIT,
};

const reportBuyingPowerHand = createSlice({
  name: "reportBuyingPowerHandSlice",
  initialState,
  reducers: {
    reportBuyingPowerHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    reportBuyingPowerHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    reportBuyingPowerHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
  },
});

export const {
  reportBuyingPowerHandStart,
  reportBuyingPowerHandSuccess,
  reportBuyingPowerHandFailed,
} = reportBuyingPowerHand.actions;

export default reportBuyingPowerHand.reducer;
