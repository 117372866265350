import { Box, Checkbox } from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "src/components/Modal";
import Typography from "src/components/Typography";
import SwitchSetting2FA from "src/components/commons/SwitchSetting2FA";
import { postRegisOtp2FAStart } from "../redux/postRegisOtp2FA";
import { getCheckOtpStatusSelector } from "../redux/selector";
import guideEZ from "src/assets/images/guideEZ.jpg";
import guideEZLogin from "src/assets/images/guideEZLogin.jpg";
import QRCode from "react-qr-code";

const linkTelegram = "https://t.me/eztrade2FABot";

const styleModal = {
  "& .MuiPaper-root": {
    width: "450px",
    height: "250px",
    maxHeight: "100%",
    maxWidth: "1000px",
  },
};

interface PropsSettingsAuth2FA {
  isShowGuide: boolean;
  isGuideLogin: boolean;
}

enum STATUS_BASE {
  STATUS_OTP = "STATUS_OTP",
  STATUS_2FA_TELE = "STATUS_2FA_TELE",
  STATUS_2FA_MAIL = "STATUS_2FA_MAIL",
}

const SettingsAuth2FA = ({ isShowGuide = false }: PropsSettingsAuth2FA) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState<STATUS_BASE | "">("");
  const { is2faStatus, is2faTele, is2faMail } = useSelector(
    getCheckOtpStatusSelector
  );
  const dispatch = useDispatch();
  const handleChangeStatus = (type: STATUS_BASE) => {
    setIsShowModal(true);
    setTitleModal(type);
  };

  const handleSubmit = () => {
    if (titleModal === STATUS_BASE.STATUS_OTP) {
      dispatch(
        postRegisOtp2FAStart({
          otpStatus: !is2faStatus,
        })
      );
    } else if (titleModal === STATUS_BASE.STATUS_2FA_TELE) {
      dispatch(
        postRegisOtp2FAStart({
          teleStatus: !is2faTele,
        })
      );
    } else if (titleModal === STATUS_BASE.STATUS_2FA_MAIL) {
      dispatch(
        postRegisOtp2FAStart({
          mailStatus: !is2faMail,
        })
      );
    }
    setIsShowModal(false);
  };

  const handleClose = () => {
    setIsShowModal(false);
  };

  const contentModal = useMemo(() => {
    switch (titleModal) {
      case STATUS_BASE.STATUS_OTP:
        return "Xác thực 2 lớp 2FA";
      case STATUS_BASE.STATUS_2FA_TELE:
        return "2FA Tele";
      case STATUS_BASE.STATUS_2FA_MAIL:
        return "2FA Mail";
      default:
        return "";
    }
  }, [titleModal]);

  return (
    <Box gap="16px" display="flex" flexDirection="column" padding="16px 16px">
      <Box gap="24px" display="flex">
        <Box>
          {!isShowGuide && (
            <Box gap="4px" display="flex" alignItems="center">
              <SwitchSetting2FA
                checked={!is2faStatus}
                sideswitch={!is2faStatus ? "S" : "B"}
                onClick={() => handleChangeStatus(STATUS_BASE.STATUS_OTP)}
              />
              <Typography fontWeight="bold">Xác thực 2 lớp 2FA</Typography>
            </Box>
          )}

          {is2faStatus && !isShowGuide && (
            <Box gap="4px" display="flex" alignItems="center">
              <Checkbox
                size="small"
                checked={is2faMail}
                name="2fa Mail"
                onChange={() => handleChangeStatus(STATUS_BASE.STATUS_2FA_MAIL)}
              />
              <Typography fontWeight="bold">2fa Mail</Typography>
            </Box>
          )}
          {is2faStatus && !isShowGuide && (
            <Box gap="4px" display="flex" alignItems="center">
              <Checkbox
                size="small"
                checked={is2faTele}
                name="2fa Tele"
                onChange={() => handleChangeStatus(STATUS_BASE.STATUS_2FA_TELE)}
              />
              <Typography fontWeight="bold">2fa Tele</Typography>
            </Box>
          )}
          {is2faTele && (
            <>
              <Typography>
                {" "}
                Truy cập link hoặc quét mã QR để Tải và Đăng Ký tài khoản
                Telegram:{" "}
                <a href={linkTelegram} target="_blank" rel="noreferrer">
                  {linkTelegram}
                </a>{" "}
              </Typography>
              <Typography>Làm theo hướng dẫn phía dưới để nhận OTP</Typography>
            </>
          )}
        </Box>
        {is2faTele && (
          <QRCode
            style={{ height: "auto", maxWidth: "120px", width: "120px" }}
            value={linkTelegram}
          />
        )}
      </Box>
      {(is2faTele || isShowGuide) && (
        <img
          title="huong dan sau khi dang nhap"
          src={isShowGuide ? guideEZLogin : guideEZ}
          style={{ minHeight: "200px" }}
        />
      )}

      <Modal
        sx={styleModal}
        open={isShowModal}
        closeText={"Đóng"}
        acceptText={"Xác Nhận"}
        content={<>Bạn có chắc chắn muốn chuyển trạng thái {contentModal}</>}
        disableEnforceFocus
        onAccept={handleSubmit}
        onClose={handleClose}
        title={"Chuyển trạng thái" + contentModal}
      />
    </Box>
  );
};

export default SettingsAuth2FA;
