import { all } from "@redux-saga/core/effects";
import authSaga from "./auth";
import logoutSaga from "./logout";
import registerSaga from "./register";
import resetPasswordSaga from "./resetPassword";
import auth2faSaga from "./auth2fa";
export default function* authenicationSaga() {
  yield all([
    authSaga(),
    logoutSaga(),
    registerSaga(),
    resetPasswordSaga(),
    auth2faSaga(),
  ]);
}
