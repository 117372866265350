import { format } from "date-fns";

export const itemNav = {};
export const roles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_EDITOR: "ROLE_EDITOR",
  ROLE_USER: "ROLE_USER",
};

export const optionsAdmin = [
  { value: "ROLE_ADMIN", label: "Admin" },
  { value: "ROLE_EDITOR", label: "Editor" },
  { value: "ROLE_USER", label: "User" },
];

export const optionsAdminFilter = [
  { value: null, label: "Tất cả" },
  { value: "ROLE_ADMIN", label: "Admin" },
  { value: "ROLE_EDITOR", label: "Editor" },
  { value: "ROLE_USER", label: "User" },
];

export const optionStatus = [
  { value: null, label: "Tất cả" },
  { value: "ACTIVE", label: "Hoạt động" },
  { value: "INACTIVE", label: "Dừng hoạt động" },
];
export const rolesText = (role) => {
  switch (role) {
    case "ROLE_ADMIN":
      return "ADMIN";
    case "ROLE_EDITOR":
      return "EDITOR";
    case "ROLE_USER":
      return "USER";
  }
};
export const statusRoles = (role) => {
  switch (role) {
    case "ROLE_ADMIN":
      return "success";
    case "ROLE_EDITOR":
      return "error";
    case "ROLE_USER":
      return "warning";
  }
};

export const localStorageKey = {
  ACCOUNT_CODE_KEY: "account_code",
  TOKEN_KEY: "token",
};
export const optionsMarket = [
  { value: "ATO", label: "ATO" },
  { value: "ATC", label: "ATC" },
];

export const isNumberKeyPrice = (evt: any) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode === 65) return true;
  // Ký tự phiên ATC và ATO
  if (evt.ctrlKey && charCode === 65)
    // ctrl + A
    return true;
  if (charCode === 32) return false;
  if (charCode >= 48 && charCode <= 57) return true;
  if (charCode > 57 && charCode < 96) return false;
  if (charCode >= 96 && charCode <= 105) return true;
  if (charCode > 105 && charCode < 110) return false;
  if (charCode > 110 && charCode < 111) return false;
  if (charCode >= 186 && charCode < 190) return false;
  if (charCode > 190) return false;
  if (charCode === 231) return false;
  //  Thằng 231 khóa vietkey
  return true;
};

export const isNumber = (evt: any) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (evt.ctrlKey && charCode === 65)
    // ctrl + A
    return true;
  if (charCode === 32) return false;
  if (charCode >= 48 && charCode <= 57) return true;
  if (charCode > 57 && charCode < 96) return false;
  if (charCode >= 96 && charCode <= 105) return true;
  if (charCode > 105 && charCode < 110) return false;
  if (charCode > 110 && charCode < 111) return false;
  if (charCode >= 186 && charCode < 190) return false;
  if (charCode > 190) return false;
  if (charCode === 231) return false;
  //  Thằng 231 khóa vietkey
  return true;
};

export const reOrderSum = (dataSum) => {
  const dataTableSum = [];
  const arrayAccount = dataSum.filter(
    (item, index) =>
      dataSum.findIndex(
        (element) => String(element.accountCode) === String(item.accountCode)
      ) === index
  );
  //  Lọc ra các phần tử không bị trùng lặp đi kèm index a = [{}1, {}2, {}3] vs a = [{}1, {}2, {}3]
  arrayAccount.forEach((itemX) => {
    const dataFilterOrders = dataSum.filter(
      (itemY) => itemY.accountCode === itemX.accountCode
    );
    // Lọc dữ liệu các lệnh theo từng channel
    const dataOrder = {
      accountCode: itemX.accountCode,
      total_qty_buy: 0,
      total_price_buy: 0,
      total_qty_sell: 0,
      total_price_sell: 0,
      total_qty:
        dataFilterOrders.length >= 2
          ? dataFilterOrders[0]?.total_order + dataFilterOrders[1].total_order
          : dataFilterOrders[0]?.total_order,
    };
    dataFilterOrders.forEach((order) => {
      if (order.cside === "B") {
        dataOrder.total_price_buy = order?.total_price;
        dataOrder.total_qty_buy = order?.total_qty;
      } else {
        dataOrder.total_price_sell = order?.total_price;
        dataOrder.total_qty_sell = order?.total_qty;
      }
    });
    // Chỉ có 2 loại là "B" và "S" nên check 2 trường hợp đẩy giá trị vào thu được dữ liệu hoàn chỉnh
    dataTableSum.push(dataOrder);
  });
  return dataTableSum;
};

export const dateText = (date) => {
  if (date) {
    return format(date, "dd/MM/yyyy HH:mm:ss");
  } else {
    return null;
  }
};

export const validateSumTable = {
  SUM_ALL: "SUM_ALL",
  SUM: "SUM",
};

export const validateSumAllTable = {
  SUM: "SUM",
};
// Tạo biến sum all cho footer table

// Sort chuẩn Number cho use Table
export const compareNumericString = (
  rowA: any,
  rowB: any,
  id: any,
  desc: any
) => {
  let a = Number.parseFloat(rowA.values[id]);
  let b = Number.parseFloat(rowB.values[id]);
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

export const tablePerformance = {
  ITEM_SIZE: 27,
};

export const heighSpaceTable = {
  SPACE_BOTOM: 105,
  SPACE_FIlTER: 155,
  SPACE_BOTOM_STOCKTRADING: 225,
};

export const StyleTable = {
  "& .MuiTableCell-root": {
    padding: "3px 8px",
    fontSize: "12px",
  },
};

// Tạo các biến cho hotkey
export const paramsHotkeys = {
  KEY_ADD: "alt+a",
  KEY_CANCEL: "alt+c",
  KEY_SAVE: "alt+s",
};
export const paramsNameHotkeys = {
  KEY_ADD: "(Alt + A)",
  KEY_CANCEL: "(Alt + C)",
  KEY_SAVE: "(Alt + S)",
};

export const dividends = {
  IS_RIGHT_BUY_INACTIVE: 0,
  IS_RIGHT_BUY_ACTIVE: 1,
  SHARE: "Chứng Khoán",
  CASH: "Tiền mặt",
  1: "Có",
  0: "Không",
  dividendsType: {
    SHARE: "SHARE",
    CASH: "CASH",
  },
};

export const compareArray = (currentData: any[], newData: any[]) => {
  if (currentData.length !== newData.length) {
    return false;
  }
  const stringCurrentData = JSON.stringify(currentData);
  const stringNewData = JSON.stringify(newData);
  if (stringCurrentData === stringNewData) {
    return true;
  }
  return false;
};

export const DEFAULT_LIMIT_OFFSET = {
  LIMIT: 50,
  OFFSET: 0,
};
