import { createSlice } from "@reduxjs/toolkit";
import { ResFilterAccount } from "src/domain/models/managementAccount";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: ResFilterAccount[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const filterAccount = createSlice({
  name: "filterAccountSlice",
  initialState,
  reducers: {
    filterAccountStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    filterAccountSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = [...state.data, ...action.payload];
    },
    filterAccountFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
    resetDataFilterAccount: () => initialState,
  },
});

export const {
  filterAccountStart,
  filterAccountSuccess,
  filterAccountFailed,
  resetDataFilterAccount,
} = filterAccount.actions;

export default filterAccount.reducer;
