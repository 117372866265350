// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import { deleteDividendApi } from "src/core/http/service/dividend";
import { dividendDeleteState } from "../redux/selector";
import { DividendDeleteParams } from "src/core/http/service/dividend/types";
import {
  deleteDividendFailed,
  deleteDividendStart,
  deleteDividendSuccess,
} from "../redux/deleteDividend";
import { getDividendFilterStart } from "../redux/getDividendFilter";

const dividendDeleteSelector = createSelector(dividendDeleteState, (state) => ({
  id: state.data.id,
}));

function* getDividendDelete(action: ReturnType<typeof deleteDividendStart>) {
  try {
    const { id } = yield select(dividendDeleteSelector);
    const params: DividendDeleteParams = {
      id,
    };
    const res = yield call(deleteDividendApi, params);
    yield put({
      type: deleteDividendSuccess.type,
      payload: {
        items: res,
      },
    });
    yield put({
      type: getDividendFilterStart.type,
    });
  } catch (error: any) {
    yield put(deleteDividendFailed(error));
  }
}

function* watchGetDividendDelete() {
  yield takeLatest(deleteDividendStart.type, getDividendDelete);
}
export default function* getDividendDeleteSaga() {
  yield all([watchGetDividendDelete()]);
}
