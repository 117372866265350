import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { channelDetailApi } from "src/core/http/service/stockTrading";
import { ChannelDetailParams } from "src/core/http/service/stockTrading/types";
import { ChannelDetailsRes } from "src/domain/models/stocktrading";
import { RootState } from "src/redux-store/store";
import {
  channelDetailFailed,
  channelDetailStart,
  channelDetailSuccess,
} from "../redux/channelDetail";
import { updateInputForm } from "../redux/formOrder";

function* channelDetailStartWork() {
  const { accountCode } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );

  const params: ChannelDetailParams = {
    accountCode: accountCode?.value,
  };
  try {
    const res: ChannelDetailsRes[] = yield call(channelDetailApi, params);
    const optionsChannelList = (res || []).map((item) => ({
      key: item?.id,
      value: item.channelId,
      label: item.channelDetailCode,
    }));
    yield put({
      type: channelDetailSuccess.type,
      payload: optionsChannelList,
    });
    yield put({
      type: updateInputForm.type,
      payload: {
        channelCode: optionsChannelList[0] || {},
      },
    });
  } catch (error: any) {
    yield put({
      type: channelDetailFailed.type,
    });
  }
}

function* watchChannelDetail() {
  yield takeLatest(channelDetailStart.type, channelDetailStartWork);
}

export default function* channelDetailSaga() {
  yield all([watchChannelDetail()]);
}
