export const handleValidate = (checkValidate: boolean, params: any) => {
  return checkValidate ? (params ? "" : "Vui lòng điền vào chỗ trống") : "";
};

export const validateErrorNumberInput = (
  checkValidate: boolean,
  params: string | number
) => {
  if (checkValidate) {
    return params === "" ? true : false;
  }
};
export const validateErrorTextNumberInput = (
  checkValidate: boolean,
  params: string | number
) => {
  if (checkValidate) {
    return params === "" ? "Vui lòng điền vào chỗ trống" : "";
  }
};

export const enterFocus = (e: any, elementRef: any) => {
  if (e.keyCode === 13) {
    elementRef.current.focus();
  }
};

export const preventSpaceAndEnterFocus = (e: any, elementRef: any) => {
  let keyCode = e.keyCode;
  if (keyCode === 13) {
    e.current.focus();
  } else if (keyCode === 32) {
    e.preventDefault();
  }
};

export const enterCallBack = (e: any, callBack: () => void) => {
  if (e.keyCode === 13) {
    callBack();
  }
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
  // kiểm tra và return về boolean
};

export const formatDDMMYYYYToMMDDYYYY = (date: string) => {
  if (!date) return null;
  const a = date.split("/");
  return [a[1], a[0], a[2]].join("-");
};
export const handleValidateAdvanced = (checkValidate: boolean, params: any) => {
  return checkValidate ? (params ? true : false) : false;
};

export const handleValidateError = (checkValidate: boolean, params: any) => {
  return checkValidate ? (params !== "" ? false : true) : false;
};

export const handleValidateDividendError = (
  checkValidate: boolean,
  params: any
) => {
  return checkValidate
    ? params !== "" && Number(params) > 0
      ? false
      : true
    : false;
};

export const handleValidateDividend = (checkValidate: boolean, params: any) => {
  return checkValidate
    ? params !== "" && Number(params) > 0
      ? ""
      : "Vui lòng điền vào chỗ trống và giá trị lớn hơn 0"
    : "";
};
