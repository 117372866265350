// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";

import { forControlChannelState } from "../redux/selector";
import {
  getListForControlChannelFailed,
  getListForControlChannelStart,
  getListForControlChannelSuccess,
} from "../redux/forControl";
import { getListReportForControlApi } from "src/core/http/service/reportForControl";
import { ReportForControlParams } from "src/core/http/service/reportForControl/types";

const forControlSelector = createSelector(forControlChannelState, (state) => ({
  filter: state.data.filter,
}));

function* getProfitLoss(
  action: ReturnType<typeof getListForControlChannelStart>
) {
  try {
    const { filter } = yield select(forControlSelector);
    const params: ReportForControlParams = {
      ...filter,
    };
    delay(200);
    const responseListReportForControl = yield call(
      getListReportForControlApi,
      params
    );
    yield put({
      type: getListForControlChannelSuccess.type,
      payload: responseListReportForControl,
    });
  } catch (error: any) {
    yield put(getListForControlChannelFailed());
  }
}

function* watchGetReportForControlChannel() {
  yield takeLatest(getListForControlChannelStart.type, getProfitLoss);
}
export default function* getForControlSaga() {
  yield all([watchGetReportForControlChannel()]);
}
