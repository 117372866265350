import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: {
    ceilingPrice: number;
    floorPrice: number;
    lastPrice: number;
    referencePrice: number;
    marginRate: number;
    buyingPower: number;
    maxBuy: number;
  };
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: {
    ceilingPrice: 0,
    floorPrice: 0,
    lastPrice: 0,
    referencePrice: 0,
    marginRate: 0,
    buyingPower: 0,
    maxBuy: 0,
  },
  status: ReduxStateType.INIT,
};

const getBuyInfo = createSlice({
  name: "getBuyInfoSlice",
  initialState,
  reducers: {
    getBuyInfoStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getBuyInfoSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    getBuyInfoFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
    resetGetBuyInfo: () => initialState,
  },
});

export const {
  getBuyInfoStart,
  getBuyInfoSuccess,
  getBuyInfoFailed,
  resetGetBuyInfo,
} = getBuyInfo.actions;

export default getBuyInfo.reducer;
