import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

const filterReportFeeSynthetic = (state: RootState) =>
  state.reportFeeSynthetic.reportFeeSyntheticListSlice;

export const filterReportFeeSyntheticSelector = createSelector(
  filterReportFeeSynthetic,
  (state) => ({
    dataReport: state.data.data,
    accountCode: state.data.filter.referrerCode,
    fromDate: state.data.filter.fromDate,
    toDate: state.data.filter.toDate,
    statusReportFS: state.status,
    totalRealized: state.data.totalRealized,
    filter: state.data.filter,
  })
);
