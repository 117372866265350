import { Grid } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import React, { memo, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxField from "src/components/BoxField";
import TextField from "src/components/Forms/TextField";
import { enterCallBack, handleValidateAdvanced } from "src/helper/validate";
import {
  resetFormForgetPass,
  updateInputFormForgetPass,
} from "../../redux/formForgetPass";
import { formForgetPass } from "../../redux/selector";
import { handleValidateTrading, validateInputEmail } from "../Login/validate";

const formRegisterSelector = createSelector(formForgetPass, (state) => ({
  emailForgetPass: state.inputFormForgetPass.emailForgetPass,
  checkValidate: state.inputFormForgetPass.checkValidate,
}));

function FormForgetPass({ handleSubmitForgetPass }) {
  const { emailForgetPass, checkValidate } = useSelector(formRegisterSelector);
  const dispatch = useDispatch();
  // useEffect
  useEffect(() => {
    dispatch(resetFormForgetPass());
  }, []);
  // Hàm handle
  const handleInput = (e: any, name: string) => {
    dispatch(
      updateInputFormForgetPass({
        [name]: e.target.value,
      })
    );
  };
  // Tao hotkey
  const inputEmailRef = useRef(null);
  // Validate
  const codeEmail = useMemo(() => {
    return validateInputEmail(emailForgetPass);
  }, [emailForgetPass]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BoxField labelWidth="100px" required label="Email">
          <TextField
            autoFocus
            value={emailForgetPass}
            inputRef={inputEmailRef}
            onChange={(e) => handleInput(e, "emailForgetPass")}
            placeholder="Nhập email"
            error={handleValidateAdvanced(checkValidate, codeEmail)}
            helperText={handleValidateTrading(checkValidate, codeEmail)}
            onKeyDown={(e) => enterCallBack(e, handleSubmitForgetPass)}
          />
        </BoxField>
      </Grid>
    </Grid>
  );
}

export default memo(FormForgetPass);
