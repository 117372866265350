import basePathHttp from "src/core/http/singleton/channel";
import { ResReportAccountShare } from "src/domain/models/reportCategoryCustomer";
import { ReportAccountShareParams } from "./type";
import { reportAccountShare } from "./urls";

export const getReportAccountShareApi = async (
  params: ReportAccountShareParams
): Promise<ResReportAccountShare[]> => {
  const { ...restParams } = params;
  const res = await basePathHttp.get<ResReportAccountShare[]>(
    reportAccountShare,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
